import {
  Banner
} from 'admin-frontend';
import { Auth } from "admin-frontend";
import { titleCase } from "./Fields";

export function UpgradeBanner({ bundleType, singular, plural, limit }) {
  const [profile] = Auth.useProfile();
  const actualLimit = !bundleType ? 0 : (limit === undefined && profile?.shop?.subscriptions[0]?.maximums ? profile.shop.subscriptions[0].maximums[bundleType] : limit);
  const actualSingular = singular || titleCase(bundleType.split(/_/).join(" "))
  const actualPlural = plural || (actualSingular + "s")
  return (<Banner
    status="warning"
    title={actualLimit === 0 ? "Use of " + actualPlural + " is Restricted"  : "Maximum Limit of " + actualPlural + " Reached"}
    action={{content: "Upgrade your Plan", url: (bundleType ? ("/setup/account/bundles?bundleType=" + encodeURIComponent(bundleType)) : "/setup/account")}}
  >
    {actualLimit === 0 ?
      "Your current plan doesn't let you use " + actualPlural.toLowerCase() + ". Please upgrade your plan to use this feature." : (
      "You can only have a maximum of " + actualLimit + " " +  (actualLimit === 1 ? actualSingular : actualPlural).toLowerCase() + " on your current plan. Please either delete a "
      + actualSingular.toLowerCase() + ", or upgrade your plan to allow for more " + actualPlural.toLowerCase() + "."
    )}
  </Banner>);
}

export function SDKUpgradeBanner({ feature, minimumSDKVersion }) {
  const [profile] = Auth.useProfile();
  return profile && (!profile.shop.sdk_version || profile.shop.sdk_version < minimumSDKVersion) && (<Banner status="warning">Please note, that the explicit use of {feature} is only allowed in SDK Versions <b>{minimumSDKVersion}</b> and above; you're currently on SDK Version <b>{profile.shop.sdk_version || "SDK20231101"}</b>. Usage of this feature without upgrading could cause your theme to stop working correctly. Please upgrade to use this feature.</Banner>);
}
