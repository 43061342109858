import React, {useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Card,
  TextContainer,
  Link,
  InlineError
} from 'admin-frontend';
import { Auth, useRedirect } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"

export function ArtificialIntelligence() {
  const [aiSetup, setAiSetup] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [profile] = Auth.useProfile();

  const authFetch = useSpotFetch();

  useEffect(() => {
		authFetch("/api/setup/ai")
			.then((r) => {
				setAiSetup(r.ai);
				setIsLoading(false);
			});
	}, [authFetch]);

	const updateAi = useCallback((ai) => {
		setIsLoading(true);
		authFetch("/api/setup/ai", { json: { ai: ai } }).then((r) => {
			setIsLoading(false);
			setAiSetup(r.ai);
		})
	}, [authFetch]);

	const usingEventsApi = false;

  return (
	<Page
    disableThemes
    permission="ai"
		>
		<Layout>
			<Layout.Section>
				<Card sectioned title="Overview">
					<TextContainer>
						<p>Spot uses an sophisiticated system of artifical intelligence to keep your installation up to date, and make sure that your customers are always receiving relevant results, by drilling into their behaviour,
						and then adding appropriate.</p>

						<p>The goal of the Spot system is to be fully overridable by a merchandiser, and compeltely transparent, so you can understand what kind of recommendations the system is making, and why.</p>

						<p>This section is under heavy development, and will be continuously improved as more data is accumulated.</p>
					</TextContainer>
        </Card>
        <Card sectioned title="Search Pins"  primaryFooterAction={{
          content: aiSetup !== null ? (aiSetup.search_pins ?  "Disable AI Search Pins" : "Enable AI Search Pins") : "...",
				  disabled: isLoading || true,
				  onAction: () => updateAi({ ...aiSetup, search_pins: (aiSetup.search_pins ? false : true) })
				}}>
					<TextContainer>
						{!usingEventsApi && <InlineError message="Your store is not using the Events API." />}
						<p>If you're using Spot's Events API, Spot can use your events data to determine which products are being clicked on by customers, and then boost results for those search terms accordingly.</p>
						<p>This ensures that your customers are always finding the most relevant products for a given term.</p>
					</TextContainer>
        </Card>
        <Card sectioned title="Synonym Management" primaryFooterAction={{
          content: aiSetup !== null ? (aiSetup.synonyms ?  "Disable AI Synonym Management" : "Enable AI Synonym Management") : "...",
				  disabled: isLoading,
				  onAction: () => updateAi({ ...aiSetup, synonyms: (aiSetup.synonyms ? false : true) })
				}}>
					<TextContainer>
						{!usingEventsApi && <InlineError message="Your store is not using the Events API." />}
						
						<p>If you're using Spot's Events API, Spot can use your events data to determine which search terms should probably be linked to which other search terms, and automatically include what it thinks is most appropriate.</p>

						<p>It will also browse your facet listing, and automatically add in any facet groups you have as synonyms, so that searching for the name of the group will automatically map to that group's values.</p>
					</TextContainer>
        </Card>
        <Card sectioned title="Recommendation Management" primaryFooterAction={{
          content: aiSetup !== null ? (aiSetup.relatedness ?  "Disable AI Recommendation Management" : "Enable AI Recommendation Management") : "...",
				  disabled: isLoading || (profile && !/orders/.test(profile.shop.scope)),
				  onAction: () => updateAi({ ...aiSetup, relatedness: (aiSetup.relatedness ? false : true) })
				}}>
					<TextContainer>
						{profile && !/orders/.test(profile.shop.scope) && (<InlineError message={<span>Requires permission to read your order listing. To enable this, please go to <Link url="/setup/catalog">Catalog Processing</Link>, and enable Order Processing.</span>}/>)}
						<p>Spot can use your store's sales data, in order to automatically manage your boost rules used on your recommendations, automatically recommending items which customers purchase together.</p>
						<p>Enabling this option will allow you to use the <i>AI Similarity</i> metric in your boost rules, which encapsulates this data.</p>
					</TextContainer>
        </Card>
			</Layout.Section>
		</Layout>
    </Page>
  );
}
