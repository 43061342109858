import React, {useState, useEffect} from 'react';
import {
	Card,
	Spinner,
	Filters,
	RangeSlider,
	Button,
	Icon
} from 'admin-frontend';
import { Auth, MultiTable, Stack } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { Page, PlaceholderImage } from "../../components/Page"
import { useSpotAPI, useSpot } from "../../components/API"
import { FieldSpecifier, productFields, convertToFrontend, convertFromFrontend, SearchField } from "../../components/Fields";
import { DeleteMajor } from 'admin-frontend';

export function ProductWeights() {
  const [products, setProducts] = useState(null);
  const [fieldWeights, setFieldWeights] = useState(null);
  const [fieldListing, setFieldListing] = useState(null);
  const [productWeights, setProductWeights] = useState({});
  const [modifiedProductWeights, setModifiedProductWeights] = useState({});
  const [updatedAt, setUpdatedAt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [search, setSearch] = useState('');
  const authFetch = useSpotFetch();

	const spotAPI = useSpotAPI();

  useEffect(() => {
		spotAPI.s().search(search).rows(100).e().done(function(products) {
			authFetch("/api/global/product_weights", { query: { "ids": products.map((p) => p.id) } })
				.then((r) => {
					setUpdatedAt(r.updated_at);
					setProductWeights(Object.fromEntries(r.product_weights.map((e) => [e.product_id, e.weight])));
					setProducts(products);
					const frontendWeights = r.field_weights.map((f) => convertToFrontend(f));
					setFieldListing(frontendWeights.map((f) => f.slice(0, -1)));
					setFieldWeights(frontendWeights.map((f) => "" + f[f.length-1]));
				});
		});
  }, [authFetch, search]);


	function saveWeights() {
		setIsLoading(true);
  	authFetch("/api/global/product_weights", { json: {
			product_weights: Object.keys(modifiedProductWeights).map((e) => { return { product_id: e, weight: modifiedProductWeights[e] }; }),
			field_weights: fieldListing.map((f,idx) => convertFromFrontend([...f, parseFloat(fieldWeights[idx])])),
			updated_at: updatedAt
		} })
    	.then((r) => {
				setUpdatedAt(r.updated_at);
  			setIsLoading(false);
  			setIsChanged(false);
    		setModifiedProductWeights({});
    		setProductWeights({ ...productWeights, ...Object.fromEntries(r.product_weights.map((e) => [e.product_id, e.weight])) });
    		const frontendWeights = r.field_weights.map((f) => convertToFrontend(f));
    		setFieldListing(frontendWeights.map((f) => f.slice(0, -1)));
    		setFieldWeights(frontendWeights.map((f) => "" + f[f.length-1]));
    	})
	}

	const weightFields = productFields.filter((e) => e.handle === "priority" || e.handle === "sales" || /custom-field/.test(e.handle));

  return (
	  <Page
			permission="product-and-search-weights"
	  	disableThemes
	  	isFullyLoading={fieldWeights === null}
	  	isLoading={isLoading}
	  	isChanged={isChanged}
	  	onSave={() => saveWeights()}
  	>
			{fieldWeights && <>
	    <Card title="Manual Weights" sectioned>
	    	<SearchField
          value={search}
          onChange={(val) => setSearch(val)}
        />
				{products ? (
	    	<MultiTable
					headings={["Image", "Title", "Product Type", "Vendor", "Weight"]}
					rows={products.map((p) => [
							(p.image ? <img alt={p.title} src={spotAPI.getSizedImage(p.image, "64x64")}/> : <PlaceholderImage/>),
							p.title,
							p.product_type,
							p.vendor,
							<RangeSlider
								style={{"minWidth": "256px"}}
								output
								disabled={isLoading}
								min={0}
								max={100}
								suffix={modifiedProductWeights[p.id] || productWeights[p.id] || 0}
								value={modifiedProductWeights[p.id] || productWeights[p.id] || 0}
								onChange={(weight) => {
									setIsChanged(true);
									setModifiedProductWeights({ ...modifiedProductWeights, [p.id]: weight });
								}}
							/>
					])}
	    	/>) : (<><br/><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></>)}
			</Card>
			<Card title="Product Field Weights" primaryFooterAction={{content: 'Add Weighted Field', onAction: () => {
				setIsChanged(true);
				setFieldListing([...fieldListing, ["priority"]]);
				setFieldWeights([...fieldWeights, 1]);
			}}} sectioned>
				<p>The weights below are added together, multiplied by their priority, to determine final product weight.</p>
				<MultiTable
					headings={["Field", "Priority", ""]}
					rows={fieldListing.map((f, idx) => [
						(<FieldSpecifier fields={weightFields} disabled={idx === 0} type={"numeric"} onChange={(field) => {
							setIsChanged(true);
							fieldListing[idx] = field;
							setFieldListing([...fieldListing]);
						} } field={f}/>),
						(<RangeSlider style={{"minWidth": "256px"}} output min={0} max={100} value={fieldWeights[idx]}
							suffix={fieldWeights[idx]} onChange={(v) => { fieldWeights[idx] = v; setIsChanged(true); setFieldWeights([...fieldWeights]); }}/>),
						(idx === 0 ? "" : (<Button onClick={() => {
							setIsChanged(true);
							setFieldWeights(fieldWeights.filter((i, idx2) => idx2 !== idx));
							setFieldListing(fieldListing.filter((i, idx2) => idx2 !== idx));
						}}>
							<Icon source={DeleteMajor} color="base" />
						</Button>))
					])}
				/>
			</Card>
		</>}
		</Page>
	);
}
