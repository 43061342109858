
import "./spot.js"
import { Auth } from "admin-frontend";
import { useMemo, useEffect, useCallback, useState, createContext, useContext } from "react";

export const SpotContext = createContext(null);

export const useSpot = () => {
  return useContext(SpotContext);
};
export const useSpotAPI = () => {
	const spot = useSpot();
	return spot && spot.spotAPI;
};

export function SpotState({ children, init, allowNoState, id, externalSearch = false }) {
  const [spotDOM, setSpotDOM] = useState(null);
  const [spotDefault, setSpotDefault] = useState(null);

	
  const [profile] = Auth.useProfile();
	const sessionTokenFetch = Auth.useFetchSessionToken();

  const spotAPI = useMemo(() => {
		if (profile) {
			const spotAPI = new window.SpotAPI();
			const hostname = profile && profile.shop.shop_origin;
			spotAPI.defaultQuery(spotAPI.se().category('spot-internal').hostname(hostname));
			spotAPI._jsStyle = false;
			// Queue up requests until we get a token.
			spotAPI.oldSpotAPIAjax = spotAPI.ajax;
			spotAPI.spotRequests = [];
			spotAPI.id = id;
			spotAPI.ajax = function() {
				var deferred = spotAPI.Deferred();
				spotAPI.spotRequests.push([deferred, arguments]);
				return deferred;
			};
			return spotAPI;
		}
		return null;
	}, [profile]);

  const updateToken = useCallback(() => {
		sessionTokenFetch().then((sessionToken) => {
			if (sessionToken && spotAPI) {
				window.sessionToken = sessionToken;
				if (externalSearch && profile.shop && profile.shop.clusters[0] != "localhost") {
					spotAPI.headers({ });
					spotAPI.clusters(profile.shop.clusters);
				/*} else if (externalSearch && profile.shop && profile.shop.clusters[0] == "localhost") {
					spotAPI.headers({ });
					spotAPI.clusters(["spot-cluster-ca-0.moddapps.com"]);*/
				} else {
					spotAPI.clusters(["/api/search/engine"]);
					spotAPI.headers({ Authorization: "Bearer " + sessionToken });
				}
				// Once we have a token, release requests.
				if (spotAPI.spotRequests) {
					spotAPI.ajax = spotAPI.oldSpotAPIAjax;
					spotAPI.spotRequests.forEach((r) => {
						spotAPI.ajax.apply(spotAPI, r[1]).done((...args) => {
							r[0].resolve(...args);
						}).fail((...args) => {
							r[0].reject(...args);
						});
					});
					spotAPI.spotRequests = null;
				}
			}
		});
  }, [sessionTokenFetch, externalSearch, profile]);

  useEffect(() => {
		if (spotAPI) {
			updateToken();
			if (spotAPI.spotRefreshInterval)
				clearInterval(spotAPI.spotRefreshInterval);
			spotAPI.spotRefreshInterval = setInterval(() => {
				updateToken();
			}, 10000);
			return () => {
				if (spotAPI.spotRefreshInterval)
					clearInterval(spotAPI.spotRefreshInterval);
			}
		}
  }, [updateToken, spotAPI, externalSearch]);

  const spot = useMemo(() => {
		return { spotAPI: spotAPI, spotDOM: spotDOM, spotDefault: spotDefault };
  }, [spotAPI, spotDOM, spotDefault]);

  useEffect(() => {
    if (spotAPI && !spotDOM && !spotDefault) {
      const spotDOM = new window.SpotDOM(spotAPI);
      const spotDefault = new window.SpotDefault(spotDOM);
      spotDOM.countBehavior("exact").optionBehavior("exact");
      spotDOM.failBehavior(null);
      if (init)
        init(spotAPI, spotDOM, spotDefault);
      setSpotDefault(spotDefault);
      setSpotDOM(spotDOM); 
    }
  }, [spotAPI]);

  useEffect(() => {
		if (init && spotAPI && spotDOM && spotDefault)
			init(spotAPI, spotDOM, spotDefault);
  }, [init, spotDOM, spotDefault, spotAPI]);

	if (!spotDOM && allowNoState)
		return <div>{children}</div>;
	if (!spotDOM)
		return <div></div>;
	return <SpotContext.Provider value={spot}>{children}</SpotContext.Provider>;
}
