import { useState, useEffect, useRef, createContext, useContext, useMemo } from 'react';
import { Button, Icon, Heading, Stack, useRoute, useRedirect, Link, Card } from "admin-frontend";
import { Page } from "./Page";

export function Category() {
  const [route] = useRoute();
  const redirect = useRedirect();
  return (<Page><Card>
    {route && route.items && route.items.map((i, idx) => (
      <Card.Section key={'route-' + i.name}>
        <Stack vertical style={{textAlign: "left"}}>
          <Heading><Link url={i.path}><Stack alignment={"center"}><Icon source={i.icon}/><span> {i.name}</span></Stack></Link></Heading>
          <div>{i.description}</div>
        </Stack>
      </Card.Section>))}
  </Card></Page>);
}
