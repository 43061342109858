import React, {useState, useCallback, useEffect, useRef} from 'react';
import {
	Layout,
	Card,
	Button,
	Icon,
	DatePicker,
	Select,
	TextField,
	Label,
	Spinner,
	Link,
	Page as OldPage,
	Modal,
	Form,
	Tag,
	FormLayout,
	Pagination,
	TextContainer
} from 'admin-frontend';
import Moment from 'react-moment';
import moment from 'moment';
import { useLocation, useParams } from "react-router-dom";
import { Grid, rearrange } from "../components/Grid";
import { titleCase } from "../components/Fields";
import { Auth, MessageBanner, useRedirect, MultiTable, AuthorizationBanner, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { ResourcePickerButton } from "../components/ResourcePicker";
import { localeFormat } from "../components/Localization";
import { DeleteMajor, SaveMinor } from 'admin-frontend';
import { Page } from "../components/Page";


import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

// Simple shallow compare, assuming no objects in hash.
function isSame(a,b) {
  if (typeof(a) !== typeof(b))
    return false;
  for (var i in a) {
    if (typeof(a[i]) !== typeof(b[i]) || a[i] !== b[i])
      return false;
  }
  return Object.keys(a).length === Object.keys(b).length;
}


function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function computeColor(string) {
  const code = hashCode(string);
  const r = (((code >> 24) & 0xFF) / 255) * 60 + 180;
  const g = (((code >> 16) & 0xFF) / 255) * 60 + 180;
  const b = (((code >> 8) & 0xFF) / 255) * 60 + 180;
  return `rgb(${r}, ${g}, ${b})`;
}

const CounterGraph = ({ title, query, data, minHeight }) => {
	return <div>{data[0]}</div>;
};

const TableGraph = ({ title, query, data, labels, values, minHeight, setCanvasHeight }) => {
  useEffect(() =>{
    if (labels.length > 0)
      setCanvasHeight(Math.max(minHeight, labels.length * 30 + 120));
  }, [data]);
	return (values && <MultiTable
    headings={[titleCase(query.query), "Count"]}
    rows={values[0].data.map((value, idx) => {
      return [labels[idx], value];
    })}
  />);
};


const LineGraph = ({ title, query, labels, values, data, setCanvasHeight, minHeight }) => {
	const chartRef = useRef();
	const [chart, setChart] = useState(null);
	useEffect(() => {
		if (data && chartRef.current) {
			if (chart)
				chart.destroy()
      setCanvasHeight(minHeight);
      const uniqueLabels = Object.fromEntries(values.map((v) => { return [v.data[0][0], true] }));
			setChart(new Chart(chartRef.current.getContext('2d'), {
				type: 'line',
				data: {
					labels: Object.keys(uniqueLabels),
					datasets: values.map((v, idx) => { return {
            ...v,
            label: labels[idx],
            backgroundColor: computeColor(labels[idx])
          } })
				},
				options: {
          maintainAspectRatio: false
				}
			}));
      return () => chart && chart.destroy() && setChart(null)
		}
	// eslint-disable-next-line
	}, [data]);
	return (<canvas ref={chartRef}></canvas>);
}


const PieGraph = ({ title, query, labels, values, data, setCanvasHeight, minHeight }) => {
	const chartRef = useRef();
	const [chart, setChart] = useState(null);
	useEffect(() => {
		if (data && chartRef.current) {
			if (chart)
				chart.destroy()
      setCanvasHeight(minHeight);
			setChart(new Chart(chartRef.current.getContext('2d'), {
				type: 'pie',
				data: {
					labels: labels,
					datasets: [{
            data: values[0].data,
            backgroundColor: labels.map((label) => computeColor(label))
          }]
				},
				options:  {
					indexAxis: 'y',
					maintainAspectRatio: false,
					scales: {}
				}
			}));
      return () => chart && chart.destroy() && setChart(null)
		}
	// eslint-disable-next-line
	}, [data]);
	return (<canvas ref={chartRef}></canvas>);
}

const HistogramGraph = ({ title, query, labels, values, data, setCanvasHeight, minHeight }) => {
	const chartRef = useRef();
	const [chart, setChart] = useState(null);
	useEffect(() => {
		if (labels && values && chartRef.current) {
			if (chart)
				chart.destroy()
      setCanvasHeight(Math.max(minHeight || 0, Math.min(1000000, (Math.min(((query && query.limit) || 5), 100) * 16) + 100)));
			setChart(new Chart(chartRef.current.getContext('2d'), {
				type: 'bar',
				data: {
					labels: labels,
					datasets: values
				},
				options:  {
					indexAxis: 'y',
					maintainAspectRatio: false,
					scales: {},
					plugins: { legend: { display: false } }
				}
			}));
      return () => chart && chart.destroy() && setChart(null)
		}
	// eslint-disable-next-line
	}, [data]);
	return (<canvas ref={chartRef}></canvas>);
};


function computeDates(query, conditions)  {
  if (!query.date || query.date.type == 'dynamic')
    return { start_date: moment.utc(conditions.start_date).toISOString(), end_date: moment.utc(conditions.end_date).toISOString() };
  if (query.date.type == 'static')
    return { start_date: moment.utc(query.date.start).toISOString(), end_date: moment.utc(query.date.end).toISOString() };
  if (query.date.type == 'relative')
    return { start_date: moment.utc(conditions.end_date).subtract(query.date.start, 'day').add(1, 'minute').startOf('minute').toISOString(), end_date: moment.utc(conditions.end_date).subtract(query.date.end, 'day').toISOString() };
  return {};
}

export function Graph({ query, conditions, labels, values, maxHeight, ...options }) {
  const [data, setData] = useState(null);
  const [lastIssuedQuery, setLastIssuedQuery] = useState(null);
	const [canvasHeight, setCanvasHeight] = useState(maxHeight || 180);
  const authFetch = useSpotFetch();

	useEffect(() => {
	  if ((!lastIssuedQuery || !isSame(lastIssuedQuery.query, query) || !isSame(lastIssuedQuery.conditions, conditions))) {
      const hash = {
        ...query,
        ...computeDates(query, conditions),
        locale: conditions.locale
      };
      delete hash.date;
      setData(null);
	    authFetch("/api/reporting/query", { query: hash }).then((response) => setData(response));
      setLastIssuedQuery({ query: query, conditions: conditions });
	  }
	// eslint-disable-next-line
	}, [authFetch, query, conditions, lastIssuedQuery, setLastIssuedQuery]);

  if (!data)
    return (<div><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></div>);

	let actualLabels;
	let actualValues;
  if (query.group) {
    actualLabels = labels ? labels(query, data) : Object.keys(data);
    actualValues = values ? values(query, data) : Object.values(data).map((d) => ({ data: d }));
  } else {
    actualLabels = labels ? labels(query, data) : data.map((d) => localeFormat("en", d[0]));
    actualValues = values ? values(query, data) : [{ data: data.map((v) => v[1]) }];
  }

  let graph;
  if (query && query.graph_type === "counter")
    graph = <CounterGraph query={query} data={data} labels={actualLabels} values={actualValues} setCanvasHeight={setCanvasHeight} {...options}/>;
  else if (query && query.graph_type === "pie")
    graph = <PieGraph query={query} data={data} labels={actualLabels} values={actualValues} setCanvasHeight={setCanvasHeight} {...options}/>;
  else if (query && query.graph_type === "line")
    graph = <LineGraph query={query} data={data} labels={actualLabels} values={actualValues} setCanvasHeight={setCanvasHeight} {...options}/>;
  else if (query && query.graph_type === "table")
    graph = <TableGraph query={query} data={data} labels={actualLabels} values={actualValues} setCanvasHeight={setCanvasHeight} {...options}/>;
  else
    graph = <HistogramGraph query={query} data={data} labels={actualLabels} values={actualValues} setCanvasHeight={setCanvasHeight} {...options}/>;
  return <div className={`graph ${query.graph_type || "histogram"}-graph`} style={{ maxHeight: maxHeight + "px" }}><div style={canvasHeight ? {height: canvasHeight } : {}}>{graph}</div></div>;
}

export function Explorer() {
  const authFetch = useSpotFetch();
  const sessionTokenFetch = Auth.useFetchSessionToken();
  const [query, setQuery] = useState(null);
  const [dashboard, setDashboard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportDetails, setExportDetails] = useState(null);
  const [immutable, setImmutable] = useState(false);
  const [name, setName] = useState(null);
  const [ordering, setOrdering] = useState("0");
  const [profile] = Auth.useProfile();
  const [conditions, setConditions] = useState({ start_date: moment().local().startOf('day').utc().toISOString(), end_date: moment().local().endOf('day').utc().toISOString(), locale: null });
  const location = useLocation();
  const redirect = useRedirect();

  const groups = /(\d+)$/.exec(location.pathname);
  const id = groups && groups[0];


  useEffect(() => {
    if (!dashboard) {
      authFetch("/api/reporting/dashboard").then((r) => {
        setDashboard(r);
        if (id)
          setOrdering("" + r.queries.map((q) => q.id).indexOf(parseInt(id)));
      })
    }
  }, [dashboard, authFetch, id])

  useEffect(() => {
    if (!query) {
      if (id) {
        authFetch("/api/reporting/query/" + id).then((r) => {
          setQuery({ ...r.details });
          setName(r.name);
          setImmutable(r.immutable);
        });
      } else
        setQuery({ query: "searches", graph_type: "histogram", start_date: conditions.start_date, end_date: conditions.end_date });
    }
  }, [query, authFetch, id]);

  let localeSelect;
	if (profile && profile.shop.locales && profile.shop.locales.length > 1)
		localeSelect = <Select label="Locale" value={(query && query.locale) || ""} onChange={(val) => setQuery({ ...query, locale: val })} options={[{ label: "all locales", value: "" }].concat(profile.shop.locales.map(function(e) { return { label: e.name, value: e.code }; }))}/>

  function saveQuery() {
    authFetch("/api/reporting/query" + (id && !immutable ?  "/" + id : ""), { json: { name: name, details: query } }).then((r) => {
      let queryIds = dashboard.queries.map((q) => q.id === r.id ? r.id : q.id)
      queryIds = queryIds.filter((q) => q !== r.id && q !== id);
      queryIds.splice(parseInt(ordering), 0, r.id);
      authFetch("/api/reporting/dashboard", { json: { ids: queryIds } }).then((r) => {
        redirect("/analytics/reporting");
      })
    });
  }

  const computedDates = query && conditions && computeDates(query, conditions);

  return (<Page
    permission="analytics"
    isFullyLoading={!query}
    resourceName={{plural:"Dashboard", singular: "Dashboard"}}
    onBack={true}
    primaryAction={{content: id ? "Save" : "+ Add to Dashboard", onAction: () => {
      if (id)
        saveQuery();
      else
        setIsModalOpen(true);
    }}}
    secondaryActions={[{ disabled: ((query && query.graph_type) || '') == "counter", content: (<Stack alignment="center"><Icon source={SaveMinor} /><span>CSV</span></Stack>), onAction: () => {
      setExportDetails(computeDates(query, conditions));
    }}]}
  >{() => {
    const metadata = (<FormLayout><FormLayout.Group>
      <TextField label="Name" value={name} placeholder={"Name for display on dashboard."} onChange={setName}/>
      <Select label="Display Size" value={query.span + ""} onChange={(val) => { setQuery({ ...query, span: parseInt(val) }) }} options={[{label: "Small", value: "1" }, {label: "Medium", value: "2" },{label: "Large", value: "3" }]}/>
      <TextField label="Ordering" type="number" onChange={setOrdering} value={ordering}/>
    </FormLayout.Group></FormLayout>);
    return (<><Modal
      open={isModalOpen}
      onClose={() => { setIsModalOpen(false); }}
      title={"Name Analytic"}
      primaryAction={[{ content: "Add", onAction: () => {
        saveQuery();
      } }]}
      secondaryActions={[{ content: "Cancel", onAction: () => { setIsModalOpen(false); } }]}
    >
      <Modal.Section>{metadata}</Modal.Section>
    </Modal>
    <Modal
      open={exportDetails != null}
      onClose={() => { setExportDetails(null); }}
      title={"Export CSV"}
      primaryAction={[{ content: "Export", onAction: () => {
        const params = { ...query, ...exportDetails };
        if (params.limit != null)
          delete params.limit;
        if (params.date != null)
          delete params.date;
        sessionTokenFetch().then((sessionToken) => {
          redirect("/api/reporting/csv?" + Object.keys(params).map((k) => k + "=" + encodeURIComponent(params[k])).join("&") + "&token=" + encodeURIComponent(sessionToken), true);
          setExportDetails(null);
        });
      } }]}
      secondaryActions={[{ content: "Cancel", onAction: () => { setExportDetails(null); } }]}
    >
      <Modal.Section>
        <FormLayout><FormLayout.Group>
          <TextField label='Start' value={exportDetails && moment.utc(exportDetails.start_date).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setExportDetails({ ...exportDetails, start_date: moment(val).utc().format('YYYY-MM-DD HH:mm') })} type='datetime-local'/>
          <TextField label='End' value={exportDetails && moment.utc(exportDetails.end_date).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setExportDetails({ ...exportDetails, end_date: moment(val).utc().format('YYYY-MM-DD HH:mm') })} type='datetime-local'/>
        </FormLayout.Group></FormLayout>
      </Modal.Section>
    </Modal>
    {id && <Card title="Metadata" sectioned>{metadata}</Card>}
    <Card title="Parameters">
      <Card.Section>
        <FormLayout>
          <FormLayout.Group condensed>
            <Select label="Graph Type" options={[
              {label: "Counter", value: "counter"},
              {label: "Histogram", value: "histogram"},
              {label: "Pie", value: "pie"},
              {label: "Line", value: "line"},
              {label: "Table", value: "table"}
            ]} value={query.graph_type || "counter"} onChange={(val) => {
              setQuery({
                ...query,
                graph_type: val,
                query: (val !== "counter") ? "searches" : "queries",
                ...(val === "line" ? { group: "date" } : {}),
              });
            }}/>
            {query.graph_type !== "counter" && <Select label="Query Type" onChange={(val) => { setQuery({ ...query, query: val }); }} value={query.query} options={[{label: "Searches", value: "searches"}, {label: "Collections", value: "collections"}, {label:"Facets", value: "facets"}, {label: "Facet Values", value: "facet_values"}, {label: "Synonyms", value: "synonyms"}]}/>}
            {query.graph_type === "counter" && <Select label="Query Type" onChange={(val) => { setQuery({ ...query, query: val }); }} value={query.query} options={[{label: "Queries", value: "queries"}]}/>}
            <Select label="Count Type" onChange={(val) => { setQuery({ ...query, count_type: val }); }} value={query.count_type || "distinct_users"} options={[{label: "Frequency", value: "frequency"}, {label: "Distinct Users", value: "distinct_users"}]}/>
            <TextField label="Result Count" onChange={(val) => { setQuery({ ...query, limit: parseInt(val) }) }} value={(query.limit === null || query.limit === undefined ? 5 : query.limit) +""} type='number' min="1" max="200"/>
            <div>
              <Label>Specific Collection?</Label>
              <ResourcePickerButton label="Any Collection" resourceType="collection" onSelection={({ selection }) => { setQuery({ ...query, collection_id: selection[0].id }); }} />
            </div>
            <Select label="Tag" onChange={(val) => { setQuery({ ...query, tag: val == "" ? val : parseInt(val) })}} options={[{label: "Any Tag", value: ""}, {label: "No Tag", value: "0"}, ...(dashboard ? dashboard.tags.map((tag) => { return { label: tag.tag, value: (tag.id + "") } }) : [])]} value={query.tag == null ? "" : (query.tag + "")}/>
          </FormLayout.Group>
          <FormLayout.Group condensed>
            <Select label="Has Facet?" value={query.is_simple + ""} onChange={(val) => { setQuery({ ...query, is_simple: val === "true" ? true : (val === "false" ? false : "")}); }} options={[{label: "Either", value: ""}, {label: "No", value: "true"}, {label: "Yes", value: "false"}]}/>
            <Select label="Has Results?" value={query.has_results + ""} onChange={(val) => { setQuery({ ...query, has_results: val === "true" ? true : (val === "false" ? false : "")}); }} options={[{label: "Either", value: ""}, {label: "No", value: "false"}, {label: "Yes", value: "true"}]}/>
            <Select label="First Page?" value={query.is_first_page + ""} onChange={(val) => { setQuery({ ...query, is_first_page: val === "true" ? true : (val === "false" ? false : "")}); }} options={[{label: "Either", value: ""}, {label: "No", value: "false"}, {label: "Yes", value: "true"}]}/>
            <Select label="Default Sort Order?" value={query.is_default_sort_order + ""} onChange={(val) => { setQuery({ ...query, is_default_sort_order: val === "true" ? true : (val === "false" ? false : null)}); }} options={[{label: "Either", value: ""}, {label: "No", value: "false"}, {label: "Yes", value: "true"}]}/>
            <Select label="Has Search?" value={query.is_search + ""} onChange={(val) => { setQuery({ ...query, is_search: val === "true" ? true : (val === "false" ? false : "")}); }}  options={[{label: "Either", value: ""}, {label: "No", value: "false"}, {label: "Yes", value: "true"}]}/>
            <Select label="Has Collection?" value={query.is_collection + ""} onChange={(val) => { setQuery({ ...query, is_collection: val === "true" ? true : (val === "false" ? false : "")}); }}  options={[{label: "Either", value: ""}, {label: "No", value: "false"}, {label: "Yes", value: "true"}]}/>
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
      <Card.Section title="Date Behavior">
        <FormLayout>
          <FormLayout.Group>
            <Select label="Dates" options={[
              { label: "Dynamic Date Range", value: "dynamic" },
              { label: "Fixed Date Range", value: "static" },
              { label: "Relative Days", value: "relative" },
            ]} value={query.date && query.date.type} onChange={(val) => { setQuery({ ...query, date: { type: val, ...(val === "static" ? { start: conditions.start_date, end: conditions.end_date } : {}), ...(val === "relative" ? { start: 1, end: 0 } : {}) } }) }}/>
            {query.date && query.date.type == "static" && <TextField label='Start' value={moment.utc(query.date.start).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setQuery({ ...query, date: { ...query.date, start: moment(val).utc().format('YYYY-MM-DD HH:mm') } })} type='datetime-local'/>}
            {query.date && query.date.type == "static" && <TextField label='End' value={moment.utc(query.date.end).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setQuery({ ...query, date: { ...query.date, end: moment(val).utc().format('YYYY-MM-DD HH:mm') } })} type='datetime-local'/>}
            {query.date && query.date.type == "relative" && <TextField value={query.date.start + ""} onChange={(val) => { setQuery({ ...query, date: { ...query.date, start: parseInt(val) } }); }} label="Start Days from End Period" min="1" type='number'/>}
            {query.date && query.date.type == "relative" && <TextField value={query.date.end + ""} onChange={(val) => { setQuery({ ...query, date: { ...query.date, end: parseInt(val) } }); }} label="End Days from End Period" min="0" type='number'/>}
          </FormLayout.Group>
          {query.group && <FormLayout.Group>
            <Select disabled={true} value={query.group || "none"} label="Group By" options={[
              { label: "None", value: "none" },
              { label: "Date", value: "date" }
            ]} onChange={(val) => { setQuery({ ...query, group: val === "none" ? null : val }); }}/>
          </FormLayout.Group>}
        </FormLayout>
      </Card.Section>
    </Card>
    <Card title="Visualization" sectioned>
      <FormLayout>
        <FormLayout.Group condensed>
          <TextField label='Start' disabled={query.date && (query.date.type == "static" || query.date.type == "relative")} value={moment.utc(computedDates.start_date).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setConditions({ ...conditions, start_date: moment(val).utc().toISOString() })} type='datetime-local'/>
          <TextField label='End' disabled={query.date && query.date.type == "static"} value={moment.utc(computedDates.end_date).local().format('YYYY-MM-DD HH:mm')} onChange={(val) => setConditions({ ...conditions, end_date: moment(val).utc().endOf('minute').toISOstring() })} type='datetime-local'/>
          {localeSelect}
        </FormLayout.Group>
      </FormLayout>
      <br/>
      <Graph query={query} conditions={conditions}/>
    </Card></>);
  }}</Page>)
}

export function Reporting() {
  const [profile] = Auth.useProfile();
  const [locale, setLocale] = useState("");
  const [deletionModalId, setDeletionModalId] = useState(null);
  const [dashboard, setDashboard] = useState(null);
	const [{month, year}, setDate] = useState({month: new Date().getMonth(), year: new Date().getYear()+1900});
	const [selectedDates, setSelectedDates] = useState({
		start: moment().startOf('day').toDate(),
		end: moment().endOf('day').toDate(),
	});
	const handleMonthChange = useCallback(
		(month, year) => setDate({month, year}),
		[],
	);
  const authFetch = useSpotFetch();


  useEffect(() => {
    if (!dashboard) {
      authFetch("/api/reporting/dashboard").then((r) => {
        setDashboard(r);
      })
    }
  }, [dashboard, authFetch])


	let localeSelect;
	if (profile && profile.shop.locales && profile.shop.locales.length > 1)
		localeSelect = <Select value={locale} onChange={setLocale} labelInline options={[{ label: "all locales", value: "" }].concat(profile.shop.locales.map(function(e) { return { label: e.name, value: e.code }; }))}/>

	return (
		<Page
      isLoading={!dashboard}
      permission="analytics"
      isFullyLoading={!dashboard}
		>
      <Modal
        open={deletionModalId != null}
        onClose={() => { setDeletionModalId(null); }}
        title={"Deletion Confirmation"}
        primaryAction={[{ content: "Delete", onAction: () => {
          authFetch("/api/reporting/dashboard", { json: { ids: dashboard.queries.filter((q) => q.id !== deletionModalId).map((q) => q.id) } }).then(function(r) {
            setDashboard(r);
            setDeletionModalId(null);
          });
        } }]}
        secondaryActions={[{ content: "Cancel", onAction: () => { setDeletionModalId(null); } }]}
      >
        <Modal.Section>
          <TextContainer>
            Are sure you want to remove this query from your dashboard?
          </TextContainer>
        </Modal.Section>
      </Modal>
			<Layout>
				<Layout.Section>
          <MessageBanner style={{marginBottom: "12px"}}/>
					<Card title="Daily Reporting">
						<Card.Section>
							<Stack alignment="center">
                <DatePicker
                  onChange={setSelectedDates}
                  selected={selectedDates}
                  allowRange
                  disableDatesAfter={new Date()}
                />
								<div>in</div>
								{localeSelect}
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
			<Grid itemsPerRow={3} gap={{x:10,y:10}} cellHeight={270} className='query-cards snapping' /*onDragDrop={(source, destination) => {
        const newQueries = rearrange(dashboard.queries, source, destination);
        setDashboard({
          ...dashboard,
          queries: newQueries
        });
        authFetch("/api/reporting/dashboard", { json: { ids: newQueries.map((query) => query.id) } });
			}}*/>
         {dashboard && dashboard.queries && dashboard.queries.map((q, idx) => (
          <Grid.Item columns={q.details.span} rows={q.details.span} key={"query-" + q.id}>
            <Card
              title={<Link url={"/analytics/explorer/" + q.id}>{q.name}</Link>}
              actions={[
                {content: <Icon source={DeleteMajor} />, onAction: () => setDeletionModalId(q.id)}
              ]} sectioned
            >
              <div style={{minHeight:"180px"}}>
                <Graph query={{ ...q.details }} conditions={{ locale: locale, start_date: selectedDates.start, end_date: selectedDates.end}} minHeight={280*(q.details.span || 1) - 100} maxHeight={280*(q.details.span || 1) - 100}/>
              </div>
            </Card>
          </Grid.Item>
        ))}
			</Grid>
		</Page>
	);
}

function shouldShowEvent(event) {
  if (event.event_type == 0)
    return event.category != "Recommendation";
  return true;
}

function getEventType(event) {
  if (event.event_type == 0)
    return event.category ? event.category : (event.search ? "Search" : (event.collection ? "Collection" : "Query"));
  if (event.event_type == 1)
    return "Hello!";
  if (event.event_type == 2)
    return "Identify";
  if (event.event_type == 3)
    return "Clicked Product";
  if (event.event_type == 4)
    return "Added to Cart";
  if (event.event_type == 5)
    return "Completed Order";
  return event.event_type;
}

function getEventDetails(event) {
  if (event.event_type == 0) {
    var parts = []
    if (event.locale)
      parts.push(" in locale '" + event.locale + "'");
    if (event.collection) {
      parts.push(" in collection '");
      if (event.collection.id)
        parts.push(<Link url={"shopify://admin/collections/" + event.collection.id}>{event.collection.handle}</Link>);
      else
        parts.push(event.collection.handle);
      parts.push("'");
    }
    if (event.search)
      parts.push(" searching '" + event.search + "' ");
    if (event.sort_order && !event.is_default_sort_order)
      parts.push(" explicitly sorting by '" + event.sort_order + "' ");
    if (event.is_first_page)
      parts.push(" on the first page ");
    if (!event.has_results)
      parts.push(" and got no results ");
    return ["Customer made a query", ...parts, "."];
  } if (event.event_type == 1)
    return "Customer landed on site for the first time.";
  if (event.event_type == 2)
    return (<>Customer was identified as customer <Link url={"shopify://admin/customers/" + event.argument1}>{event.argument1}</Link> ({(event.customer ? event.customer.email : "Unknown Customer")}).</>);
  if (event.event_type == 3)
    return (<>Customer clicked on {(event.product ? (<Link url={"shopify://admin/products/" + event.product.id}>{event.product.title}</Link>) : "an unknown product")} in position {event.argument3 + 1}.</>);
  if (event.event_type == 4)
    return (<>Customer added {event.argument3} x {(event.product ? (<Link url={"shopify://admin/products/" + event.product.id}>{event.product.title}</Link>) : "an unknown product")} to their cart.</>);
  return JSON.stringify([event.argument1, event.argument2, event.argument3]);
}

export function Journeys() {

  const [filter, setFilter] = useState("");
  const [filterType, setFilterType] = useState("id");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [journeys, setJourneys] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const [events, setEvents] = useState(null);
  const authFetch = useSpotFetch();
  const [profile] = Auth.useProfile();

  const { journeyId } = useParams();
  const redirect = useRedirect();

	const hasCustomerScope = profile && profile.shop && /_customers/.test(profile.shop.scope);

  useEffect(() => {
    if (refresh) {
      setIsLoading(true);
      setRefresh(false);
      authFetch("/api/reporting/journeys" + (journeyId ? "/" + journeyId : ""), { query: { page: page, filter: filter, filter_type: filterType } }).then((r) => {
        setIsLoading(false);
        if (journeyId) {
          setEvents(r.events);
        } else {
          setJourneys(r.journeys);
        }
      });
    }
  }, [authFetch, refresh]);
  useEffect(() => {
    if (hasCustomerScope)
      setFilterType("email");
  });

  const MILLISECONDS_BETWEEN_JOURNEYS = 5*60*1000;
  const journeyList = events && [];

  if (events) {
    for (let i = events.length - 1; events && i >= 0; --i) {
      if (i == events.length - 1 || Math.abs(moment(events[i].date) - moment(journeyList[journeyList.length - 1][journeyList[journeyList.length - 1].length - 1].date)) > MILLISECONDS_BETWEEN_JOURNEYS)
        journeyList.push([]);
      journeyList[journeyList.length - 1].push(events[i]);
    }
  }

	return (
		<Page
      isLoading={!isLoading}
      isFullyLoading={!journeys && !events}
			resourceName={{singular: "customer journey", plural: "customer journeys"}}
			onBack={journeyId && (() => { redirect('/analytics/journeys'); })}
		>
      {!journeyId && <AuthorizationBanner message={"This page functions best if we can read your customer information from your storefront.\
      Click below to grant Spot access to the `read_customers` scope. Spot won't store any of this information,\
      but it will use it to make this page easier to use (by showing emails, and other customer details), on this page only, where we have \
      the customer's ID."} style={{marginBottom: "12px"}} scopes={["read_customers"]} />}
      {journeyId && journeyList && <Card title={"Journey for " + journeyId} sectioned>
        {journeyList.reverse().map((journey) => (<Card.Section><MultiTable
          headings={["Date", "Event", "Details"]}
          rows={journey.reverse().filter((e) => shouldShowEvent(e)).map((j) => [moment(j.date).format('YYYY-MM-DD HH:mm:ss'), getEventType(j), (<>{getEventDetails(j)}</>)])}
        /></Card.Section>))}
      </Card>}
      {!events && <Card title="Journey List" sectioned>
        <TextContainer>
          <p>
            Below, you can peruse a list of your customers' journey. In order to be positively identify a session as a particular customer, the Spot Events API
            must be set up on your store.
          </p>
          <p>
            If you haven't set up the events API and are only seeing anonymous customers here, and would like to be able to identify
            customers that have logged in, contact support for details.
          </p>
        </TextContainer>
        <br/>
        {journeys && journeys.length > 0 && <Form>
          <FormLayout>
            <FormLayout.Group>
              <Select options={[...(hasCustomerScope ? [{label: "Email", value: "email"}] : []), {label: "Customer ID", value: "id"}]}/>
              <TextField value={filter} onChange={setFilter}/>
              <Button primary onClick={() => { setRefresh(true); }} disabled={isLoading}>Filter</Button>
            </FormLayout.Group>
          </FormLayout>
        </Form>}
        <MultiTable
          headings={["Session", "Last Activity", "Customer"]}
          resourceName={{singular:"journey", plural: "journeys"}}
          rows={journeys && journeys.map((j) => [<Tag>{j.session_id}</Tag>, moment(j.date).format('MMMM DD, YYYY HH:mm:ss'), j.email || j.customer_id || "Anonymous"])}
          onRowClick={(row, idx) => {
            redirect("/analytics/journeys/" + journeys[idx].session_id);
          }}
        />
        <br/>
        <Stack distribution="center">
          <Pagination
            hasPrevious={!isLoading && page > 1}
            onPrevious={() => {
              setPage(parseInt(page)-1);
              setRefresh(true);
            }}
            hasNext={!isLoading && journeys && journeys.length == 50}
            onNext={() => {
              setPage(parseInt(page)+1);
              setRefresh(true);
            }}
          />
        </Stack>
      </Card>}
    </Page>
  );
}
