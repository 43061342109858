import React, { useEffect } from 'react';
import {
  TextField,
  Select
} from 'admin-frontend';
import { titleCase } from "./Fields.jsx"

export function getRelativeDateLabel(value) {
  var groups = /(\d+)?(\w+)/.exec(value);
  if (value === "now")
    return "Present";
  if (!groups)
    return "";
  return groups[1] + " " + titleCase(groups[2]) + " Ago";
}

export function RelativeDateField({
  value,
  label,
  onChange,
  error
}) {
  var groups = /(\d+)?(\w+)/.exec(value);
  useEffect(() => {
    if (!value)
      onChange("now");
  });
  const type = !groups || groups[2] === "now" ? "now" : groups[2];
  const number = groups[1] || 1;
  const typeElement = (<Select
    label={type === "now" && label}
    options={[
      { label: "Present", value: "now" },
      { label: "Day(s) Ago", value: "days" },
      { label: "Week(s) Ago", value: "weeks" },
      { label: "Month(s) Ago", value: "months" },
      { label: "Year(s) Ago", value: "years" }
    ]}
    onChange={(value) => { onChange(value === "now" ? value : (number + value)); }}
    value={type}
  />);
  return type !== "now" ? (<TextField
    label={label}
    value={number}
    connectedRight={typeElement}
    onChange={(value) => { onChange(value + type); }}
    type="number"
    error={error}
  />) : typeElement;
}

export function DateField({
  value,
  label,
  onChange,
  error
}) {
  return (<TextField
    label={label}
    value={value}
    type="datetime-local"
    onChange={onChange}
    error={error}
  />);
}
