import React, {useState, useEffect, useCallback, useMemo } from 'react';
import {
  TextContainer,
  Icon,
  Spinner,
  Button,
  Filters,
  Checkbox,
  Thumbnail,
  Modal
} from 'admin-frontend';
import { Auth, MultiTable, Stack } from "admin-frontend";
import { useSpotAPI } from "./API"
import { SoftPackMajor, SearchMajor } from 'admin-frontend';
import { PlaceholderImage } from "./Page";
import { titleCase, FieldSpecifier, CachedTextField, productFields } from "./Fields";

export function ResourcePickerButton({ label, primary, fullWidth = true, disabled, onCancel, resourceType = "product", value, onChange, onIdentified, onUnidentified, type = "select", split, large, merge, selectMultiple, onSelection, ...others }) {
  const [isOpen, setIsOpen] = useState(false);
  const [cachedInfo, setCachedInfo] = useState({});
  const [attemptedIds, setAttemptedIds] = useState({});

  const spotAPI = useSpotAPI();

  const target = resourceType == "variant" ? "products": (resourceType.toLowerCase() + "s");
  const searchIdentifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  const propertyIdentifierField = resourceType == "variant" ? "id" : searchIdentifierField; 
  const needsToLoad = type == "select" && value && value.length > 0 && value.filter((id) => cachedInfo[id] == null && !attemptedIds[id]).length > 0;

  useEffect(() => {
    if (needsToLoad) {
      spotAPI.s()[searchIdentifierField]("in", value).rows(1000).targets([target]).split(split || "none").productMerge(merge || "none").e().done((items) => {
        setAttemptedIds({ ...attemptedIds, ...Object.fromEntries(value.map((id) => [id, true])) });
        let newCachedInfo;
        if (resourceType == "variant") {
          newCachedInfo = { ...cachedInfo, ...Object.fromEntries(items.flatMap((i) => { return i.variants.map((v) => { return { ...v, product: i }; }) }).map((i) => [i.id, i])) };
        } else {
          newCachedInfo = { ...cachedInfo, ...Object.fromEntries(items.map((i) => [(Array.isArray(i) ? i[0] : i)[propertyIdentifierField], i])) };
        }
        const unknownValues = value.filter((id) => newCachedInfo[id] == null);
        const identifiedValues = value.filter((id) => newCachedInfo[id] != null && cachedInfo[id] == null);
        unknownValues.forEach((id) => { newCachedInfo[id] = false; });
        if (onUnidentified && unknownValues.length)
          onUnidentified(unknownValues);
        if (onIdentified && identifiedValues.length)
          onIdentified(identifiedValues.map((id) => newCachedInfo[id]));
        setCachedInfo(newCachedInfo);
      });
    }
  }, [needsToLoad, setCachedInfo, cachedInfo]);

  const capitalized = resourceType.charAt(0).toUpperCase() + resourceType.slice(1);

  return (<span>
    <Button disabled={disabled} fullWidth={fullWidth} primary={primary} loading={needsToLoad} onClick={() => setIsOpen(true)}>
      {
        label ? (typeof(label) == 'function' ? label(value && value.length > 0 && (value.map((id) => cachedInfo[id] ? cachedInfo[id] : id))) : label) : (
          (type == "select" && value && value.length > 0 && (value.map((id) => cachedInfo[id] ? (
            resourceType == "variant" ? cachedInfo[id].product.title + " - " + cachedInfo[id].title : ((Array.isArray(cachedInfo[id]) ? cachedInfo[id][0] : cachedInfo[id]).title)
          ) : id).join(", "))) ||
          ((type == "select" ? "Select" : "Add") + (!selectMultiple ? (" a " + capitalized) : (" " + capitalized + "s")))
        )
      }
    </Button>
    <ResourcePicker
      open={isOpen}
      large={large}
      title={label}
      onCancel={({ ...others }) => {
        setIsOpen(false);
        if (onCancel)
          onCancel({ ...others });
      } }
      value={value && value.map((v) => cachedInfo[v]).filter((v) => v)}
      onSelection={({ selection, ...others }) => {
        setIsOpen(false);
        setCachedInfo({ ...cachedInfo, ...Object.fromEntries(selection.map((i) => [(Array.isArray(i) ? i[0] : i)[propertyIdentifierField], (split ? (i.handle + "-" + i[propertyIdentifierField]) : i.handle)])) })
        if (onChange)
          onChange(selection.map((i) => ((Array.isArray(i) ? i[0] : i)[propertyIdentifierField])), "selection");
        if (onSelection)
          onSelection({ selection, ...others });
      } }
      resourceType={resourceType}
      selectMultiple={selectMultiple}
      split={split}
      merge={merge}
      {...others}
    />
  </span>)
}

export function ResourceTable({ resourceType = "product", selectMultiple, initialQuery, headings, beforeQueryRun, onSelection, onCancel, split, merge, onClear, row, transform, filters, appliedFilters, items, setItems, selectedItems, setSelectedItems }) {
  const [isLoading, setIsLoading] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadField, setUploadField] = useState(null);
  const [uploadValue, setUploadValue] = useState(null);
  const [page, _setPage] = useState(1);
  const [query, _setQuery] = useState(initialQuery);
  const sessionTokenFetch = Auth.useFetchSessionToken();
  const [profile] = Auth.useProfile();

  const maxSelection = typeof(selectMultiple) == "number" ? selectMultiple : (selectMultiple ? null : 1);
  const spotAPI = useSpotAPI();


  // What we pass to spot to search for this.
  const searchIdentifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  // Where we get the property FROM spot info.
  const propertyIdentifierField = resourceType == "variant" ? "id" : searchIdentifierField;
  
  const currentTransform = transform || ((fn, items) => { fn(items); });
  const target = resourceType == "variant" ? "products": (resourceType.toLowerCase() + "s");

  const selectedItemHash = selectedItems ? Object.fromEntries(selectedItems.map((i) => [(Array.isArray(i) ? i[0] : i)[propertyIdentifierField], i])) : {};

  const runUploadQuery = useCallback((uploadField, uploadValue) => {
    if (!isLoading && uploadValue && uploadField) {
      setIsLoading(true);
      var sq = spotAPI.s().allVariants(false).targets([target]);
      let valueList;
      if (maxSelection)
        sq = sq.paginate(maxSelection);
      let queryMaker = sq;
      // uploadField.forEach((field) => queryMaker = queryMaker[field]);
      queryMaker = queryMaker[uploadField[0]]
      valueList = uploadValue.split(/\s*[,\s]\s*/).filter((v) => v != "");
      // for custom fields where you specify a field name
      if (uploadField.length === 2) {
        const fieldType = uploadField[0];
        const fieldName = uploadField[1];
        
        sq = queryMaker.call(sq, uploadField[1], "in", valueList)

        sq = sq.pins(valueList.map(
          (value) => {
            return {
              [fieldType]: {
                  [fieldName]: { "in": value }
              }
          };
          }
        ))
      }
      else
        sq = queryMaker.call(sq, "in", valueList)
      if (split)
        sq = sq.split(split);
      if (merge)
        sq = sq.productMerge(merge);
      if (beforeQueryRun)
        sq = beforeQueryRun(sq);

      sq.e().done((newItems) => {
        const field = productFields.filter((f) => f.handle == uploadField[0])[0];
        const valueHash = Object.fromEntries(valueList.map((v, idx) => [v, idx]));
        var productHash = {};
        if (field.level == "product") {
          productHash = Object.fromEntries(newItems.filter((p) => valueHash[p[uploadField[0]]] != null).map((p) => {
            return [(Array.isArray(p) ? p[0] : p).split_id, valueHash[p[uploadField[0]]]]
          }));
        } else if (field.level == "variant") {
          productHash = Object.fromEntries(newItems.flatMap((p) => { p.variants.forEach((v) => v.product = p); return p.variants; }).filter((v) => valueHash[v[uploadField[0]]] != null).map((v) => {
            return [v.product.split_id, valueHash[v[uploadField[0]]]];
          }));
        }
        newItems = newItems.sort((a,b) => productHash[a.split_id] - productHash[b.split_id]);
        if (resourceType == "variant") {
          newItems = newItems.flatMap((p) => {
            p.variants.forEach((v) => { v.product = p; });
            return p.variants;
          });
        }
        currentTransform((newItems) => {
          setItems(newItems);
          setSelectedItems(newItems);
          setIsLoading(false);
        }, newItems);
      });
    }
  }, [isLoading, spotAPI, target, maxSelection, split, beforeQueryRun, resourceType, currentTransform]);

  const runQuery = useCallback((query, page) => {
    if (!isLoading) {
      setIsLoading(true);
      var sq = spotAPI.s().allVariants(false).page(page).targets([target]);
      if (query)
        sq = sq.search(query);
      if (split)
        sq = sq.split(split);
      if (merge)
        sq = sq.productMerge(merge);
      if (beforeQueryRun)
        sq = beforeQueryRun(sq);
      sq.e().done((newItems) => {
        if (resourceType == "variant") {
          newItems = newItems.flatMap((p) => {
            p.variants.forEach((v) => { v.product = p; });
            return p.variants;
          });
        }
        newItems = page === 1 ? newItems : [...(items || []), ...newItems];
        currentTransform((newItems) => {
          setItems(newItems);
          setIsLoading(false);
        }, newItems);
      });
    }
  }, [isLoading, resourceType, items, split, merge, setItems, spotAPI, beforeQueryRun]);

  const setPage = useCallback((page, newquery) => {
    _setPage(page);
    runQuery(newquery !== undefined ? newquery : query, page);
  }, [_setPage, query, runQuery]);

  const setQuery = useCallback((query, reset) => {
    _setQuery(query);
    if (reset)
      setPage(1, query);
    else
      runQuery(query, page);
  }, [runQuery, _setQuery, page, setPage]);

  useEffect(() => {
    if (setSelectedItems) {
      if (selectedItems) {
        const targetList = selectedItems.filter((s) => typeof(s) != 'object');
        if (targetList.length > 0) {
          spotAPI.s().paginate(1000).allVariants(false).targets([target])[searchIdentifierField]("in", targetList).split(split || "none").productMerge(merge || "none").e().done(function(items) {
            if (resourceType == "variant")
              items = items.flatMap((i) => i.variants.map((v) => { return { ...v, product: i }; }))
            setSelectedItems([...selectedItems.filter((s) => typeof(s) == "object"), ...items]);
          });
        }
      } else {
        setSelectedItems([]);
      }
    }
  }, [selectedItems, spotAPI]);

  useEffect(() => {
    runQuery();
  }, [beforeQueryRun, split, merge]);

  function toggleItem(item) {
    const identifier = (Array.isArray(item) ? item[0] : item)[propertyIdentifierField];
    if (!selectedItemHash[identifier]) {
      if (maxSelection === null || selectedItems.length < maxSelection)
        setSelectedItems([ ...selectedItems, item ]);
    } else {
      setSelectedItems(selectedItems.filter((i) => (Array.isArray(i) ? i[0] : i)[propertyIdentifierField] != identifier));
    }
  }

  const scrolledToBottom = useCallback(() => {!isLoading && setPage(page+1)}, [isLoading, setPage, page]);

  return items && (<Stack vertical>
    {!isUpload && <Stack>
      <Stack.Item fill>
        <Filters
          queryValue={query}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={(val) => setQuery(val, true)}
          onQueryClear={() => setQuery('', true)}
          onClearAll={() => {}}
        />
      </Stack.Item>
      <Button square onClick={() => { setIsUpload(true); }}><Icon source={SoftPackMajor}/></Button>
    </Stack>}
    {isUpload && <Stack vertical>
      <Stack><Stack.Item fill><FieldSpecifier field={uploadField} fields={productFields.filter((v) => /(sku|handle|vendor|tag|barcode|id|product_metafield|variant_metafield|product-custom-field|variant_custom_field|variant_id)/i.test(v.handle)) } onChange={(val) => { setUploadField(val);  runUploadQuery(val, uploadValue); }}/></Stack.Item><Button square onClick={() => { setIsUpload(false); }}><Icon source={SearchMajor}/></Button></Stack>
      <CachedTextField idle={0.25} multiline={10} value={uploadValue} placeholder={"Please enter a comma or space separated list of your above sepecified attribute."} onChange={(val) => { 
        setUploadValue(val); 
        runUploadQuery(uploadField, val);
      }}/>
    </Stack>}
    {items && <MultiTable
      loading={isLoading}
      onRowClick={setSelectedItems && ((row, idx) => toggleItem(items[idx]))}
      headings={headings}
      rows={items && items.map((item, idx) => {
        const image = (spotAPI.getSizedImage(resourceType === "collection" ? item.image : (resourceType == "variant" ? spotAPI.getProductImage(item.product, item) : spotAPI.getProductImage(item)), "64x64"));
        const title = resourceType === "collection" ? item.title : (resourceType == "variant" ?  spotAPI.getProductTitle(item.product, item) : spotAPI.getProductTitle(item));
        const identifier = (Array.isArray(item) ? item[0] : item)[propertyIdentifierField];
        return [
          ((maxSelection === null || (selectedItems || []).length < maxSelection || selectedItemHash[identifier]) && setSelectedItems ? (
              <Checkbox checked={selectedItemHash[identifier]} /*onChange={() => toggleItem(item)}*//>) : (<span/>)),
          ...(row ? row(item) : [(image ? (<Thumbnail
            source={image}
            size="medium"
            alt={title}
          />) : <PlaceholderImage width={64} height={64}/>),
          title
          ])
        ];
      })}/>}
  </Stack>);
}

export function ResourcePicker({ open, large, title, value, resourceType, selectMultiple, onSelection, onChange, onCancel, onClear, allowNoSelection, ...props }) {  
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);
  

  // What we pass to spot to search for this.
  const searchIdentifierField = resourceType == "variant" ? "variant_id" : (resourceType == "product" ? "split_id" : "id");
  // Where we get the property FROM spot info.
  const propertyIdentifierField = resourceType == "variant" ? "id" : searchIdentifierField;

  const chooseValues = useCallback((items) => {
    if (onSelection)
      onSelection({ selection: items });
    if (onChange) {
      onChange(items.map((item) => {
        const identifier = (Array.isArray(item) ? item[0] : item)[propertyIdentifierField];
        return identifier;
      }));
    }
  }, [onSelection, onChange]);
  
  useEffect(() => {
    if (open && value && Array.isArray(value)) {
      const valueEntries = Object.fromEntries(value.map((v) => {
        const identifier = (Array.isArray(v) ? v[0] : v)[propertyIdentifierField];
        return [identifier, v];
      }));
      const selectedEntries = Object.fromEntries(selectedItems.map((item) => {
        const identifier = (Array.isArray(item) ? item[0] : item)[propertyIdentifierField];
        return [identifier, item];
      }));
      const missingValues = value.filter((s) => !selectedEntries[s]);
      const extraValues = Object.keys(selectedEntries).filter((e) => !valueEntries[e]);
      if (missingValues.length > 0 || extraValues.length > 0)
        setSelectedItems([...Object.keys(selectedEntries).filter((e) => !valueEntries[e]).map((e) => selectedEntries[e]), ...missingValues]);
    }
  }, [value, open]);

  const maxSelection = typeof(selectMultiple) == "number" ? selectMultiple : (selectMultiple ? null : 1);
  
  if (!open)
    return null;
    
  return <Modal
    open={true}
    large={large}
    onClose={() => { onCancel({}); setSelectedItems([]); }}
    title={title || "Add " + titleCase(resourceType)}
    primaryAction={{ disabled: (selectedItems.length === 0 && !allowNoSelection), content: 'Select', onAction: () => { chooseValues(selectedItems); } }}
    secondaryActions={[...(selectMultiple && onClear !== false ? [{ content: 'Clear', onAction: () => { chooseValues([]); setSelectedItems([]);  } }] : []), { content: 'Cancel', onAction: () => { onCancel({}); setSelectedItems([]); } }]}
    footer={selectedItems.length + (maxSelection !== null ? "/" + maxSelection : "") + " " + resourceType.toLowerCase() + "s selected"}
  >
    {!items && <div style={{ padding: "50px" }}><Stack vertical alignment="center" distribution="center"><Spinner size="large"/></Stack></div>}
    <Modal.Section>
      <ResourceTable pages={page} items={items} selectMultiple={selectMultiple} selectedItems={selectedItems} setSelectedItems={setSelectedItems} setItems={setItems} resourceType={resourceType} {...props}/>
    </Modal.Section>
  </Modal>;
}
