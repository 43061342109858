import React, {useState, useEffect, useCallback, useMemo } from 'react';
import {
  Card,
  Button,
  Checkbox,
  Select,
  FormLayout,
  TextField,
  TextContainer,
  DisplayText,
  Label,
  RangeSlider,
  Tabs
} from 'admin-frontend';


import { StreamLanguage } from '@codemirror/language';
import { css } from '@codemirror/legacy-modes/mode/css';
import { javascript } from '@codemirror/legacy-modes/mode/javascript';
import CodeMirror from "@uiw/react-codemirror";
import { MultiTable, Auth, useRedirect, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"
import { useParams, useLocation } from "react-router-dom";

export function TapcartSection({ id, rulesets, setSettings, settings, layout }) {
  return (
    <FormLayout>
      {layout.map((field) => {
        if (field.type == "multiline")
          return <TextField label={field.label} multiline={10} value={settings[field.id]}  onChange={(value) => { setSettings({ ...settings, [field.id]: value }) }}/>
        else if (field.type == "javascript" || field.type == "css") {
          return (
            <Stack vertical>
              {field.label && <Label>{field.label}</Label>}
              <CodeMirror extensions={[StreamLanguage.define(field.type == "javascript" ? javascript: css)]} value={settings[field.id]} height={"100px"} onChange={(value) => { setSettings({ ...settings, [field.id]: value }) }}/>
            </Stack>
          )
        } else if (field.type == "select")
          return <Select label={field.label} options={field.options} value={settings[field.id]} onChange={(value) => { setSettings({ ...settings, [field.id]: value }) }}/>
        else if (field.type == "ruleset")
          return <Select label={field.label} options={[{ label: "Situation Default", value: "0" }, ...rulesets.map((r) => { return { label: r.handle, value: (r.id + "") } })]} value={settings[field.id] != null ? (settings[field.id] + "") : "0"} onChange={(value) => { setSettings({ ...settings, [field.id]: parseInt(value) }) }}/>
        else if (field.type == "checkbox")
          return <Checkbox label={field.label} checked={settings[field.id] != null ? settings[field.id] : field.value} onChange={(value) => { setSettings({ ...settings, [field.id]: value }); }} />
        else if (field.type == "text")
          return <TextField label={field.label}  value={(settings[field.id] != null ? settings[field.id] : field.value)} onChange={(value) => { setSettings({ ...settings, [field.id]: value }) }}/>
        else if (field.type == "number")
          return <TextField type={field.type} label={field.label}  value={(settings[field.id] != null ? settings[field.id] : field.value + "")} onChange={(value) => { setSettings({ ...settings, [field.id]: value }) }}/>
      })}
    </FormLayout>
  );
}

export function Tapcart() {
  const redirect = useRedirect();
  const authFetch = useSpotFetch();
  const [isLoading, setIsLoading] = useState(true);
  const [tapcart, setTapcart] = useState(null);
  const [tabSelected, setTabSelected] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [rulesets, setRulesets] = useState(null);

  useEffect(() => {
    if (!tapcart) {
      authFetch('/api/setup/integrations/tapcart').then((r) => {
        if (r.integration) {
          setTapcart(r.integration);
        } else {
          setIsChanged(true);
          setTapcart({});
        }
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!rulesets) {
      authFetch('/api/global/rulesets').then((r) => {
        setRulesets(r.rulesets);
      });
    }
  }, []);

  const commonFields = [
    { label: "Governing Display Rule", id: "rule", type: "ruleset" },
    { label: "Custom CSS", id: "css", type: "css", eval: `var element = document.createElement("style"); element.setAttribute("type", "text/css"); element.innerHTML = value;` },
    { label: "Custom JS", id: "js", type: "javascript", eval: `eval(value);` }
  ];
  const resultsFields = [
    { label: "Results per Page", type: "number", id: "paginate", value: 12 },
    { label: "Default Product Renderer", id: "renderer", type: "javascript" },
    { label: "Show Sort Dropdown", type: "checkbox", id: "sort_dropdown", value: true },
    { label: "Pagination Type", type: "select", id: "pagination_type", options: [{ label: "Paged", value: "paged" }, { label: "Infinite Scroll", value: "infinite" }, { label: "Load More", value: "load-more" }] }
  ]

  const tabs = [
    {
      id: "general",
      content: "General Settings",
      layout: [
        ...commonFields,
        { label: "Default Product Renderer", id: "renderer", type: "javascript" },
      ]
    },
    {
      id: "search-bar",
      content: "Search Bar",
      layout: [
        { label: "Instant Search Results", type: "number", id: "paginate", value: 12 },
        ...commonFields
      ]
    },
    {
      id: "collection-results",
      content: "Collection Results Page",
      layout: [
        ...resultsFields,
        { label: "Display Rule Behavior", type: "select", id: "ruleset-override", options: [{ label: "Allow Collection-Specific Display Rules", value: "1" }, { label: "Disallow Collection-Specific Display Rules", value: "1" }] },
        ...commonFields
      ]
    },
    {
      id: "search-results",
      content: "Search Results Page",
      layout: [
        ...resultsFields,
        ...commonFields
      ]
    },
    {
      id: "recommendations", content: "Recommendations",
      layout: [
        { label: "Results", type: "number", id: "paginate", value: 12 },
        ...commonFields
      ]
    }
  ];

  return (<Page
    isFullyLoading={tapcart == null || rulesets == null}
    onSave={() => {
      setIsLoading(true);
      authFetch('/api/setup/integrations/tapcart', { json: { integration: tapcart } }).then((r) => {
        setTapcart(r.integration);
        setIsLoading(false);
        setIsChanged(false);
      });
    }}
    isLoading={isLoading}
    isChanged={isChanged}
    onBack={() => { redirect('/setup/integrations'); }}
  >{(currentLocale, currentTheme) => {
    return (<Card title={<img src={process.env.PUBLIC_URL + '/tapcart.png'} width={256} />}>
      <Tabs tabs={[{ content: "Overview", id: "overview" }, ...tabs]} selected={tabSelected} onSelect={(tabIndex) => { setTabSelected(tabIndex); }}>
        {tabSelected == 0 && (<>
          <Card.Section title="Overview">
            <TextContainer>
              <p>Welcome to Spot's tapcart integration!</p>

              <p>By default, Spot comes out of the box on Tapcart working with full features, and mostly paying attention to your existing configuration, so long as you are using Spot's <b>custom blocks</b>.</p>

              <p>However, should you want to change anything about how your Tapcart app is using Spot, you may do so here.</p>

              <p>At any time, please feel free to contact support if you have any questions about your Tapcart integration.</p>
            </TextContainer>
          </Card.Section>
          <Card.Section title="Install - Metafields">
            <TextContainer>
              <p>In order to function correctly, Spot requires several <b>metafields</b> from your shop, that you can configure under <b>Settings</b> -> <b>Customizations</b> in Tapcart.</p>
              <p>You will want to have the following metafields set up to come through in Tapcart:</p>
              <MultiTable
                headings={["Object", "Namespace", "Key"]}
                rows={[
                  ["Shop", "esafilters", "tapcart"],
                  ["Collection", "esafilters", "settings"],
                  ["Product", "esafilters", "settings"]
                ]}
              />
            </TextContainer>
          </Card.Section>
          <Card.Section title="Install - Screens">
            <TextContainer>
              <p>In order to function correctly in Tapcart, Spot requires you to set up two block-based custom screens, with the "Spot Results" block. Once set up, you should enter the screen ID below.</p>
              <p/>
            </TextContainer>
            <TextField label="Spot Results Screen ID" placeholder="012345789abcedf" onChange={(value) => { setIsChanged(true); setTapcart({ ...tapcart, results_screen_id: value }); }} value={tapcart.results_screen_id != null ? tapcart.results_screen_id : ""}/>
          </Card.Section>
        </>)}
        {tabSelected > 0 && (
          <Card.Section title={tabs[tabSelected - 1].content}>
            <TapcartSection id={tabs[tabSelected - 1].id} layout={tabs[tabSelected - 1].layout} rulesets={rulesets} setSettings={(settings) => { setIsChanged(true); setTapcart({ ...tapcart, [tabs[tabSelected - 1].id]: settings }); }} settings={tapcart[tabs[tabSelected - 1].id] || {}}/>
          </Card.Section>
        )}
      </Tabs>
    </Card>);
  }}
  </Page>);
}

export function Integrations() {
  const { integration } = useParams();
  const redirect = useRedirect();
  const [integrations, setIntegrations] = useState(null);
  const authFetch = useSpotFetch();


  useEffect(() => {
    authFetch('/api/setup/integrations').then((r) => {
      setIntegrations(r.integrations);
    });
  }, []);

  if (integration == "tapcart")
    return (<Tapcart/>);
  return (<Page title="Integrations">
    <Card sectioned title="Integrations">
      <Stack wrap={false}>
        <Button primary={integrations && integrations.filter((i) => i == "tapcart")[0]} onClick={() => { redirect('/setup/integrations/tapcart'); }}>
          <img width={256} src={process.env.PUBLIC_URL + '/tapcart.png'}/>
        </Button>
      </Stack>
    </Card>
  </Page>);
}

