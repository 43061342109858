import React, {useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Select,
  FormLayout,
  Badge,
  TextField,
  Page as OldPage,
  Spinner,
  TextContainer
} from 'admin-frontend';
import moment from 'moment';
import { MultiTableForm, MultiTable, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"

export function Reservations() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [reservations, setReservations] = useState(null);
  const [logs, setLogs] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [editingReservation, setEditingReservation] = useState(null);

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!reservations) {
      setIsLoading(true);
      authFetch("/api/setup/reservations")
        .then((r) => {
          setReservations(r.reservations);
          setUpdatedAt(r.updated_at);
          setIsLoading(false);
        });
    }
  }, [authFetch, reservations]);
  useEffect(() => {
    if (!reservations) {
      setIsLoading(true);
      authFetch("/api/setup/reservations/logs")
        .then((r) => {
          setLogs(r.logs);
          setIsLoading(false);
        });
    }
  }, [authFetch, reservations]);

  const saveReservations = useCallback((reservations) => {
    setIsLoading(true);
    return authFetch("/api/setup/reservations", { json: { reservations: reservations, updated_at: updatedAt } })
      .then((r) => {
        setReservations(r.reservations);
        setIsLoading(false);
        setUpdatedAt(r.updated_at);
      });
  }, [authFetch, updatedAt]);

  const editingForm = editingReservation && (<FormLayout>
    <Select
      key="type"
      label="Type"
      value={editingReservation.type}
      options={[{ label: "Recurring", value: "recurring" }, { label: "Single Occurrence", value: "single" }]}
      onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, type: val, frequency: "monthly", specifiers: [] }) }}
    />
    {editingReservation.type === "single" && (<FormLayout.Group>
      <TextField label='Start' value={editingReservation.start} onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, start: val }) }} type='datetime-local'/>
      <TextField label='End' value={editingReservation.end} onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, end: val }) }} type='datetime-local'/>
    </FormLayout.Group>)}
    {editingReservation.type === "recurring" && [
      (<FormLayout.Group>
        <Select label="Frequency" value={editingReservation.frequency} onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, frequency: val, specifiers: [] }); }} options={[{ label: "Monthly", value: "monthly" }, { label: "Weekly", value: "weekly" }, { label: "Daily", value: "daily" }]}/>
      </FormLayout.Group>),
      editingReservation.frequency === "monthly" && (<FormLayout.Group><TextContainer>Days of the Month<Stack spacing="tight">
        {Array.from({ length: 31 }).map((e, idx) => {
          return <Button pressed={editingReservation.specifiers.includes(idx+1)} onClick={() => {
            setIsChanged(true);
            setEditingReservation({ ...editingReservation,
              specifiers: !editingReservation.specifiers.includes(idx+1) ? [...editingReservation.specifiers, idx+1] : editingReservation.specifiers.filter((s) => s !== idx+1)
            });
          }}>{idx+1}</Button>;
        })}
      </Stack></TextContainer></FormLayout.Group>),
      editingReservation.frequency === "weekly" && (<FormLayout.Group><TextContainer>Days of the Month<Stack spacing="tight">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((e, idx) => {
          return <Button pressed={editingReservation.specifiers.includes(idx+1)} onClick={() => {
            setIsChanged(true);
            setEditingReservation({ ...editingReservation,
              specifiers: !editingReservation.specifiers.includes(idx+1) ? [...editingReservation.specifiers, idx+1] : editingReservation.specifiers.filter((s) => s !== idx+1)
            });
          }}>{e}</Button>;
        })}
      </Stack></TextContainer></FormLayout.Group>),
      (<FormLayout.Group condensed>
        <TextField label='Start Time' value={editingReservation.start} onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, start: val }) }} type='time'/>
        <TextField label='End Time' value={editingReservation.end} onChange={(val) => { setIsChanged(true); setEditingReservation({ ...editingReservation, end: val }) }} type='time'/>
      </FormLayout.Group>)
    ]}
  </FormLayout>);

  return (<Page
    disableThemes
    isFullyLoading={!reservations}
    permission="reservations"
    resourceName={{
      singular: "reservation",
      plural: "reservations"
    }}
    isChanged={isChanged}
    onSave={editingReservation && (() => { saveReservations([...reservations.map((e, idx) => idx === editingReservation.idx ? editingReservation : e), ...(editingReservation.idx === undefined ? [editingReservation] : []) ]).then((r) => { setEditingReservation(null); setIsChanged(false);  }) })}
    onBack={editingReservation && (() => { setEditingReservation(null); setIsChanged(false); })}
    isFullyLoading={isLoading}
  >
    <MultiTableForm
      isEditing={editingReservation}
      hideTable={editingReservation}
      onNew={() => { setEditingReservation({ type: "single" }); setIsChanged(true); }}
      resourceName={{
        singular: "reservation",
        plural: "reservations"
      }}
      editingForm={editingForm}
      bulkActions={[{content: "Delete reservation", onAction: (ids) => {  saveReservations(reservations.filter((r, idx) => { return !ids.includes(idx) })) } }]}
      headings={["Type", "Frequency", "Status", "Next Scheduled", "Next End"]}
      rows={reservations && reservations.map((r) => [
        (r.type === "single" ? "Single Occurrence" : "Recurring"),
        (r.frequency ? r.frequency : "N/A"),
        ((new Date() >= moment(r.next_start).toDate() && new Date() < moment(r.next_end).toDate()) ? <Badge status="success">Active</Badge> : <Badge>Pending</Badge>),
        r.next_start ? moment(r.next_start.replace(/ [-+]\d+$/, "")).format("MMMM DD, YYYY HH:mm:ss") : "N/A",
        r.next_end ? moment(r.next_end.replace(/ [-+]\d+$/, "")).format("MMMM DD, YYYY HH:mm:ss") : "N/A"
      ])}
      onRowClick={(row, idx) => { setEditingReservation({ ...reservations[idx], idx: idx }); } }
    />
    {!editingReservation && <Card
      title="Reservation History"
      sectioned
    >
      <MultiTable
        headings={["Start Time", "End Time"]}
        resourceName={{
          singular: "reservation",
          plural: "reservations"
        }}
        rows={logs && logs.map((l) => [
          moment(l.start.replace(/ [-+]\d+$/, "")).format("MMMM DD, YYYY HH:mm:ss"),
          moment(l.end.replace(/ [-+]\d+$/, "")).format("MMMM DD, YYYY HH:mm:ss")
        ])}
      />
    </Card>}
  </Page>);
}

