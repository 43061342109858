import React, {useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Heading,
  Spinner,
  Select,
  FormLayout,
  TextField,
  DisplayText,
  RangeSlider
} from 'admin-frontend';
import { MultiTable, Auth, useRedirect, Stack, Page as OldPage } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page } from "../components/Page"

function computeChargeBundle(bundle, quantity) {
  const defaultQuantity = bundle.bundle_default != null ? bundle.bundle_default : bundle.bundle_min;
  const actualQuantity = quantity != null ? quantity : (bundle.quantity != null ? bundle.quantity : defaultQuantity);
  return actualQuantity != null && (parseFloat(actualQuantity) - defaultQuantity) / bundle.bundle_increment * bundle.bundle_charge_increment + bundle.bundle_charge_min;
}

function formatMoney(amount, options) {
  let { color, suffix, size, anchor } = (options || {});
  if (amount == null)
    return (<Heading size={size || "large"}> - </Heading>);
  if (!amount)
    amount = 0;
  if (anchor == null)
    anchor = amount;
  return (<Heading size={size || "large"}><span style={{minWidth: "80px", color: color ? color : (amount === anchor ? "black" : (amount < anchor ? "green" : "red")), whiteSpace: "nowrap"}}>$ {(amount || 0) + " " + (suffix || "")}</span></Heading>);
}

function BundleRangeSlider({ value, onChange, ...others }) {
  const [text, setText] = useState(value + "");
  useEffect(() => {
    setText(value + "");
  }, [value])
  return (<Stack alignment="center"><Stack.Item fill><RangeSlider value={value} onChange={onChange} {...others}/></Stack.Item><Stack.Item><TextField onChange={(val) => {
    setText(val);
    if (val != "") {
      if (others.min != null || others.max != null)
        onChange(Math.min(Math.max(parseFloat(val), others.min), others.max) + "");
      else
        onChange(val);
    }
  }} value={text}/></Stack.Item></Stack>)
}

export function Bundles() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [originalBundles, setOriginalBundles] = useState(null);
  const [bundles, setBundles] = useState(null);
  const [lastBill, setLastBill] = useState(null);
  const [addableBundle, setAddableBundle] = useState(null);
  const [usageBasedChargeCeiling, setUsageBasedChargeCeiling] = useState(null);
  const [originalUsageBasedChargeCeiling, setOriginalUsageBasedChargeCeiling] = useState(null);
  const [balance, setBalance] = useState(null);

  const authFetch = useSpotFetch();
  const [profile] = Auth.useProfile();
  const redirect = useRedirect();

  useEffect(() => {
    if (!bundles) {
      authFetch("/api/subscriptions/bundles").then((r) => {
        if (r.confirmation_url) {
          redirect(r.confirmation_url);
        } else {
          setOriginalBundles(r.bundles);
          setBundles(r.bundles);
          setBalance(r.balance);
          setUsageBasedChargeCeiling(parseFloat(r.usage_based_charge_ceiling));
          setIsLoading(false);
        }
      });
    }
  }, [authFetch, redirect]);

  const computeTotalCost = useCallback((bundles) => {
    return bundles && profile && profile?.shop?.subscriptions && Math.max(bundles.map((e) => computeChargeBundle(e) || 0).reduce((sum, e) => sum + e, 0), 0)
  }, [profile]);
  const getOriginalBundle = useCallback((bundle) => {
    return originalBundles.filter((e) => e.bundle_id === bundle.bundle_id)[0];
  }, [originalBundles]);

  const isSupport = profile && profile.user && profile.user.support;
  const addableBundles = bundles && bundles.filter((e) => !e.active && e.optional);
  const currentDisplayedBundles = bundles && bundles.filter((e) => e.active || !e.optional);
  const currentCost = computeTotalCost(originalBundles);
  const proposedCost = computeTotalCost(bundles);
  const needsSettlement = balance > 0;

  if (!profile?.shop?.subscriptions)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);

  return (<Page
    title="Bundles"
    permission="account"
    subtitle="Use this page to manage your add-ons and bundles associated with your subscription."
    resourceName={{
      singular: "bundle",
      plural: "bundles"
    }}
    fullWidth
    primaryAction={<Button
      primary={!needsSettlement}
      destructive={needsSettlement}
      disabled={!isChanged && !needsSettlement}
      onClick={() => {
        if (!needsSettlement) {
          setIsLoading(true);
          authFetch("/api/subscriptions/bundles", { json: { usage_based_charge_ceiling: parseFloat(usageBasedChargeCeiling), bundles: bundles.filter((e) => e.active) } }).then((r) => {
            if (r.confirmation_url)
              redirect(r.confirmation_url);
            setBundles(r.bundles);
            setIsLoading(false);
          });
        } else {
          setIsLoading(true);
          authFetch("/api/subscriptions/settlement").then((r) => {
            if (r.confirmation_url)
              redirect(r.confirmation_url);
          });
        }
      }}
      >{needsSettlement ? "Settle Balance" : (originalUsageBasedChargeCeiling !== usageBasedChargeCeiling ? "Confirm Charge Changes & Save" : "Save")}</Button>
    }
    isLoading={isLoading}
    isFullyLoading={isLoading && !bundles}
  >{bundles && profile.shop && (<>
    <Card title="Subscriptions" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <div>
            <p>{profile?.shop?.subscriptions[0].plan_name}</p>
            {formatMoney(profile?.shop?.subscriptions[0].amount)}
          </div>
          <div>
            <p>Usage Based Charge Ceiling</p>
            <div style={{maxWidth: "200px", marginTop: "8px"}}>
              <BundleRangeSlider
                min={50}
                disabled={needsSettlement || profile?.shop?.subscriptions[0].frequency == "annual"}
                max={profile?.shop?.subscriptions[0].amount*10}
                value={usageBasedChargeCeiling || 50}
                onChange={(val) => { setUsageBasedChargeCeiling(val); setIsChanged(true); }}
                suffix={formatMoney(usageBasedChargeCeiling || 0, { anchor: originalUsageBasedChargeCeiling, size: "small" })}
              />
            </div>
          </div>
          <div>
            <p>Outstanding Balance</p>
            <span style={{display: "inline-block"}}>
              {formatMoney(balance || 0, { anchor: 0 })}
            </span>
          </div>
        </FormLayout.Group>
      </FormLayout>
    </Card>
    <Card title="Bundles">
      <Card.Section title="Monthly Charges">
        <FormLayout>
          <FormLayout.Group>
            <div>
              Last Bill {formatMoney(lastBill)}
            </div>
            <div>
              Current Bundles {formatMoney(currentCost)}
            </div>
            <div>
              Proposed Bundles {formatMoney(proposedCost, { anchor:  currentCost })}
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
      <Card.Section title="Bundles">
        <MultiTable
          fixedWidths
          resourceName={{
            singular: "bundle",
            plural: "bundles"
          }}
          headings={["Bundle", "Details", "Cost", "Quantity", "Line Cost", "Overage Cost", ""]}
          rows={[
          ...((currentDisplayedBundles || []).map((b, idx1) => [
            (!isSupport || !b.support_only ? b.line_description : <TextField placeholder={b.original_line_description} value={b.line_description} onChange={(val) => { setBundles([...bundles.map((e, idx2) => (e === b ? { ...e, line_description: val } : e))]); }}/>),
            (!isSupport || !b.support_only ? b.description : <TextField multiline={4} placeholder={b.description} value={b.description} onChange={(val) => { setBundles([...bundles.map((e, idx2) => (e === b ? { ...e, description: val } : e))]); }}/>),
            formatMoney(b.bundle_charge_increment ? b.bundle_charge_increment : null, { suffix: "/ " + b.bundle_increment, size: "small" }),
            (b.bundle_min === b.bundle_max ? (<p>{b.quantity || b.bundle_default || b.bundle_min}</p>) : (<BundleRangeSlider
              min={b.bundle_min}
              max={b.bundle_max}
              step={b.bundle_increment}
              label={b.name}
              disabled={needsSettlement}
              value={(b.quantity != null ? b.quantity : (b.bundle_default != null ? b.bundle_default : b.bundle_min)) + ""}
              onChange={(val) => { setBundles([...bundles.map((e, idx2) => e === b ? { ...e, quantity: (val != "" ? parseFloat(val) : null) } : e)]) }}
            />)),
            formatMoney(computeChargeBundle(b), { anchor: computeChargeBundle(getOriginalBundle(b)) }),
            formatMoney(b.bundle_charge_overage_increment ? b.bundle_charge_overage_increment : null, { suffix: "/ " + b.bundle_increment, size: "small" }),
            b.optional ? (<Button onClick={() => {
              setBundles([...bundles.map((e) => e === b ? { ...e, active: false, quantity: null } : e)]);
            }}>Remove</Button>) : (<div></div>)
          ])),
          ...(addableBundles.length > 0 ? [[
            <Select
              disabled={needsSettlement}
              options={[{ label: "Choose a Bundle", value: "" }, ...addableBundles.map((e) => { return { label: e.line_description, value: (e.id + "") } })]}
              onChange={(val) => { setAddableBundle(addableBundles.filter((e) => (e.id + "") === val)[0]) }}
              value={addableBundle ? (addableBundle.id + "") : ""}
            />,
            addableBundle && addableBundle.description,
            "",
            "",
            "",
            <Button disabled={!addableBundle} onClick={() => {
              setBundles([...bundles.filter((e) => e !== addableBundle), { ...addableBundle, active: true }]);
            }} primary>Add</Button>,
          ]] : [])
          ]}
        />
      </Card.Section>
    </Card></>)}
  </Page>);
}

