import { useState, useEffect, useRef, createContext, useContext, useMemo } from 'react';
import { Auth, Banner, Tooltip } from "admin-frontend";
import { titleCase } from "./Fields";
import { useSpotFetch } from "../useSpotFetch";

function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function computeColor(string) {
  const code = hashCode(string);
  const r = (((code >> 24) & 0xFF) / 255) * 60 + 180;
  const g = (((code >> 16) & 0xFF) / 255) * 60 + 180;
  const b = (((code >> 8) & 0xFF) / 255) * 60 + 180;
  return `rgb(${r}, ${g}, ${b})`;
}

function computeBackgroundColor(user) {
  if (!user)
    return "#aaaaaa";
  return computeColor(user.first_name + " " + user.last_name);
}

export function ActivityBadge({ activity }) {
  const [profile] = Auth.useProfile();
  const user = profile.shop.users.filter((user) => user.id == activity.user_id)[0];
  return (<Tooltip dismissOnMouseOut content={`${user.first_name} ${user.last_name} <${user.email}> - ${titleCase(activity.activity)}`}>
    <div className={`activity-badge ${activity.activity}`}  style={{ backgroundColor: computeBackgroundColor(user) }}>
      {user ? user.first_name.charAt(0).toUpperCase() : "?"}
    </div>
  </Tooltip>);
}

const ACTIVITY_REFRESH_INTERVAL = 30*1000;

export const ActivityContext = createContext({ });

export function ActivityProvider({ objectType, children, activity, setActivity, refresh = ACTIVITY_REFRESH_INTERVAL, update = false }) {
  const ids = useRef([]);
  const interval = useRef(null);
  const authFetch = useSpotFetch();
  const contextValue = useMemo(() => {
    return { addId: (id) => {
      if (ids.current.filter((i) => i == id).length == 0)
        ids.current.push(id);
    }, removeId: (id) => {
      ids.current = ids.current.filter((i) => i != id);
    } };
  }, [])
  useEffect(() => {
    if (!activity) {
      setTimeout(() => {
        authFetch("/api/activity", { [update ? "json" : "query"]: { type: objectType, id: ids.current, ...(update ? { activity: update } : {}) } }).then((r) => {
          setActivity(r.activity);
        });
      }, 100);
    }
  }, []);
  useEffect(() => {
    if (interval.current)
        clearInterval(interval.current);
    interval.current = setInterval(() => {
      authFetch("/api/activity", { [update ? "json" : "query"]: { type: objectType, id: ids.current, ...(update ? { activity: update } : {}) } }).then((r) => {
        setActivity(r.activity);
      });
    }, refresh);
    return () => {
      if (interval.current)
        clearInterval(interval.current);
    };
  }, [authFetch, objectType, refresh]);
  return <ActivityContext.Provider value={contextValue}>{children}</ActivityContext.Provider>;
}

export function ActivityBanner({ activity, objectId }) {
  const context = useContext(ActivityContext);
  const [profile] = Auth.useProfile();
  useEffect(() => {
    context.addId(objectId);
    return () => { context.removeId(objectId); }
  }, [context]);
  if (!activity || activity.length == 0)
    return null;
  const users = Object.fromEntries(profile.shop.users.filter((user) => user.origin != "api").map((u) => [u.id, u]));
  return <div className='activity-banner'>
    {activity.filter((a) => a.user_id && users[a.user_id]).slice(0, 5).map((a, idx) => <ActivityBadge key={`activity-{idx}`} activity={a}/>)}
  </div>;
}
