import React, {useState, useEffect, useMemo, useCallback } from 'react';
import {
  Layout,
  Card,
  Spinner,
  Button,
  Select,
  Filters,
  Page as OldPage,
  Link,
  ChoiceList,
  Popover,
  Heading,
  ActionList,
  TextField,
  Modal,
  TextContainer,
  Icon,
  Form,
  Tooltip,
  Pagination,
  Frame,
  ContextualSaveBar
} from 'admin-frontend';
import { SettingsMinor, NavigationMajor, AddMajor, DeleteMajor, FlagMajor } from 'admin-frontend';
import { useLocation } from "react-router-dom";
import { MultiTable, Auth, useRedirect, useCreateToast, useCreateModal, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { Page, ExternalLink } from "../components/Page"
import { ProductSearch, ProductSearchResult } from "../components/Product"
import { rearrange } from "../components/Grid"
import { RulesetEditor, translateRules } from "./GlobalProperties/Ruleset"
import { useSpotAPI, useSpot } from "../components/API"
import { ActivityProvider, ActivityBanner } from "../components/Activity"
import moment from 'moment';

function getCollectionsFromLinklists(list, parent, root, depth = 0) {
  return [
    { root: root || list, parent: parent, handle: list.handle, title: list.title, ...(list['object_id'] && list['type'] === 'collection_link' ? { id: list.object_id } : {}), depth: depth },
    ...list.links.flatMap((l) => getCollectionsFromLinklists(l, list, root || list, depth + 1))
  ];
}

function arrayifyParams(param) {
  return typeof(param) == "string" ? (param != "" ? [param] : "") : param;
}
function convertToSearchParams(hash) {
  let searchParams = new URLSearchParams();
  for (let i in hash) {
    if (Array.isArray(hash[i])) {
      for (let j = 0; j < hash[i].length; ++j)
        searchParams.append(i, hash[i][j]);
    } else
      searchParams.append(i, hash[i]);
  }
  return searchParams;
}

function convertFromSearchParams(params) {
  const hash = {};
  for (let pair of params.entries()) {
    if (hash[pair[0]] !== undefined)
      hash[pair[0]].push(pair[1]);
    else
      hash[pair[0]] = [pair[1]];
  }
  return hash;
}

export function CollectionRulesets() {
  const searchQuery = useLocation().search;
  const params = useMemo(() => new URLSearchParams(searchQuery), [searchQuery]);
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState("");
  const [collections, setCollections] = useState(null);
  const [modifiedRulesets, setModifiedRulesets] = useState({});
  const [rulesets, setRulesets] = useState(null);
  const [linklists, setLinklists] = useState(null);

  const [modalOpen, setModalOpen] = useState(null);
  const [selectedRuleset, setSelectedRuleset] = useState("0");
  const [theme, _setTheme] = useState(null);
  const [sort, setSort] = useState(null);
  const [selectedItems, _setSelectedItems] = useState([]);
  const [activity, setActivity] = useState({});
  const createToast = useCreateToast();

  const [id] = [params.get("id")];

  function setTheme(theme) {
    setModifiedRulesets({});
    _setTheme(theme);
  }
  const querySearch = useLocation().search;
  const filters = useMemo(() => convertFromSearchParams(new URLSearchParams(querySearch)), [querySearch]);
  const displayMode = (filters.display && filters.display[0]) || "flat";

  function setFilters(hash) {
    const searchParams = convertToSearchParams(hash).toString();
    if (window.location.search != searchParams)
      redirect("/merchandising/collections?" + searchParams);
  }

  function setSelectedItems(items) {
    if (displayMode == "hierarchy") {
      if (items.length < selectedItems.length) {
        const newHash = Object.fromEntries(items.map((i) => [i, true]));
        const removedIdx = selectedItems.filter((id) => !newHash[id])[0];
        let endRemoveIdx;
        for (endRemoveIdx = removedIdx + 1; endRemoveIdx < collections.length && collections[endRemoveIdx].depth > collections[removedIdx].depth; ++endRemoveIdx);
        items = items.filter((id) => id < removedIdx || id >= endRemoveIdx);
      } else {
        const oldHash = Object.fromEntries(selectedItems.map((i) => [i, true]));
        const addedIdx = items.filter((id) => !oldHash[id])[0];
        for (let i = addedIdx + 1; i < collections.length && collections[i].depth > collections[addedIdx].depth; ++i) {
          if (!oldHash[i])
            items.push(i);
        }
      }
    }
    _setSelectedItems(items);
  }

  const collectionsPerPage = 50;
  const linklistHash = linklists && Object.fromEntries(linklists.map((l) => [l.handle, l]));
  const linkCollections = linklists && linklists.flatMap((l) => getCollectionsFromLinklists(l));
  const linkCollectionHash = linkCollections && Object.fromEntries(linkCollections.filter((c) => c.id).map((c) => [c.id, c]));

  const authFetch = useSpotFetch();
  useEffect(() => {
    if (!id) {
      if (displayMode === "hierarchy" && !linkCollections || linklists === null)
        return;
      setIsLoading(true);
      const navigationHash = filters.navigation && filters.navigation.length > 0 && Object.fromEntries(filters.navigation.map((handle) => [handle, 1]));
      const ids = (navigationHash || displayMode == "hierarchy") && linkCollections ? linkCollections.filter((link) => link.id && (
        (displayMode == "hierarchy" && (!filters.navigation || filters.navigation.length == 0)) || navigationHash[link.root.handle]
      )).map((link) => link.id) : null;
      if (ids && ids.length == 0) {
        setCollections([]);
        setCount(null);
        return;
      }
      authFetch('/api/collections', { query: { q: search, page: page, ...(ids ? { ids: ids } : {}),  ...(filters.ruleset_id ? { ruleset_id: filters.ruleset_id } : {})  } }).then((r) => {
        if (displayMode === "hierarchy") {
          const collectionHash = Object.fromEntries(r.collections.map((c) => [c.id, c]));
          setCollections(linkCollections.filter((l) => !navigationHash || navigationHash[l.root.handle]).map((c) => { return { ...c, ...(collectionHash[c.id] || {}) } }));
          setCount(null);
        } else {
          setCollections(r.collections);
          setCount(r.count);
        }
        setActivity(r.activity);
      });
    }
  }, [id, authFetch, search, page, filters, linklists]);
  useEffect(() => {
    if (!id) {
      authFetch('/api/global/rulesets').then((r) => {
        setRulesets(r.rulesets);
      });
    }
  }, [authFetch, id]);
  useEffect(() => {
    if (!id)
      setIsLoading(!collections || !rulesets);
  }, [collections, rulesets, id])
  useEffect(() => {
    if (!id) {
      authFetch('/api/collections/linklists').then((r) => {
        setLinklists(r.linklists || false);
      })
    }
  }, [id, authFetch]);

  const collectionDetails = useMemo(() => {
    return id && <CollectionDetails/>
  }, [id]);
  
  if (id)
    return collectionDetails; 

  const defaultImage = <svg className="placeholder--image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5"><path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path><path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path></svg>;

  const rulesetHash = rulesets ? Object.fromEntries(rulesets.map((r) => [r.id, r])) : {};


  return (<Page
    permission="rules"
    overrideThemes
    getResourceCount={() => { return 0; }}
    setTheme={setTheme}
    isChanged={isChanged}
    isLoading={isLoading}
    disableThemes
    onSave={(() => {
      setIsLoading(true);
      authFetch("/api/collections", { json: { collections: Object.keys(modifiedRulesets).map((k) => { return { ...modifiedRulesets[k] } }) } }).then((r) => {
        setIsLoading(false);
        setIsChanged(false);
        const collectionHash = Object.fromEntries(r.collections.map((c) => [c.id, c]));
        setCollections(collections.map((c) => (collectionHash[c.id] ? { ...c, ...collectionHash[c.id] } : c)));
        setModifiedRulesets({});
      });
    })}
  >
    <Card
      title="Collection Listing"
      sectioned
      actions={linklists && [
        {
          content: "Switch to " + (displayMode === "flat" ? "Hierarchical" : "Flat") + " View",
          onAction: () => {
            setCollections(null);
            setFilters(displayMode === "flat" ? { display: ["hierarchy"], navigation: ["main-menu"] } : { display: ["flat"] });
          }
        }
      ]}
    >
      {displayMode === "flat" && (<>
        <Filters
          queryValue={search}
          filters={[{
            key: 'displayRule',
            label: 'Display Rule',
            filter: (
              <ChoiceList
                label="Display Rule"
                allowMultiple
                choices={[...(rulesets || []).map((r) => { return { label: r.handle, value: r.id + "" }; })]}
                selected={arrayifyParams(filters.ruleset_id) || []}
                onChange={(val) => { setFilters({ ...filters, ruleset_id: val }); }}
                autoComplete="off"
                labelHidden
              />
            ),
            shortcut: true,
          }, ...(linklists ? [{
            key: 'inNav',
            label: 'Navigation',
            filter: (
              <ChoiceList
                label="Navigation"
                allowMultiple
                choices={linklists.map((l) => { return { label: l.title, value: l.handle }; })}
                selected={arrayifyParams(filters.navigation) || []}
                onChange={(val) => {
                  setFilters({ ...filters, navigation: val });
                }}
                autoComplete="off"
                labelHidden
              />
            ),
            shortcut: true,
          }] : []) ]}
          appliedFilters={[
            ...((filters.ruleset_id ||[]).map((ruleset_id) => { return {
              key: 'displayRule-' + ruleset_id,
              label: rulesetHash[ruleset_id] && rulesetHash[ruleset_id].handle,
              onRemove: () => { setFilters({ ...filters, ruleset_id: filters.ruleset_id.filter((rid) => rid != ruleset_id) }); }
            } })),
            ...((filters.navigation ||[]).map((handle) => { return {
              key: 'navigation-' + handle,
              label: linklistHash && linklistHash[handle] && linklistHash[handle].title,
              onRemove: () => { setFilters({ ...filters, navigation: filters.navigation.filter((lhandle) => lhandle != handle) }); }
            } }))
          ]}
          onQueryChange={(value) => {
            setSearch(value);
            setPage(1);
          }}
          onQueryClear={() => { setSearch(''); }}
          onClearAll={() => {}}
        />
        <br/>
      </>
      )}
      {linklists && displayMode === "hierarchy" && (<>
        <Select onChange={(val) => { setFilters({ ...filters, navigation: val != "" ? [val] : [] }); }} options={[...linklists.map((l) => { return { label: l.title, value: l.handle } }), { label: "All Menus", value: "" }]} value={(filters.navigation && filters.navigation[0]) || ""} />
      </>)}
      <Modal
        open={!!modalOpen}
        onClose={() => { setModalOpen(null); }}
        title="Setting Display Rules for Collections"
        primaryAction={{
          content: 'Set Display Rules',
          onAction: () => {
            setModifiedRulesets({
              ...modifiedRulesets,
              ...Object.fromEntries(modalOpen.map((index) => [collections[index].id, { id: collections[index].id, type: collections[index].type, ruleset_id: selectedRuleset, updated_at: (modifiedRulesets[collections[index].id] && modifiedRulesets[collections[index].id].updated_at) || collections[index].updated_at || new Date().toISOString() }]))
            });
            setIsChanged(true);
            setModalOpen(null);
          }
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setSelectedItems(modalOpen);
              setModalOpen(null);
            },
          },
        ]}
      >
        <Modal.Section>
          <Select value={selectedRuleset} onChange={setSelectedRuleset} options={[{ label: "Situation Default", value: "0" }, ...(rulesets || []).map((r) => { return { label: r.handle, value: r.id + "" }; })]}/>
        </Modal.Section>
      </Modal>
      {(<div className={displayMode}><ActivityProvider activity={activity} objectType={"collection"} setActivity={setActivity}><MultiTable
        loading={isLoading}
        bulkActions={[{
          content: "Set Display Rules",
          onAction: (ids) => {
            setModalOpen(ids);
            setIsChanged(true);
          }
        }]}
        selectedItems={[...selectedItems, ...(modalOpen || [])]}
        setSelectedItems={setSelectedItems}
        headings={["", "Title", "Display Rule", ""]}
        rows={rulesets && collections && collections.map((c) => {
          const ruleset_id = modifiedRulesets && modifiedRulesets[c.id] ? (modifiedRulesets[c.id].ruleset_id) : (c.ruleset_id ? c.ruleset_id : null);
          return { className: "depth" + (c.depth || 0), cells: (c.id ? [
          displayMode == "flat" && (c.image ? <img alt={c.title} style={{ maxHeight: "64px", maxWidth: "64px" }} src={c.image.src}/> : defaultImage),
          <Stack>
            <Stack.Item fill>
              <Link url={`shopify://admin/collections/${c.id}`}>{c.title}</Link>
              <div className='collection-handle'>{c.handle}</div>
            </Stack.Item>
            {displayMode == "flat" && linkCollectionHash[c.id] && (<Stack.Item style={{ alignSelf: "center" }}><Tooltip dismissOnMouseOut content={`This collection is included in your site's navigation under '${linkCollectionHash[c.id].root.handle}'.`}>
              <Link onClick={() => { setFilters({ display: ["hierarchy"], navigation: [linkCollectionHash[c.id].root.handle] }); }}>
                <Icon color="base" source={NavigationMajor}/>
              </Link>
            </Tooltip></Stack.Item>)}
            <ActivityBanner activity={activity[c.id]} objectId={c.id}/>
          </Stack>,
          <Select
            onChange={(value) => {
              setModifiedRulesets({ ...modifiedRulesets, [c.id]: { id: c.id, ruleset_id: value, type: c.type, updated_at: (modifiedRulesets[c.id] && modifiedRulesets[c.id].updated_at) || c.updated_at || new Date().toISOString() } });
              setIsChanged(true);
            }}
            value={(ruleset_id || 0) + ""}
            loading={!collections}
            options={[
              {label: "Situation Default", value: "0"},
              ...(rulesets ? rulesets.map((r) => { return { label: r.handle, value: r.id + "" }; }) : []),
              ...((ruleset_id != null && !rulesetHash[ruleset_id]) ? [{ label: "Custom Display Rule", value: (ruleset_id + "") }] : [])
            ]}
          />,
          <Button onClick={() => redirect('/merchandising/collections?id=' + c.id + "&from=" + encodeURIComponent(search) + "&type=" + encodeURIComponent(c.type)) }>
            <Icon source={SettingsMinor}/>
          </Button>
          ] : [
            "",
            c.title,
            "",
            "",
            "",
            ""
          ])
        } })}
        resourceName={{singular: "collection", plural: "collections"}}
      /></ActivityProvider></div>)}
      {(displayMode === "flat" && count && count > collectionsPerPage && (<Stack spacing="tight" distribution="center">
        <Pagination
          hasPrevious={page > 1}
          onPrevious={() => {
            setPage(parseInt(page)-1);
          }}
          hasNext={page < Math.ceil(count / collectionsPerPage)}
          onNext={() => {
            setPage(parseInt(page)+1);
          }}
        />
      </Stack>)) || ""}
    </Card>
  </Page>);
}

export function CollectionContext({ collection, settings, loadingLevel, setLoadingLevel, isChanged, setIsChanged, ruleset, setRuleset, error, setError, parent, setHasSales, hasSales }) {
  const [merchandising, setMerchandising] = useState(null);
  const [selection, setSelection] = useState([]);
  const authFetch = useSpotFetch();
  const [profile] = Auth.useProfile();
  const [alreadyWarnedPinned, setAlreadyWarnedPinned] = useState(false);

  const [previewProducts, setPreviewProducts] = useState(null);
  const [actionPopoverVisible, setActionPopoverVisible] = useState(false);
  const [columnChoices, setColumnChoices] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [view, setView] = useState(null);
  
  // Used to detemrine whether we've had a "real" need for a rerender.
  // If just pins change, we shouldn't really care.
  const [lastRuleset, setLastRuleset] = useState(null);
  const [deferredDrag, setDeferredDrag] = useState(null);
  const [pinPopoverActive, setPinPopoverActive] = useState(false);
  const [goToPin, setGoToPin] = useState("1");

  const spot = useSpot();
  const createToast = useCreateToast();

  const MAX_ALLOWED_PINS = 400;


  const isSplit = spot.spotDOM.split() && spot.spotDOM.split() != "none" && spot.spotDOM.split() != "auto";
  const isMerge = spot.spotDOM.merge() && spot.spotDOM.merge() != "none" && spot.spotDOM.merge() != "auto";
  const hasSalesColumn = selectedColumns ? selectedColumns.filter((e) => e == "sales")[0] != null : null;

  function JSONstringifyOrder(obj, space) {
      const allKeys = new Set();
      JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
      return JSON.stringify(obj, Array.from(allKeys).sort(), space);
  }
  
  useEffect(() => {
    if (spot && settings) {
      if (!spot.spotDefault.setupComplete) {
        spot.spotDefault.setupObjects(settings);
        spot.spotDefault.setupActiveRules();
        spot.spotDefault.setupQueryOnChange();
        spot.spotDefault.setupComplete = true;
      }
    }
  }, [spot, settings]);
  useEffect(() => {
    if (spot)
      spot.spotDOM.collection(collection ? parseInt(collection.id) : null, false);
  }, [collection, spot]);

  useEffect(() => {
    if (collection) {
      const rulesetString = ruleset ? JSONstringifyOrder({ ...ruleset, pins: null, rules: null, preferred_images: null, updated_at: null }) : '';
      if (!lastRuleset || lastRuleset != rulesetString) {
        const rule = ruleset && typeof(ruleset) == 'object'? { ...ruleset, conditions: translateRules(ruleset.rules) } : (Object.values(settings.rules).filter((r) => r.id == ruleset).map((r) => r.handle)[0]);
        spot.spotDefault.activeRules(spot.spotDefault.getRules({ rule: rule }));
      }
      setLastRuleset(rulesetString);
    }
  }, [ruleset, collection, spot, isSplit, isMerge, hasSales]);
  
  const queryTransform = useCallback((q, spotDOM) => {
    if (hasSalesColumn)
      q = q.query_param('sales', 1);
    return q;
  }, [hasSalesColumn]);
  
  useEffect(() => {
    setHasSales(hasSalesColumn);
  }, [hasSalesColumn]);



  const isEditor = ruleset && typeof(ruleset) === "object" && !ruleset.global;
  const productDeleteRule = isEditor && ruleset.rules.filter((r) => r.column[0] == "split_id" && r.relation == "not equal to")[0];
  const pinnedProducts = isEditor && ruleset.pins && Object.fromEntries(ruleset.pins.map((id) => [id, true]));
  const antipinnedProducts = isEditor && ruleset.antipins && Object.fromEntries(ruleset.antipins.map((id) => [id, true]));

  const selectionHash = useMemo(() => Object.fromEntries(selection.map((id) => [id, true])), [selection]);
  const activator = (<Button primary onClick={() => { setActionPopoverVisible(!actionPopoverVisible) }} fullWidth disclosure="select">{selection.length} Selected</Button>);

  const deleteProductIds = ((product_ids) => {
    let rule = productDeleteRule;
    if (!rule) {
      ruleset.rules.push({ column: ["split_id"], relation: "not equal to", condition: [] });
      rule = ruleset.rules[ruleset.rules.length-1];
    }
    product_ids.forEach((id) => { rule.condition.push(id); });
    const productHash = Object.fromEntries(product_ids.map((id) => [id, true]))
    ruleset.pins = ruleset.pins.filter((id) => !productHash[id]);
    const newAntipins = ruleset.antipins.filter((id) => !productHash[id]);
    if (newAntipins.length != ruleset.antipins.length)
      ruleset.antipins = newAntipins;
    setPreviewProducts(previewProducts.filter((p) => !productHash[p.split_id]))
    setIsChanged(true);
    setRuleset({ ...ruleset });
  });

  useEffect(() => {
    if (!columnChoices) {
      authFetch(`/api/global/merchandising`).then((r) => {
        setColumnChoices(r.columns.map((c) => { return { ...c, renderText: c.render, render: eval(c.render) } }));
        setSelectedColumns(r.selected);
      });
    }
  }, [columnChoices]);

  const dragDrop = useMemo(() => {
    return isEditor && function(source, destination, length) {
      if (source != destination) {
        // We check to see if source is already a pin, because if so, we may not have loaded all `previewProducts`. If it's already a pin, rearrange the pins array itself.
        let newPins;
        const rearrangedProducts = rearrange(previewProducts, source, destination, length);
        const isPin = source < ruleset.pins.length;
        const maxPin = Math.max(source, destination) + length;
        // We're just rarranging existing pins.
        if (maxPin <= ruleset.pins.length) {
          const hasProducts = Object.fromEntries(rearrangedProducts.slice(0, maxPin).map((p) => [p.split_id, p]));
          newPins = rearrangedProducts.slice(0, maxPin).map((p) => p.split_id).concat(ruleset.pins.slice(maxPin).filter((p) => !hasProducts[p]));
        } else {
          const fixedUntil = Math.max(destination + length, ruleset.pins.length + (isPin ? 0 : length));
          newPins = rearrangedProducts.slice(0, fixedUntil).map((p) => p.split_id);
        }
        const newAntipins = ruleset.antipins.filter((id) => !newPins.includes(id));
        if (newAntipins.length != ruleset.antipins.length)
          ruleset.antipins = newAntipins;
        if (newPins.length < MAX_ALLOWED_PINS) {
          setPreviewProducts(rearrangedProducts);
          setRuleset({ ...ruleset, pins: newPins });
          setIsChanged(true);
        } else {
          createToast({ content: `You cannot have more than ${MAX_ALLOWED_PINS} pins at present. If you require more than this, please contact support to discuss your use-case.` });
          setRuleset({ ...ruleset, refresh: new Date() });
        }
      }
    }
  }, [isEditor, previewProducts, ruleset, selectionHash]);

  const selectedCallback = useCallback((idx) => {
    if (!selectionHash[previewProducts[idx].split_id])
      return [idx];
    let startIdx, endIdx;
    for (startIdx = idx; startIdx > 0 && selectionHash[previewProducts[startIdx-1].split_id]; startIdx--);
    for (endIdx = idx; endIdx < previewProducts.length - 1 && selectionHash[previewProducts[endIdx+1].split_id]; endIdx++);
    const indices = [];
    for (let i = startIdx; i <= endIdx; ++i)
      indices.push(i);
    return indices;
  }, [selectionHash, previewProducts]);

  useEffect(() => {
    if (deferredDrag && loadingLevel == 0 && dragDrop && previewProducts.length >= deferredDrag[2]) {
      setDeferredDrag(null);
      dragDrop(deferredDrag[0], Math.min(previewProducts.length+1, deferredDrag[1]), 1);
    }
  }, [dragDrop, deferredDrag, previewProducts, loadingLevel]);

  const selectPin = (targetIdx) => {
    const element = document.querySelector(`.search-preview-product-${targetIdx} input`);
    if (element) {
      element.focus();
      element.setSelectionRange(0, element.value.length);
    }
  };

  if (!lastRuleset && ruleset)
    return (<Stack alignment="center" distribution="center"><Spinner size="large"/></Stack>);

  return (
    <Layout>
      {isEditor && (<RulesetEditor
        error={error}
        setError={setError}
        parent={parent}
        sortOptions={settings.sort_orders}
        splits={settings.splits}
        isLoading={loadingLevel > 0}
        setIsLoading={(val) => {
          setLoadingLevel(loadingLevel + (val ? 1 : -1))
        }}
        merges={settings.merges}
        facetGroups={settings.facet_groups}
        badgeGroups={settings.badge_groups}
        collection={collection}
        banners={settings.banners}
        ruleset={ruleset}
        swatches={settings.swatches}
        recommendations={settings.recommendations}
        boostRules={settings.boost_rules}
        setRuleset={(r) => {
          setRuleset({ ...r, refresh: new Date() });
          setIsChanged(true);
        }}/>)}
      {!isEditor && <Layout.Section>
        <Card
          title="Display Rule"
          sectioned
          actions={[{
            onAction: () => {
              const extantRuleset = ruleset && typeof(ruleset) == "number" && Object.values(settings.rules).filter((r) => r.id == ruleset)[0];
              if (!profile.shop.sdk_version || profile.shop.sdk_version < "SDK20231122") {
                setRuleset({ rules: [], pins: [], recommendation_ids: [], antipins: [], preferred_images: {}, ...(extantRuleset ? extantRuleset : {}), global: false,  id: null, handle: null })
              } else {
                setRuleset({ pins: [], antipins: [], recommendation_ids: [], preferred_images: {}, ...(extantRuleset ? extantRuleset : {}), rules: [{ condition: null, operator: null, column: ["default"] }], global: false, id: null })
              }
              setIsChanged(true);
            },
            content: "Enable Custom Display Rule & Merchandising"
          }]}
        >
          <Select
            onChange={(r) => {
              setRuleset(r !== "0" ? parseInt(r) : null);
              setIsChanged(true);
            }}
            value={(ruleset + "") || "0"}
            options={[
              { label: "Store Default Display Rule", value: "0" },
              ...Object.keys(settings.rules).map((h) => { return { label: h, value: settings.rules[h].id + "" } })
            ]}
          />
        </Card>
      </Layout.Section>
      }
      <Layout.Section>
        {(hasSalesColumn != null && hasSalesColumn == hasSales) && <ProductSearch
          className='collection-details'
          collection={collection}
          spotState={false}
          disabled={loadingLevel > 0}
          bannerHash={settings.banners && Object.fromEntries(settings.banners.map((b) => [b.id, b]))}
          sidebarExtra={selection && selection.length > 0 && (<div style={{ marginBottom: "12px" }}>
            <Popover
              active={actionPopoverVisible}
              activator={activator}
              onClose={() => { setActionPopoverVisible(false); }}
            >
              <ActionList
                actionRole="menuitem"
                items={[{content: 'Pin to Top', onAction: () => {
                  ruleset.pins = selection.concat(ruleset.pins.filter((id) => !selection.includes(id)));
                  ruleset.antipins = ruleset.antipins.filter((id) => !ruleset.pins.includes(id));
                  if (ruleset.pins.length < MAX_ALLOWED_PINS) {
                    const productHash = Object.fromEntries(previewProducts.map((product) => [product.split_id, product]));
                    const pinnedProducts = ruleset.pins.map((id) => productHash[id]).filter((p) => p);
                    const notPinnedProducts = previewProducts.filter((product) => !ruleset.pins.includes(product.split_id));
                    setRuleset({ ...ruleset });
                    setIsChanged(true);
                    setPreviewProducts([...pinnedProducts, ...notPinnedProducts]);
                    setSelection([]);
                    setActionPopoverVisible(false);
                  } else {
                    createToast({ content: `You cannot have more than ${MAX_ALLOWED_PINS} pins at present. If you require more than this, please contact support to discuss your use-case.` });
                  }
                }}, {content: 'Pin', onAction: () => {
                  ruleset.pins = ruleset.pins.concat(selection.filter((id) => !ruleset.pins.includes(id)));
                  ruleset.antipins = ruleset.antipins.filter((id) => !ruleset.pins.includes(id));
                  if (ruleset.pins.length < MAX_ALLOWED_PINS) {
                    const pinnedProducts = previewProducts.filter((product) => ruleset.pins.includes(product.split_id));
                    const notPinnedProducts = previewProducts.filter((product) => !ruleset.pins.includes(product.split_id));
                    setRuleset({ ...ruleset });
                    setIsChanged(true);
                    setPreviewProducts([...pinnedProducts, ...notPinnedProducts]);
                    setSelection([]);
                    setActionPopoverVisible(false);
                  } else {
                    createToast({ content: `You cannot have more than ${MAX_ALLOWED_PINS} pins at present. If you require more than this, please contact support to discuss your use-case.` });
                  }
                }}, {
                  content: "Send to Bottom", onAction: () => {
                    ruleset.antipins = ruleset.antipins.concat(selection.filter((id) => !ruleset.antipins.includes(id)));
                    ruleset.pins = ruleset.pins.filter((id) => !ruleset.antipins.includes(id));
                    if (ruleset.antipins.length < MAX_ALLOWED_PINS) {
                      setRuleset({ ...ruleset });
                      setSelection([]);
                      setIsChanged(true);
                      setActionPopoverVisible(false);
                    } else {
                      createToast({ content: `You cannot have more than ${MAX_ALLOWED_PINS} products sent to bottom at present. If you require more than this, please contact support to discuss your use-case.` });
                    }
                  }
                }, ...(!ruleset.disjunctive ? [{content: 'Delete', onAction: () => {
                  deleteProductIds(selection.flatMap((ids) => ids));
                  setSelection([]);
                  setIsChanged(true);
                  setActionPopoverVisible(false);
                }}] : []), {content: 'Deselect', onAction: () => {
                  setSelection([]);
                  setActionPopoverVisible(false);
                }}]}
              />
            </Popover>
          </div>)}
          setProducts={setPreviewProducts}
          products={previewProducts}
          controls={{ search: true }}
          sort="featured"
          beforeQueryRun={queryTransform}
          onDragDrop={dragDrop}
          columnChoices={columnChoices}
          banners={ruleset && ruleset.banners || []}
          setBanners={false && ((banners) => {
            setRuleset({ ...ruleset, banners: banners });
          })}
          setColumnChoices={(columns) => {
            setColumnChoices(columns.map((c) => { return { ...c, renderText: c.render, render: eval(c.renderText) } }));
            authFetch("/api/global/merchandising", { json: { columns: columns.map((c) => { return { ...c, render: c.renderText }; }) } }).then((r) => {
              setColumnChoices(r.columns.map((c) => { return { ...c, renderText: c.render, render: eval(c.render) } }));
            });
          }}
          selectedColumns={selectedColumns}
          setSelectedColumns={(columns) => {
            authFetch("/api/global/merchandising", { json: { selected: columns } }).then((r) => {

            });
            setSelectedColumns(columns);
          }}
          selectedCallback={selectedCallback}
          renderProduct={((product, idx, choices, spot) => {
            const isFiltered = spot.spotDOM.facets().filter(function(f) { return f.isEnabled(); }).length > 0 || (spot.spotDOM.search() != null && spot.spotDOM.search() != "");
            const splitId = (Array.isArray(product) ? product.map((p) => p.split_id).sort()[0] : product.split_id);
            return (
              <ProductSearchResult
                idx={idx}
                disabled={loadingLevel > 0 || spot.spotDOM.isQuerying()}
                onSelect={isEditor &&  dragDrop && ((value) => {
                  if (value)
                    setSelection([...selection, splitId]);
                  else
                    setSelection(selection.filter((id) => id != splitId));
                })}
                selected={isEditor &&  dragDrop && selectionHash[splitId]}
                onPin={isEditor && dragDrop && pinnedProducts && !pinnedProducts[splitId] && ((product) => {
                  ruleset.pins = previewProducts.slice(0, idx + 1).map((p) => p.split_id);
                  setRuleset({ ...ruleset, refresh: new Date() });
                  setIsChanged(true);
                })}
                onUnpin={isEditor && dragDrop && pinnedProducts && pinnedProducts[splitId] && ((product) => {
                  ruleset.pins = ruleset.pins.filter((id) => id != splitId);
                  setRuleset({ ...ruleset, refresh: new Date() });
                  setIsChanged(true);
                })}
                onUnantipin={isEditor && antipinnedProducts && antipinnedProducts[splitId] && ((product) => {
                  ruleset.antipins = ruleset.antipins.filter((id) => id != splitId);
                  setRuleset({ ...ruleset });
                  setIsChanged(true);
                })}
                onChangeIndex={isEditor &&  dragDrop && !isFiltered && (!antipinnedProducts || !antipinnedProducts[splitId]) && ((newIndex, type, event) => {
                  if (newIndex < 0) {
                    createToast({ content: `You must specify a number between 1 and ${MAX_ALLOWED_PINS}.` });
                    return false;
                  } else if (newIndex < MAX_ALLOWED_PINS) {
                    const newPage = Math.ceil(newIndex / spot.spotDOM.paginate());
                    const selectNext = () => {
                      if (type == "enter" || type == "tab" || type == "shift+tab") {
                        let targetIdx = type == "shift+tab" ? idx - 1 : idx + 1;
                        if (newIndex > idx)
                          targetIdx = targetIdx + 1;
                        selectPin(targetIdx);
                        event.preventDefault();
                      }
                    };
                    if (newPage < spot.spotDOM.page()) {
                      dragDrop(idx, newIndex, 1);
                      selectNext();
                    } else {
                      setLoadingLevel(loadingLevel);
                      spot.spotDefault.scrollPaginationHelper.loadUpTo(newPage).done(() => {
                        setLoadingLevel(loadingLevel);
                        selectNext();
                        setDeferredDrag([idx, newIndex, (spot.spotDOM.page()-1)*spot.spotDOM.paginate()+1]);
                      });
                    }
                  } else {
                    createToast({ content: `You cannot have more than ${MAX_ALLOWED_PINS} pins at present. If you require more than this, please contact support to discuss your use-case.` });
                    return false;
                  }
                })}
                onDelete={isEditor &&  dragDrop && !ruleset.disjunctive && ((product) => { deleteProductIds([splitId]); })}
                onImage={isEditor && ((id) => {
                  setIsChanged(true);
                  if (!id && ruleset.preferred_images && ruleset.preferred_images[splitId])
                    delete ruleset.preferred_images[splitId];
                  else if (id) {
                    if (!ruleset.preferred_images)
                      ruleset.preferred_images = {};
                    ruleset.preferred_images[splitId] = id;
                  }
                  setRuleset({ ...ruleset, preferred_images: { ...ruleset.preferred_images } });
                })}
                image={isEditor && ruleset && ruleset.preferred_images && ruleset.preferred_images[splitId]}
                authFetch={authFetch}
                key={splitId}
                profile={profile}
                product={product}
                info={choices && choices.length > 0 && (<table>
                  <tbody>
                    {(choices.map((id) => columnChoices.filter((choice) => choice.value == id)[0]).filter((choice) => choice != null).map((choice) => {
                      let value = "";
                      try {
                        value = choice.render(product, { moment: moment });
                      } catch (e) {
                        console.error("Column Choice " + choice.label + " Error: " + e);
                      }
                      return (<tr key={"choice-" + choice.label}>
                        <td>{choice.label}</td>
                        <td>{value}</td>
                      </tr>);
                    }))}
                  </tbody>
                </table>)}
              />
            );
          })}
          paginationType="infinite"
          resultsPerPage={36}
          title={<Stack alignment="center"><Heading>Collection Preview</Heading>{ruleset && ruleset.pins && ruleset.pins.length > 0 && (<Popover
              active={pinPopoverActive}
              activator={<Link onClick={() => { setPinPopoverActive(true); }}>{ruleset.pins.length} Pins</Link>}
              onClose={() => { setPinPopoverActive(false); }}
              sectioned
            >
              <Form onSubmit={() => {
                const targetIndex = parseInt(goToPin) - 1;
                selectPin(targetIndex);
                setGoToPin("1");
                setPinPopoverActive(false);
              }}>
                <Stack alignment="flexEnd">
                  <TextField disabled={loadingLevel > 0} label="Go to Pin" type="number" min="1" max={ruleset.pins.length} value={goToPin} onChange={setGoToPin} required/>
                  <Button onClick={() => { selectPin(goToPin - 1); }} disabled={loadingLevel > 0}>Go</Button>
                </Stack>
                <div style={{ marginTop: "4px" }}>
                  <Button fullWidth destructive onClick={() => {
                    setRuleset({ ...ruleset, pins: ruleset.pins.slice(0, goToPin), refresh: new Date() });
                    setSelection([]);
                    setPinPopoverActive(false);
                    setIsChanged(true);
                  }}>Truncate</Button>
                </div>
              </Form>
          </Popover>)}</Stack>}
        />}
      </Layout.Section>
    </Layout>);
}

export function CollectionDetails() {
  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [profile] = Auth.useProfile()
  const [type, id, from, viewId] = [params.get("type"), params.get("id"), params.get("from"), params.get('view')];
  const [collection, setCollection] = useState(null);
  const [settings, setSettings] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [loadingLevel, _setLoadingLevel] = useState(1);
  const [isChanged, setIsChanged] = useState(false);
  const [ruleset, setRuleset] = useState(null);
  const [views, setViews] = useState(null);
  const [view, setView] = useState(null);
  const [error, setError] = useState({});
  // Awkward
  const [hasSales, setHasSales] = useState(false);
  const redirect = useRedirect();
  const authFetch = useSpotFetch();
  const isEditor = ruleset && typeof(ruleset) === "object" && !ruleset.global;

  const createModal = useCreateModal();

  const setLoadingLevel = useCallback((level) => {
    _setLoadingLevel(Math.max(level, 0));
  }, [loadingLevel, _setLoadingLevel]);

  function updateCollection(ruleset_id, updated_at) {
    setLoadingLevel(loadingLevel + 1);
    return authFetch(`/api/collections/${collection.id}`, { json: {
      ruleset_id: ruleset_id,
      type: collection.type,
      updated_at: updated_at
    }})
    .then((r) => {
      setView(null);
      setViews(r.views);
      setRuleset(r.rule);
      setCollection(r.collection);
      setUpdatedAt(r.updated_at || new Date().toISOString());
      setLoadingLevel(loadingLevel - 1);
      setIsChanged(false);
    });
  }


  function onSave() {
    if (isEditor) {
      setLoadingLevel(loadingLevel + 1);
      return authFetch("/api/global/rulesets" + ((view ? view.id : ruleset.id) ? "/" + (view ? view.id : ruleset.id) : ""), { json: view || ruleset })
        .then((r) => {
          setLoadingLevel(loadingLevel - 1);
          if (view && !view.id) {
            setViews([...views, r]);
            setIsChanged(false);
            setUpdatedAt(r.updated_at || new Date().toISOString());
            redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}&view=${encodeURIComponent(r.id)}`);
          } else if (!view) {
            setRuleset(r);
            updateCollection(r.id, updatedAt);
          } else {
            setIsChanged(false);
            setView(r);
            setViews(views.map((v) => v.id == r.id ? r : v));
            setUpdatedAt(r.updated_at || new Date().toISOString());
          }
      })
    } else {
      return updateCollection(ruleset, updatedAt);
    }
  }

  useEffect(() => {
    if (!collection && id) {
      authFetch("/api/collections/" + id + "?type=" + type).then((r) => {
        setCollection(r.collection);
        setLoadingLevel(loadingLevel - 1);
        setViews(r.views);
        setRuleset(r.rule);
        setUpdatedAt(r.updated_at);
        setSettings({ ...r, ruleset: null, updated_at: null });
      });
    } else if (!id) {
      redirect('/merchandising/collections');
    }
  }, [authFetch, id, type, collection, params, redirect]);

  useEffect(() => {
    if (ruleset) {
      if (viewId != null && !view || (view && view.id || "") != viewId) {
        if (viewId == "") {
          setView({ ...ruleset, id: undefined, handle: "", parent_id: ruleset.id, sort_option_id: null, facet_group_id: null, merge_id: null, split_id: null, personalization_id: null, rules: [{ column: ["default"] }] });
          setIsChanged(true);
        } else
          setView(views.filter((v) => v.id == viewId)[0]);
      } else if (viewId == null && view != null) {
        setView(null);
      }
    }
  }, [ruleset, viewId]);
  
  const hasError = error && Object.values(error).filter((v) => v != null).length > 0;

  if (!collection || !settings)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);
  return (<>
    {isChanged && <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{ onAction: onSave }}
    />}
    <Page
      fullWidth
      permission="rules"
      resourceName={{ singular: "collection", plural: "collections" }}
      resourceId={collection && collection.id}
      onSave={collection && onSave}
      audit={collection && collection.id && {resource: "Collection", id: collection.id}}
      tertiaryActions={
        (isEditor && [{
          content: 'Delete Custom Display Rule',
          onAction: () => {
            setIsChanged(true);
            setRuleset(null);
            setView(null);
          }
        }] : [])
      }
      onBack={(() => {
        if (typeof(from) == "string")
          redirect('/merchandising/collections');
        else
          redirect('shopify://admin/collections/' + id);
      })}
      disableThemes
      isChanged={isChanged && !hasError}
      isLoading={loadingLevel > 0}
      overrideThemes
      spotState={hasSales ? true : "external"}
      title={collection.title}
      overrideTitle={<Stack alignment="center">
        <ExternalLink url={profile && profile.shop && ("https://" + profile.shop.shop_origin + "/collections/" + collection.handle + (view && view.id ? "?rule=" + view.handle : ""))}>{collection.title}</ExternalLink>
        {/* We specifically don't display this if loading, because of a react bug that doesn't properly redraw the select box.*/}
        {loadingLevel == 0 && ruleset && typeof(ruleset) == "object" && ruleset.id && views.length > 0 && <Select plain disabled={!view && views.length == 0}
          options={[
            { label: (ruleset.handle || "unnamed") + ("*"), value: (ruleset.id + "") },
            ...views.map((v) => { return { label: (v.handle || "unnamed"), value: v.id + "" } }),
            ...(view && !view.id ? [{ label: (view.handle ? view.handle : "New View"), value: view.id || "new" }] : [])
          ]}
          value={view ? (view.id ? (view.id + "") : "new") : (ruleset.id + "")}
          onChange={((viewId) => {
            if (viewId == "")
              redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`);
            else
              redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}&view=${encodeURIComponent(viewId)}`);
          })}
        />}
        {ruleset && typeof(ruleset) == "object" && ruleset.id && views.length > 0 && <Button square disabled={!view} loading={loadingLevel > 0} primary={!view} onClick={() => {
          updateCollection(view.id, updatedAt).then((r) => {
            redirect(`/merchandising/collections?type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`);
          });
        }}><Icon source={FlagMajor}/></Button>}
        {ruleset && typeof(ruleset) == "object" && ruleset.id && <Button square primary onClick={() => {
          redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}&view=`);
        }}><Icon source={AddMajor}/></Button>}
        {view && <Button square onClick={() => {
          createModal({
            title: "Delete this view?",
            secondaryActions: [{ content: "Close" }],
            primaryAction: { content: "Confirm", onAction: () => {
              if (viewId != "") {
                return authFetch("/api/global/rulesets", { method: "DELETE", json: { ids: [viewId] } }).then((r) => {
                  setViews(views.filter((v) => v.id != viewId));
                  redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`);
                });
              } else {
                redirect(`/merchandising/collections?from=${encodeURIComponent(from)}&type=${encodeURIComponent(type)}&id=${encodeURIComponent(id)}`);
              }
            }},
            render: (() => {
              return (<Modal.Section>
                <TextContainer>
                  <p>
                    This will remove this view of this display rule permanently. Are you sure you want to continue?
                  </p>
                </TextContainer>
              </Modal.Section>);
            })
          })
        }} destructive><Icon source={DeleteMajor}/></Button>}
      </Stack>}
      subtitle="Specify Spot specific properties of this collection."
    >
      <CollectionContext key="collection-context" setHasSales={setHasSales} hasSales={hasSales} isChanged={isChanged} error={error} setError={setError} ruleset={view || ruleset} parent={view ? ruleset : null} setRuleset={(ruleset) => {
        if (view) {
          setView(ruleset);
        } else {
          setRuleset(ruleset)
        }
      }} settings={settings} collection={collection} setIsChanged={setIsChanged} loadingLevel={loadingLevel} setLoadingLevel={setLoadingLevel}/>
    </Page>
  </>)
}
