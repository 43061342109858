import React, {useState, useEffect} from 'react';
import {
  Badge
} from 'admin-frontend';
import { MultiTableForm, Auth, rearrangeArray, Stack, Select, Layout, TextField } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";

import { Page } from "../../components/Page"
import { FieldSpecifier, productFields } from "../../components/Fields";
import { LocalizedTextField } from "../../components/Localization";


function convertFromBackend(option) {
  if (option.property === "product-metafield" || option.property === "variant-metafield")
    return [option.property, option.namespace, option.key];
  if (option.property === "product-custom-field" || option.property === "variant-custom-field")
    return [option.property, option.field];
  if (option.property === "option")
    return [option.property, option.option];
  return [option.property];
}

function convertToBackend(option) {
  if (option[0] === "product-metafield" || option[0] === "variant-metafield")
    return { property: option[0], namespace: option[1], key: option[2] };
  if (option[0] === "product-custom-field" || option[0] === "variant-custom-field")
    return { property: option[0], field: option[1] };
  if (option[0] === "option")
    return { property: option[0], option: option[1] };
  return { property: option[0] };
}

export function SortOptions() {
  const [error, setError] = useState({});
  const [productSortOptions, setProductSortOptions] = useState(null);
  const [originalProductSortOptions, setOriginalProductSortOptions] = useState(null);
  const [theme, setTheme] = useState(0);
  const [productSortUpdatedAt, setProductSortUpdatedAt] = useState(null);
  const [productSortOptionsLoading, setProductSortOptionsLoading] = useState(true);
  const [productSortOptionsLoaded, setProductSortOptionsLoaded] = useState(false);
  const [editingSortOptionId, setEditingSortOptionId] = useState(null);
  const [editingSortOptionLabel, setEditingSortOptionLabel] = useState("");
  const [editingSortOptionDescription, setEditingSortOptionDescription] = useState("");
  const [editingSortOptionDirection, setEditingSortOptionDirection] = useState(null);
  const [editingSortOptionType, setEditingSortOptionType] = useState(null);
  const [editingSortOptionVisibility, setEditingSortOptionVisibility] = useState(null);
  const [editingSortOptionProperty, _setEditingSortOptionProperty] = useState(null);

  const authFetch = useSpotFetch();

  function setEditingSortOptionProperty(property) {
    if (property) {
      let target = productFields.filter((e) => e.handle === property[0])[0];
      if (target) {
        if (target.type !== "both")
          setEditingSortOptionType(target.type == "string" ? "string" : "numeric");
      } else
        setEditingSortOptionType("special");
    } else {
      setEditingSortOptionType(null);
    }
    _setEditingSortOptionProperty(property);
  }


  useEffect(() => {
    if (!productSortOptionsLoaded) {
      authFetch("/api/global/sort_options")
        .then((r) => {
            setProductSortOptions(r.sort_options);
            setOriginalProductSortOptions([...r.sort_options]);
            setProductSortUpdatedAt(r.updated_at);
            setProductSortOptionsLoaded(true);
            setProductSortOptionsLoading(false);
        });
    }
  }, [productSortOptionsLoaded, authFetch]);

  function saveSortOptions(productSortOptions) {
    setProductSortOptionsLoading(true);
    authFetch("/api/global/sort_options", { json: { sort_options: productSortOptions, updated_at: productSortUpdatedAt } })
      .then((r) => {
        setProductSortOptions(r.sort_options);
        setOriginalProductSortOptions(r.sort_options);
        setProductSortUpdatedAt(r.updated_at);
        setProductSortOptionsLoading(false);
      }, (r) => {
        setProductSortOptions([...originalProductSortOptions]);
        setProductSortOptionsLoading(false);
      });
  }

  const resourceName = {
    singular: "sort option",
    plural: "sort options"
  };
  const [profile] = Auth.useProfile();

  let targetField = editingSortOptionProperty && productFields.filter((e) => e.handle === editingSortOptionProperty[0])[0];
  let isEditing = editingSortOptionDirection !== null;

  return (
    <Page
      permission="sort-options"
      breadcrumbs={isEditing ? [{content: 'Sort Options', onAction: () => {
        setEditingSortOptionLabel("");
        setEditingSortOptionDescription("");
        setEditingSortOptionDirection(null);
      }}] : []}
      audit={{resource: "SortOption"}}
      resourceName={resourceName}
      isLiveCallback={(theme) =>
        theme.role === "main" && productSortOptions && productSortOptions.filter((t) => t.theme_id === theme.id).length > 0
      }
      isChanged={true}
      isLoading={productSortOptionsLoading}
      localization
      overrideThemes
      disableThemes={isEditing}
      setTheme={(newTheme) => { if (theme !== newTheme) { setTheme(newTheme); } }}
      onSave={isEditing && ((theme) => {
        setError({});
        if (/^\s*$/.test(editingSortOptionLabel)) {
          setError({ ...error, label: "Requires a label." });
          return;
        }
        productSortOptions[editingSortOptionId !== null ? editingSortOptionId : productSortOptions.length] = {
          label: editingSortOptionLabel,
          ...convertToBackend(editingSortOptionProperty),
          type: editingSortOptionType,
          active: editingSortOptionVisibility === false ? false : true,
          direction: editingSortOptionDirection,
          description: editingSortOptionDescription,
          theme_id: theme ? theme.id : null,
          locales: typeof(editingSortOptionLabel) == "object" ? Object.keys(editingSortOptionLabel) : profile.shop.locales.map((l) => l.code)
        };
        setProductSortOptions([...productSortOptions]);
        setEditingSortOptionLabel("");
        setEditingSortOptionDescription("");
        setEditingSortOptionDirection(null);
        saveSortOptions(productSortOptions)
      })}
      onPort={!isEditing && ((sourceThemeId, destinationThemeId, preserve) => {
        let targets = [
          ...productSortOptions.filter((e) => (preserve || (e.theme_id || null) !== sourceThemeId) && (e.theme_id || null) !== destinationThemeId),
          ...productSortOptions.filter((e) => (e.theme_id || null) === sourceThemeId).map((e) => { return { ...e, theme_id: destinationThemeId } })
        ];
        setProductSortOptions(null);
        saveSortOptions(targets);
      })}
      getResourceCount={(themeId) => productSortOptions.filter((e) => (e.theme_id || null) === themeId).length}
      onDelete={(themeId) => {
        let targets = [...productSortOptions.filter((option) => (option.theme_id || null) !== themeId)];
        setProductSortOptions(null);
        saveSortOptions(targets);
      }}
    >
      {(currentTheme, currentLocale, themes) => {
        let visibleThemeId = currentTheme ? currentTheme.id : null;
        let visibleOptions = productSortOptions && productSortOptions.filter((e) => (e.theme_id || null) === visibleThemeId)
          .filter((e) => !currentLocale || !e.locales || e.locales.length === 0 || e.locales.filter((l) => l === currentLocale.code).length > 0);

        return (
          <Layout>
            <Layout.Section>
              <MultiTableForm
                resourceName={resourceName}
                isEditing={isEditing}
                hideTable={isEditing}
                editingForm={[
                  (<LocalizedTextField error={error && error.label} key="label" label="Label" locale={currentLocale} value={editingSortOptionLabel} onChange={setEditingSortOptionLabel}/>),
                  (<TextField placeholder="Optional freeform description for this sort option. Does not affect functionality." multiline={4} key="description" label="Description" value={editingSortOptionDescription} onChange={setEditingSortOptionDescription}/>),
                  (<FieldSpecifier label="Field" type={["numeric", "string", "date"]} onChange={(field) => {
                      setEditingSortOptionProperty(field);
                      if (field && field[0] == "search")
                        setEditingSortOptionDirection("desc");
                    } } field={editingSortOptionProperty} extraFields={[{
                      "name": "Search Relevancy",
                      "handle": "search",
                      "level": "product",
                      "type": "special",
                      "specifiers": []
                    }, {
                      "name": "Collection Sort Order",
                      "handle": "manual",
                      "level": "product",
                      "type": "special",
                      "specifiers": []
                    }]}
                  />),
                  (<Select disabled={editingSortOptionProperty && editingSortOptionProperty[0] === "search"} key="direction" options={[{label:"Ascending",value:"asc"},{label:"Descending",value:"desc"}]} label="Direction" value={editingSortOptionDirection} onChange={setEditingSortOptionDirection}/>),
                  (<Select disabled={!targetField || targetField.type !== "both"} key="type" options={[
                    {label:"Numerical",value:"numeric"},
                    {label:"String",value:"string"},
                  ].concat(!targetField ? [{label:"Special",value:"special"}] : [])} label="Type" value={editingSortOptionType} onChange={setEditingSortOptionType}/>),
                  (<Select key="active" options={[
                    {label:"Visible",value:"true"},
                    {label:"Invisible",value:"false"},
                  ]} label="Visibility" value={editingSortOptionVisibility != false ? "true" : "false"} onChange={(val) => {
                    setEditingSortOptionVisibility(val == "false" ? false : true);
                  }}/>)
                ]}
                onDragDrop={(source, destination) => {
                  let newSortOptions = rearrangeArray(visibleOptions, source, destination);
                  let targets = [...productSortOptions.filter((option) => (option.theme_id || null) !== visibleThemeId), ...newSortOptions];
                  setProductSortOptions(targets);
                  saveSortOptions(targets);
                }}
                onNew={() => {
                  setEditingSortOptionId(null);
                  setEditingSortOptionLabel("");
                  setEditingSortOptionDescription("");
                  setEditingSortOptionDirection('asc');
                  setEditingSortOptionProperty(null);
                }}
                disabled={productSortOptionsLoading}
                loading={productSortOptionsLoading}
                bulkActions={[{
                  content: "Delete sort options",
                  onAction: (ids) => {
                    let productSortOptionsHash = Object.fromEntries(ids.map((i) => [i,1]));
                    let targets = visibleOptions.filter((e, idx) => productSortOptionsHash[idx]);
                    targets = [...productSortOptions.filter((e) => targets.filter((i) => i === e).length === 0)];
                    setProductSortOptions(null);
                    saveSortOptions(targets);
                  }
                }]}
                onRowClick={(row, idx) => {
                  setEditingSortOptionId(productSortOptions.indexOf(visibleOptions[idx]));
                  setEditingSortOptionLabel(visibleOptions[idx].label);
                  setEditingSortOptionDescription(visibleOptions[idx].description);
                  setEditingSortOptionDirection(visibleOptions[idx].direction || 'desc');
                  setEditingSortOptionProperty(convertFromBackend(visibleOptions[idx]));
                  if (visibleOptions[idx].type)
                    setEditingSortOptionType(visibleOptions[idx].type);
                  setEditingSortOptionVisibility(visibleOptions[idx].active === false ? false : true);
                }}
                headings={[
                  "Label",
                  "Property",
                  "Type",
                  "Direction",
                  (!currentLocale && profile.shop.locales.length > 1 && "Locales")
                ]}
                rows={visibleOptions && visibleOptions.map((sortOption) => [
                  (typeof(sortOption.label) == "object" ? sortOption.label[currentLocale ? currentLocale.code : sortOption.locales[0]] : sortOption.label),
                  sortOption.property,
                  sortOption.type,
                  sortOption.direction === "asc" ? "Ascending" : "Descending",
                  (!currentLocale && profile.shop.locales.length > 1 && (<Stack>{(sortOption.locales || profile.shop.locales.map((l) => l.code)).sort().map((l, idx) => (<Badge key={`badge-${idx}`}>{l}</Badge>))}</Stack>))
                ])}
              />
            </Layout.Section>
          </Layout>
        )
      }}
    </Page>
  );
}
