import {
  Card,
  Page,
  TextContainer,
  Subheading
} from "admin-frontend";

export function Legal() {
  return (<Page title="Legal">
    <Card sectioned title="Terms of Service">
      <TermsOfService/>
    </Card>
    <Card sectioned title="Privacy Policy">
      <Privacy/>
    </Card>
  </Page>)
}

export function TermsOfService() {
  return (<TextContainer>
      <h1><strong>General Terms and Conditions of Use</strong></h1>
      <p>The following general terms and conditions of use form a legally binding agreement (the “<strong><strong>Agreement</strong></strong>”) governing the access to, and use of, the websites, services and applications owned, operated or provided by or on behalf of eShopAdmin Inc. d.b.a. “<strong><strong>Modd</strong></strong>” (collectively, the “Services”).&nbsp;<strong><strong>BY USING THE SERVICES, YOU AGREE AND ACCEPT TO ENTER INTO THIS AGREEMENT WITH MODD; IF YOU DO NOT AGREE, DO NOT USE THE SERVICES. PLEASE CAREFULLY READ ALL OF THE FOLLOWING TERMS AND CONDITIONS BEFORE YOU PROCEED.</strong></strong></p>
      <h2><strong>The Parties</strong></h2>
      <p>As used in this Agreement, “you” and “your” refers to you, the person accessing the Services; “we” means (and “us”, “our”, “ours” and “ourselves” refer to) Modd; “party” refers to you or to us separately; and “parties” to you and us collectively.</p>
      <h2><strong>Changes to this Agreement</strong></h2>
      <p>We reserve the right, at our sole discretion, to change, modify, add or remove portions of this Agreement at any time (“<strong><strong>Changes</strong></strong>”). Such Changes will be effective as of the date we post them at&nbsp;<strong><a href="https://spot.moddapps.com/setup/legal">https://spot.moddapps.com/setup/legal</a></strong>. If you continue to access and use the Services after the posting of any Changes, you are deemed to accept and agree to such Changes. Therefore, it is your responsibility to visit this page periodically to review this Agreement and related legal notices, such as our privacy policy.</p>
      <h2><strong>The Services</strong></h2>
      <p>We develop and make available to you apps designed to enhance your e-commerce platform and provide additional value-add features to your Shopify storefront. We also custom develop apps for your store; provide webmaster services, and many other e-commerce services. We take commercially reasonable efforts to provide technical support for all Services.</p>
      <p>Although we do our best to explain each Service’s features to you on our website, we cannot guarantee a Service will look or perform exactly as it appears on a demo page.&nbsp; In addition, please note that each specific Service may be subject to other terms and conditions (including technical details, support and pricing information) as set forth and agreed upon with you in specific agreements and statements or work in respect of each Service. We will bring such additional terms and conditions to your attention whenever you purchase a Service or otherwise seek to engage us to provide a custom Service to you.</p>
      <p>If you have questions about any particular information or any specific term or condition applicable to any one of our Services, please reach out to us using the contact information provided below.</p>
      <h2><strong>Purchases & Payment Terms</strong></h2>
      <p>We offer all Services on a per store basis, meaning you will have to purchase Services on an individual store basis.&nbsp; For example, if you have two stores and wish to use one of our apps for both stores, you must purchase two subscriptions.</p>
      <p>Unless otherwise indicated, all sales are final and non-refundable when you conclude the purchase of the Service. You will be charged based on your predefined billing cycle on Shopify and/or Shopify Plus. When you make a purchase, you confirm that you are entitled to use the selected method of payment. All payments are payable in USD (United States Dollars).</p>
      <p>Our prices do not include applicable sales, goods and services, harmonized or any other taxes or fees that governmental authorities may charge. Such taxes and fees will be added to the total amount you are required to pay and billed to your method of payment. Payment of these taxes and fees will be your sole responsibility.</p>
      <p>You agree to indemnify and hold harmless Modd and its officers, directors, subsidiaries, affiliates, employees, representatives, agents, licensors and their respective successors and assigns (collectively, its “<strong><strong>Others</strong></strong>”) against any chargeback costs (plus any taxes or related fees) we may be required to pay as a result of a payment dispute with you or in relation to a payment you have made to us for the Services.</p>
      <h2><strong>Trial Period</strong></h2>
      <p>We may offer a free trial period for certain Services before you are required to purchase or subscribe. The duration and specific terms of the trial period will be notified to you during the Services install process. You will not be charged for the Services until the trial period has expired.</p>
      <p>You agree that even though you may have access to the Services on a trial basis, free of charge, you will be bound by the terms and conditions of this Agreement. Your continued use of the Services following the conclusion of the trial period constitutes your consent to be charged for those Services and to the provisions in this Agreement as well as any other terms and conditions applicable to the specific Service you are using.</p>
      <h2><strong>Right to Use the Services and Intellectual Property</strong></h2>
      <p>Subject to your compliance with the terms and conditions of this Agreement, we hereby grant to you a limited, non-exclusive, non-assignable, non-sublicensable, revocable right to:</p>
      <ul>
        <li>
          <p>Access, view and use our website, including a limited licence to download, print and store single copies of Our Content (as defined below,&nbsp;excluding&nbsp;the source code) from our website, for your personal use, provided that you maintain all copyright and other notices contained in such items and you do not (and do not allow any third party to) copy, modify, create derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, in our website;</p>
        </li>
        <li>
          <p>Install and use the Services for the purpose of operating your online store. This right terminates upon termination of this Agreement or any other agreements previously entered into by the parties, as may be applicable. Any and all rights not expressly granted to you are reserved by us, and this Agreement does not confer to you a proprietary right, title or interest in any Services or any part thereof.</p>
        </li>
      </ul>
      <p>All materials displayed or otherwise accessible on or through the Services, including source code (“<strong><strong>Our Content</strong></strong>”), and the selection and arrangement of Our Content, are protected by copyright. We either own the intellectual property rights, including copyright, or have acquired the necessary rights or licences, in Our Content. Portions of Our Content may have been licensed to us or published on our website, or as part of our Services, by third parties. Any reproduction, modification, publication, transmission, transfer, sale, distribution, display or exploitation of the Services or Our Content, whether in whole or in part, without our express written permission, is strictly prohibited.</p>
      <p>Certain words, phrases, names, designs or logos made available on or through the Services may constitute trademarks, service marks, or trade names that are owned by us or others. The display of such marks on or through the Services does not imply that you have been granted a licence by us or others with respect to them.</p>
      <p>If you believe in good faith that any material that is made available on or through the Services, infringes your copyright, please notify us using the Contact Us link on our website or using the contact information provided below.</p>
      <h2><strong>Acceptable Use</strong></h2>
      <p>Without limiting anything else in this Agreement, you must not use (or cause or permit to be used) the Services:</p>
      <ul>
        <li>
          <p>In any way that causes, or may cause, damage to the Services, or impairment of the availability or accessibility of the Services;</p>
        </li>
        <li>
          <p>In any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</p>
        </li>
        <li>
          <p>To post, transmit, or otherwise make available any material that is or may be: (a) threatening, harassing, degrading, hateful, or intimidating; (b) defamatory; (c) fraudulent or tortious; (d) obscene, indecent, pornographic, or otherwise objectionable; or (e) non-compliant with applicable privacy legislation or an infringement of another person’s privacy, including without limitation by disclosing the personal data of another individual without their knowledge and consent;</p>
        </li>
        <li>
          <p>To post, transmit, or otherwise make available, any material that may violate: a) our proprietary rights; or b) the proprietary rights of any third party, including, without limitation, copyrighted software, photographs, texts, videos or artwork or any moral rights associated therewith;</p>
        </li>
        <li>
          <p>For any commercial purposes other than those which are expressly set out in this Agreement;</p>
        </li>
        <li>
          <p>To copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software;</p>
        </li>
        <li>
          <p>To conduct any systematic or automated data collection activities (including, without limitation, scraping, data mining, data extraction and data harvesting) on or in relation to this website without our prior express written consent;</p>
        </li>
        <li>
          <p>To impersonate any person or entity or misrepresent your affiliation with any other person or entity;</p>
        </li>
        <li>
          <p>To engage in spamming, flooding, harvesting of email addresses or other personal information, spidering, “screen scraping”, “database scraping”, or any other activity with the purpose of obtaining lists of users or other information (including any activity which involves accessing or using the Services for purposes which are unrelated to the Services);</p>
        </li>
        <li>
          <p>To attempt to gain (or gain) unauthorized access to other computer systems through the Services, or to obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Services; or</p>
        </li>
        <li>
          <p>In a manner which is otherwise contrary to this Agreement. Any use of the Services which is contrary to the above or any other term or condition of this Agreement may result in the immediate termination of this Agreement and of your use of the Services, by us.</p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2><strong>Continued Use, Updates and Upgrades</strong></h2>
      <p>We reserve the right to modify, update, remove or disable access to any Services, without notice to you, and we will not be liable to you if we exercise those rights. When you purchase a particular Service, you are purchasing the right to use that Service as of the time you purchase it. You are not paying for the right to any updates, upgrades or future versions of the Services, though we may make such updates, upgrades or future versions available to you, in our sole discretion. We do not warrant, represent or undertake to provide any updates and in no event shall we be liable to you for our failure to do so.</p>
      <h2><strong>Third Party Services and Content</strong></h2>
      <p>We are not a party to any relationship between you and any third party, including, but not limited to, you and the Shopify platform or you and your customers (your “<strong><strong>Customers</strong></strong>”). In addition, your use of the Services may rely on services and products offered by third parties (“<strong><strong>Third Party Services</strong></strong>”), such as Internet service providers. Your use of Third Party Services may be subject to specific terms and conditions set by those third parties, and we make no warranties or representations and assume no responsibility whatsoever as regards to your relationships with these third parties, including anything third parties may do or fail to do. Without limiting the generality of the foregoing, we are not responsible for providing technical support for any apps, products, or services provided to you by third parties.</p>
      <p>We may make content and materials from third parties (“<strong><strong>Third Party Content</strong></strong>”) available to you through our websites, such as reviews. Our making available of such Third Party Content does not constitute an endorsement or recommendation, and we are not responsible for any reliance you may place on Third Party Content. We make no warranties or representations as to any Third Party Content and assume no responsibility whatsoever for it. Any opinions or statements made by third parties are those of such third parties, and do not necessarily state or reflect our views.</p>
      <p>YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NO RECOURSE AGAINST US FOR ANY ACTS OR OMISSIONS OF THIRD PARTIES, AND YOUR INTERACTION WITH THIRD PARTIES IS ENTIRELY AT YOUR OWN RISK. YOU AGREE THAT WE WILL HAVE NO LIABILITY TO YOU WITH RESPECT TO THE ACTS, OMISSIONS, ERRORS, REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE OF, OR FOR ANY DAMAGES OR EXPENSES RESULTING IN ANY MANNER FROM YOUR INTERACTIONS WITH, ANY: A) THIRD PARTIES; B) THIRD PARTY SERVICES; OR C) THIRD PARTY CONTENT; AND WE ARE NOT OBLIGED TO BECOME INVOLVED IN ANY DISPUTES YOU MAY HAVE WITH ANY THIRD PARTIES.&nbsp; IF YOU HAVE A DISPUTE WITH ANY THIRD PARTIES, YOU RELEASE MODD AND ITS OTHERS FROM ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES, ARISING OUT OF OR IN ANY WAY RELATED TO SUCH DISPUTES AND/OR OUR SERVICES.</p>
      <h2><strong>Links from the Services</strong></h2>
      <p>We do not monitor or review the content of other parties’ websites and services which are linked to from this website, nor do we control the availability and content of such websites and services. Opinions expressed or material appearing on such websites are not endorsed by us and we are not the publisher of such opinions or material, nor are we responsible for the accuracy of such opinions or material. We are not responsible for the privacy practices, or content, of third party websites and services. Whenever a link from our Services directs outside of our Services, it is your responsibility to read the terms of use and privacy statement of the websites and services you are thereby accessing before disclosing any personal data. Modd will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal data.</p>
      <h2><strong>Disclaimer</strong></h2>
      <p>THE SERVICES AND THE MATERIALS ON OUR WEBSITE ARE PROVIDED ON AN AS-IS, AS-AVAILABLE, BASIS AND WITHOUT WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED.&nbsp; BY ACCESSING AND USING THE SERVICES AND THE MATERIALS ON OUR WEBSITE, YOU ACKNOWLEDGE AND AGREE THAT SUCH ACCESS AND USE IS ENTIRELY AT YOUR OWN RISK.&nbsp; WE MAKE NO REPRESENTATION OR WARRANTIES REGARDING THE USE OR THE RESULTS OF THE SERVICES OR THE MATERIALS ON OUR WEBSITE (WHETHER PROVIDED DIRECTLY BY US OR THROUGH THIRD PARTIES OR OUR AFFILIATES), INCLUDING, WITHOUT LIMITATION, THAT THE SERVICES OR MATERIALS ON OUR WEBSITE WILL BE ACCURATE, COMPLETE, CORRECT, TIMELY OR SUITABLE, THAT THE SERVICES AND THE MATERIALS ON OUR WEBSITE ARE OF MERCHANTABLE QUALITY OR FIT FOR A PARTICULAR PURPOSE, THAT THE SERVICES AND THE MATERIALS ON OUR WEBSITE WILL BE AVAILABLE OR UNINTERRUPTED AT ALL TIMES OR AT ANY PARTICULAR TIME, OR THAT THE SERVICES AND THE MATERIALS ON OUR WEBSITE WILL BE FREE FROM ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AND CONDITIONS REGARDING USE OF THE SERVICES AND THE MATERIALS ON OUR WEBSITE, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, WHETHER EXPRESS OR IMPLIED, OR ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE.&nbsp; WE ARE NOT RESPONSIBLE FOR WHAT OTHERS DO WITH ANY MATERIALS OR INFORMATION YOU CHOOSE TO SHARE USING THE SERVICES.</p>
      <h2><strong>Limitation of Liability</strong></h2>
      <p>THIS SECTION LIMITS OUR LIABILITY TO YOU.&nbsp; PLEASE READ IT CAREFULLY.</p>
      <p>To the maximum extent permitted by law, under no circumstances shall you be entitled to recover any special, incidental, consequential or indirect damages from Modd or its Others, which you may suffer arising out of, caused by, or in connection with, the use, or inability to use, the Services, any inaccuracy, incompleteness or incorrectness contained in the materials displayed, accessed or used as part of the Services, or your reliance or acting upon the materials used as part of the Services, including any loss or damages in the nature of or relating to lost business, lost savings, lost data and/or profits, regardless of the cause and whether arising in contract, tort, in equity, at law or otherwise, and whether or not Modd has or had been advised of the possibility of such losses or damages. Unless otherwise agreed to by you and Modd in writing, under no circumstances shall the liability of Modd and its Others to you, for damages or losses suffered by you arising out of, related to or caused by, the Services, or the use thereof, exceed a maximum equal to the amount actually paid by you for the Services in the twelve (12) months’ period preceding such losses or damages. You acknowledge and agree that the payments paid by you for the Services reflect the allocation of risk set forth in this Agreement and that Modd would not enter into this Agreement without these limitations on liability.</p>
      <p>You agree to release, remise, acquit and discharge Modd and its Others from any claims, actions, demands, costs and expenses of any kind whatsoever, whether in contract, negligence or tort, at law or in equity, or by statute or otherwise, howsoever caused, with respect to your use of, or our operation of, the Services, except as may be set out in the preceding paragraph.</p>
      <h2><strong>Indemnity</strong></h2>
      <p>You agree to indemnify and hold harmless Modd and its Others from any claim or demand (including reasonable legal fees, expert fees and other reasonable litigation costs), arising from, incurred as a result of, or in any manner related to, your use of the Services, including, but not limited to: a) your breach of this Agreement; b) any misrepresentation made by you to any third party; c) any third party claim in respect of the Services involving or related to your or your Customers’ use of the Services; d) your ability or your Customers’ ability to access and use the Services; or e) your violation of applicable laws, rules or regulations or the rights of any third party.</p>
      <h2><strong>Cancellation, Termination & Refund Policy</strong></h2>
      <p>If you breach any provision of this Agreement (as determined by us, in our sole discretion), we may terminate this Agreement and you may no longer use the Services. We may, at any time, and for any reason, with or without cause, and in our sole discretion, immediately suspend or terminate (in whole or in part) your permission to use the Services, all without notice or liability to you or to any other person.</p>
      <p>We shall not be responsible for refunding or otherwise paying any funds, amounts or credits that may be owed to you if we have suspended or terminated your permission to use the Services or any part thereof.&nbsp;&nbsp; We reserve the right to cease, suspend or interrupt operation of, or access to, the Services or any part thereof, and we shall not be required to provide any notice in respect of such cessation, suspension or interruption of access, nor shall we have any liability for such cessation, suspension or interruption of access.</p>
      <p>Provisions which, by their nature, should survive termination of this Agreement (including, without limitation, provisions dealing with obligations you have to pay or indemnify us, limitations on our liability, confidentiality, privacy, intellectual property rights and rules governing disputes between us) shall survive termination of this Agreement.</p>
      <h2><strong>Privacy Policy</strong></h2>
      <p>We are committed to protecting your privacy, in accordance with the terms and conditions of the Modd Privacy Policy (the “<strong><strong>Privacy Policy</strong></strong>”). Please ensure that you have reviewed and understand our Privacy Policy before purchasing or subscribing for any Services from us or providing personal data to us. By accessing and using the Services, including without limitation by registering or creating an account or profile with Modd, and by providing personal data (also known as personal information or personally identifiable information) to Modd through the Services, you are acknowledging that you have read our Privacy Policy (which sets out how we process personal data, and our legal bases for doing it), and that you agree and consent to us processing your personal data to provide the Services to you in accordance with this Agreement and our Privacy Policy.</p>
      <p>If, in the course of using any Services, you transfer or give us access to personal data concerning third parties “<strong><strong>Third Party Personal Data</strong></strong>” (such as your Customers or employees), you represent and warrant to us that you have such third parties’ consent, or another legal basis, allowing for&nbsp;the transfer to us and our processing (including collection, use, retention and disclosure, as the case may be) of Third Party Personal Data for the purposes of providing the Services in question. You further acknowledge and agree that we are accessing and processing such Third Party Personal Data exclusively in our capacity as your agent.</p>
      <h2><strong>Your Account and Content</strong></h2>
      <p>You may be required, when you use certain features of the Services, to create an account with us, including a username and password.&nbsp; You are responsible for maintaining the confidentiality of your password and you are responsible for all uses of your username, whether or not you authorize such uses. You agree to notify us immediately of any actual or suspected loss, theft or unauthorized use of your username and password, or your account. We are not responsible for verifying your identity or the identity of anyone who uses your account, and we are not liable for any loss or damage as a result of your failure to protect your password, or as a result of unauthorized use of your username and/or password. You agree that any registration information you provide will be true and accurate, and that you will update it, as is necessary to keep it current. We reserve the right to automatically log you out of your account after such a period of inactivity as we determine is reasonable, in the circumstances.</p>
      <p>While we work hard to protect the security of the content you upload, personal data, and account, we cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately in the event of any actual or suspected breach or unauthorized access or use of your account.</p>
      <p>If you are a resident of the European Union: You have the right to delete your account with us by contacting us. If you choose to permanently delete your account, the non-public personal data that we have associated with your account will also be deleted.</p>
      <h2><strong>Internet Connectivity and Compatible Technology</strong></h2>
      <p>The availability and functioning of the Services depends on the availability of a properly functioning Internet connection, as well as compatible hardware and software. We are not responsible for ensuring uninterrupted access to the Internet or any charges you incur in association with your use of the Internet, nor are we responsible for ensuring your hardware and software are compatible with the Services. You acknowledge that your access to and use of the Services may be impaired or prevented by factors beyond our control (such as issues with your computer system or Internet connectivity) and that we are not responsible for any such factors or their effects. We are not liable for any failure to backup or restore any information or for interruptions, delay or suspension of access to or unavailability of the Services, or any loss of such information, data or transmissions. It is your responsibility to ensure that your data is backed up effectively.</p>
      <h2><strong>Miscellaneous</strong></h2>
      <p>We are physically located in the Province of Quebec, Canada. This Agreement, and all ancillary documents such as the Privacy Policy, will be governed by the laws of the Province of Quebec and the laws of Canada applicable therein and shall be treated in all respects as a Quebec contract, without reference to the principles of conflicts of law. In the event of a dispute, you agree to submit to the exclusive jurisdiction of Quebec’ s civil courts.</p>
      <p>Your use of the Services may also be subject to other local, state, provincial, national or international laws and the use of the Services may be prohibited by law in some jurisdictions. By using the Services, you certify that the laws of the jurisdiction in which you are using the Services permit the use thereof, and you are responsible for complying with all local laws in your jurisdiction. If the laws which apply to your use of the Services would prohibit the enforceability of this Agreement, or impose any additional burdens on us, or confer to you rights which are materially different than those granted to you under this Agreement, you are not authorized to use the Services and you agree to remove them from any device on which they may be installed.</p>
      <p>You agree to waive any right you may have to: (a) a trial by jury; and (b) commence or participate in any class action proceedings against us related to your use of the Services.</p>
      <p>The Services we offer are directed towards and designed for the use of persons above the age of majority in their respective province, state, or country. Persons under the age of majority are not permitted to use the Services on their own, and we will not approve applications of, or establish or maintain accounts or memberships for, any persons below their respective region’s age of majority. You represent that any information regarding your date or birth or age that you provide to us is accurate.</p>
      <h2><strong>Communication</strong></h2>
      <p>All notices given by you to us must in writing by email to&nbsp;<strong><a href="mailto:support@modd.com">support@moddapps.com</a></strong>&nbsp;or sent at the address listed on our Contact Us page on our website. When you contact us through our website or any other Services, or email us, you are communicating with us electronically. We may send you notices or otherwise communicate with you at the email or mailing address you provide to us when you place an order with us, or register to use the Services, or by way of a general posting on our website. You agree that all agreements, notices, disclosures and other electronic communications between the Parties satisfy, for all legal purposes, any requirement that such communications be in writing and/or signed. Notice will be deemed to be received immediately when posted on our website, twenty-four (24) hours after an email is sent and three (3) days after a letter is posted. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post. In the case of an email, notice will be proved by showing the email was sent to the specified email address of the recipient of the notice.</p>
      <p>You are not required to agree to receive promotional messages from us as a condition of using the Services. However, by electing to submit your contact information to us and agreeing to this Agreement, you agree to receive certain communications from us. These communications may include, for example, operational communications concerning your account or use of the Services.</p>
  </TextContainer>);
}

function Privacy() {
  return (<TextContainer>    
    <p>Spot "the App” provides site search services "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</p>
    
    <Subheading>Personal Information the App Collects</Subheading>
    
    <p>When you install the App, we are automatically able to access certain types of information from your Shopify account: </p>
    
    <ul>
      <li>Your product catalog's information.</li>
      <li>Your blogs, articles and pages.</li>  
    </ul>
    
    <p>In addition to this information, you may also explicitly enable certain options that will allow us to collect additional data to provide more features, including, but not limited to:</p>
    
    <ul>
      <li>Your order information.</li>
      <li>Your customer information.</li>
    </ul>
    
    <p>Additionally, we collect information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information, for our records, as well as to have a means of contacting you should any problem arise.</p>
    
    <p>We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies:</p>
    
    <ul>
      <li>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
    </ul>
    
    <Subheading>How Do We Use Your Personal Information?</Subheading>
    
    <p>We use the personal information we collect from you in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.</p>
    
    <Subheading>Sharing Your Personal Information</Subheading>
    
    <p>We do not share, sell or license your personal information, or information about your store or clients, with any third parties outside our company other than for the purposes of delivering the Service. 
      We do use various infrastructure providers, such as Google Cloud Platform, and various application service providers, like Postmark and Zendesk to provide certain features like monitoring, debugging, usage analytics. </p>

    <p>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
    
    <Subheading>Your Rights</Subheading>
    
    <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
    <p>Additionally, if you are a European resident please note that we are processing your information in order to fulfill contracts we might have with you (for example if you would like an export of your customer listing, we will have to download your customer listing from Shopify in order to present it to you), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
    
    <Subheading>Data Retention</Subheading>
    
    <p>We retain any interactions that clients on your site, and managers on your store have with our app, in detail, for 90 days. After that period, a smaller amount of metadata is retained, in order to provide
    a base-level of analytics to you in your analytics dashboard.</p>
    
    <p>At no level is any personally identifiable information retained by default for your clients. The closest that the app retains as part of its 90-day logs are email addresses for shop managers, and IP addresses for clients on your shop who interact with Spot.</p>
    
    <Subheading>Changes</Subheading>
    
    <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
    
    <Subheading>Contact Us</Subheading>
    
    <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@moddapps.com or by mail using the details provided below:</p>
    
    <p>Data Protection Officer</p>
    
    <p>Modd Apps<br/>
    1435 Rue Saint-Alexandre, Suite 220<br/>
    Montreal, QC<br/>
    Canada H3A 2G4</p>
  </TextContainer>);
}

