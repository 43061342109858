import React, { useState, useEffect, useCallback, useMemo } from "react";

import { 
  Checkbox,
  Subheading,
  Heading,
  Spinner
} from "admin-frontend";
import { 
  MultiTable, 
  Auth, 
  Tooltip,
  OptionList,
  ChoiceList,
  Icon,
  Tag,
  useRedirect,
  Button,
  Page as OldPage,
  Stack,
  Layout,
  Card,
  FormLayout,
  TextField,
  Modal,
  Select,
  CircleInformationMajor, 
  SearchMajor, 
  MobilePlusMajor
} from "admin-frontend";
import { useParams } from "react-router-dom";
import { Page } from "../../components/Page";
import { LocalizedTextField, LocalizedBadgeList, localeFormat } from "../../components/Localization";
import { useSpotAPI } from "../../components/API";
import { productFields, titleCase } from "../../components/Fields";
import { SwatchSelector, SwatchText } from "../Swatches";
import { DateField, RelativeDateField, getRelativeDateLabel } from "../../components/DateFields";
import { useLocation } from "react-router-dom";
import { useQuickStartStatus } from "../../components/StatusContext";
import { useSpotFetch } from "../../useSpotFetch";
import { NotFound } from "../../NotFound";

// Value listing.
function translateValueListToFrontend(facet, values) {
  return values.map((value) => {
    if(facet.level === "numeric" || facet.level === "date"){
      var hash = typeof(value) === "object" ? { name: value.name } : {};
      var numericalValue = typeof(value) === "object" ? value.value : value;
      if (numericalValue.startsWith("-"))
        return { ...hash, from: null, to: numericalValue.substring(1) }
      if(numericalValue.endsWith("+"))
        return { ...hash, from: numericalValue.substring(0,numericalValue.length-1), to: null };
      return { ...hash, from: numericalValue.split("-")[0], to: numericalValue.split("-")[1] };
    }
    else {
      if (typeof(value) == "string")
        return { name: value, values: [value] };
      const is_wildcard = typeof value.value === "object" && !Array.isArray(value.value);
      const wildcard_value = is_wildcard ? Object.values(value.value)[0] : null;
      return {
        name: is_wildcard && !value.name ? "*" : value.name,
        swatch_id: value.swatch_id,
        values:
          typeof value.value === "object"
            ? (is_wildcard ? [wildcard_value.replace(/\*$/, "") + "[Wildcard]"] : value.value)
            : [value.value],
      };
    }
  });
}

function translateValueListToBackend(facet, values) {
   return values.map((fv) => {
      var value;
      if (facet.level === "numeric" || facet.level === "date") {
        if (fv.from === null || fv.from === "")
          return { "name": fv.name, swatch_id: fv.swatch_id, value: "-" + fv.to };
        if (fv.to === null || fv.to === "")
          return { "name": fv.name, swatch_id: fv.swatch_id, value: fv.from + "+" };
        return { "name": fv.name, swatch_id: fv.swatch_id, value: fv.from + "-" + fv.to };
      } else if (facet.level === "bool") {
        value = fv.values.map((v) => v == "true");
      } else if (fv.values.length > 1) {
        value = fv.values;
      } else {
        const wildcardIdx = fv.values[0].indexOf("[Wildcard]");
        if (wildcardIdx !== -1) {
          const wildcardValue = (fv.values[0].replace("[Wildcard]", "") + "*");
          return fv.name && fv.name !== "*" ? { name: fv.name, swatch_id: fv.swatch_id, value: {"*": wildcardValue} } : { value: {"*": wildcardValue} };
        }
        value = fv.values[0];
      }
      return fv.name ? { name: fv.name, swatch_id: fv.swatch_id, value: value } : value;
  });
}

function getValueDisplayName(value) {
  if (typeof(value) !== "string")
    return value + "";
  const wildcardIdx = value.indexOf("[Wildcard]");
  if (wildcardIdx === -1)
    return value;
  if (wildcardIdx === 0)
    return "* [Wildcard]";
  return value.slice(0, wildcardIdx) + "* [Starts with Wildcard]";
}

export function EditFacet() {
  const authFetch = useSpotFetch();
  const redirect = useRedirect();
  const sessionTokenFetch = Auth.useFetchSessionToken();
  const [profile] = Auth.useProfile();
  const [swatches, setSwatches] = useState(null);

  const { facetId } = useParams();
  const [facet, setFacet] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [updatedAt, setUpdatedAt] = useState(null);
  const [type, setType] = useState("vendor");
  const [optionName, setOptionName] = useState({ visible: false, value: "" });
  const [metafieldNamespace, setMetafieldNamespace] = useState({
    visible: false,
    value: "",
  });
  const [metafieldKey, setMetafieldKey] = useState({
    visible: false,
    value: "",
  });
  const [customFieldField, setCustomFieldField] = useState({
    visible: false,
    value: "",
  });
  const [warnAboutOrphans, setWarnAboutOrphans] = useState(true);
  const [includeAllValues, setIncludeAllValues] = useState(false);
  const [fieldType, setFieldType] = useState("string");
  const [adjustableRange, setAdjustableRange] = useState(null);
  const [sortOrder, setSortOrder] = useState("name-asc");
  const [singleValueOnly, setSingleValueOnly] = useState(false);
  const [relativeDates, setRelativeDates] = useState(false);
  const [facetValues, setFacetValues] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [newValue, setNewValue] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedSwatchId, setSelectedSwatchId] = useState(null);
  const [valueList, setValueList] = useState(null);
  const [activeList, setActiveList] = useState([]);
  const [search, setSearch] = useState("");
  const [groupSelectedValues, setGroupSelectedValues] = useState(false);
  const [showOnlyUnusedValues, setShowOnlyUnusedValues] = useState(true);
  const [showAllSelectedValues, setShowAllSelectedValues] = useState(false);
  const maxFacetValuesShown = 8;

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [selectedLocales, setSelectedLocales] = useState(["en"]);
  const [currentTheme, setCurrentTheme] = useState(null);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [valuesLoading, setValuesLoading] = useState(false);
  const [quickStartStatus, setQuickStartStatus] = useQuickStartStatus();

  const spotAPI = useSpotAPI();

  const searchURL = useLocation().search;
  const params = useMemo(() => new URLSearchParams(searchURL), [searchURL]);
  const themeId = params.get("theme_id");

  const [isValid, setIsValid] = useState({
    name: true,
    optionName: true,
    metafieldNamespace: true,
    metafieldKey: true,
    facetValues: true,
    customFieldField: true,
    facetLabel: true,
  });

  const updateGroups = useCallback((theme) => {
    return authFetch("/api/facets/groups", { query: theme || themeId ? { theme_id: themeId || theme.id } : {} })
      .then((r) => {
        setGroups(r.groups);
    });
  }, [authFetch, themeId]);

  useEffect(() => {
    if(!groups)
      updateGroups(currentTheme);
  }, [groups, updateGroups, currentTheme])


  useEffect(() => {
    if(!swatches)
      authFetch('/api/swatches').then((r) => setSwatches(r.swatches));
  }, [swatches]);

  const filterActiveList = useCallback((list) => {
    if (!showOnlyUnusedValues)
      return list;
    let usedValues = facetValues ? Object.fromEntries(facetValues.flatMap((fv) => fv.values).map((v) => [v, 1])) : {};
    return list.filter((v) => !usedValues[v.value]);
  }, [showOnlyUnusedValues, selectedValues, facetValues]);


  useEffect(() => {
    if (!facet) {
      if (facetId !== "new") {
        authFetch("/api/facets/" + facetId)
          .then((r) => {
            setFacet(r.facet);
            setName(r.facet.name);
            setWarnAboutOrphans(r.facet.orphans != null ? r.facet.orphans : true);
            setDescription(r.facet.description);
            setType(r.facet.type);
            setOptionName({
              visible: r.facet.type === "option",
              value: r.facet.option || "",
            });
            setMetafieldNamespace({
              visible: r.facet.type.includes("metafield"),
              value: r.facet.namespace || "",
            });
            setMetafieldKey({
              visible: r.facet.type.includes("metafield"),
              value: r.facet.key || "",
            });
            setCustomFieldField({
              visible: r.facet.type.includes("custom-field"),
              value: r.facet.field || "",
            });
            const desiredSortOrder = typeof(r.facet.sort) == 'object' ? (Object.values(r.facet.sort)[0] + "-" + Object.keys(r.facet.sort)[0]) : (r.facet.sort || (r.facet.values && r.facet.values.length > 0 ? "manual" : "name-asc"));
            setSortOrder(desiredSortOrder);
            setSelectedLocales(r.facet.locales || (profile && profile.shop ? profile.shop.locales.map((l) => l.code) : ["en"]));
            setSelectedGroups(r.facet.groups);
            setFieldType(r.facet.level);
            setUpdatedAt(r.facet.updated_at);
            setSingleValueOnly(!!r.facet.single);
            if (r.facet.boundary) {
              setAdjustableRange(r.facet.boundary);
            } else if (r.facet.values && r.facet.values.length > 0) {
              setIncludeAllValues(false);
              setFacetValues(translateValueListToFrontend(r.facet, r.facet.values));
              if (valueList)
                setActiveList(filterActiveList(valueList))
            } else {
              setIncludeAllValues(true);
              setFacetValues([]);
            }
          }, (r) => {
            setFacet(false);
          });
      } else {
        setFacet({});
      }
    }
  }, [facetId, authFetch, facet, profile, valueList, setActiveList, filterActiveList]);

  useEffect(() => {
    if (valueList)
      setActiveList(filterActiveList(valueList));
  }, [showOnlyUnusedValues, filterActiveList, valueList]);



  const catalogueFieldList = [
    { label: "Vendor", value: "vendor" },
    { label: "Custom Product Type", value: "product_type" },
    { label: "Product Standardized Type / Category", value: "category" },
    { label: "Tags", value: "tag" },
    { label: "Price", value: "price" },
    { label: "Published Date", value: "published_at" },
    { label: "Created Date", value: "created_at" },
    { label: "Availability", value: "available" },
    { label: "Inventory Quantity", value: "inventory_quantity" },
    { label: "Compare at Price", value: "compare_at_price" },
    { label: "Option", value: "option" },
    { label: "Option1", value: "option1" },
    { label: "Option2", value: "option2" },
    { label: "Option3", value: "option3" },
    { label: "Product Metafield", value: "product-metafield" },
    { label: "Variant Metafield", value: "variant-metafield" },
    { label: "Product Custom Field", value: "product-custom-field" },
    { label: "Variant Custom Field", value: "variant-custom-field" },
    { label: "Collection", value: "collection" },
  ];

  const performSearch = useCallback((search) => {
    var hash = {};
    const facetLocales = (!selectedLocales || selectedLocales.length === 0 ? profile && profile.shop && (profile.shop.locales.length > 0 ? profile.shop.locales : ["en"]) : selectedLocales);
    function wrapValue(value) {
      if (search != null && search != '')
        return {[value]:[{"value":{"*":  "*" + search + "*"}}]}
      return value;
    }
    if (type === "product-metafield" || type === "variant-metafield") {
      hash[type] = {};
      hash[type][metafieldNamespace.value] = wrapValue(metafieldKey.value);
    } else if (type === "option") {
      hash[type] = wrapValue(optionName.value);
    } else if (type === "product-custom-field" || type === "variant-custom-field") {
      hash[type] = wrapValue(customFieldField.value);
    } else
      hash = wrapValue(type);
    let retrievedHash = {};
    let completedRequests = 0;
    setValuesLoading(true);
    facetLocales.forEach((l) => {
      var completionFunction = function (products, count, options) {
        ++completedRequests;
        var values;
        if (type !== "collection") {
          values = options["options"][0][type];
          if (type === "product-metafield" || type === "variant-metafield")
            values = values[metafieldNamespace.value][metafieldKey.value];
          else if (type === "option")
            values = values[optionName.value];
          else if (type === "product-custom-field" || type === "variant-custom-field")
            values = values[customFieldField.value];
        } else
          values = Object.fromEntries(products.map((collection) => collection.handle).sort().map((handle) => [handle, 1]));
        if (values && Object.keys(values).length > 0)
          retrievedHash = { ...retrievedHash, ...values };
        if (completedRequests === facetLocales.length) {
          let retrievedList = [...Object.keys(retrievedHash).map((e) => { return { label: e, value: e }; })];
          const listHash = Object.fromEntries(retrievedList ? retrievedList.map((e) => [e.label.toLowerCase(),1]) : [])
          if (retrievedList) {
            if (search !== "" && !listHash[search.toLowerCase()])
              retrievedList = [...retrievedList, { label: search + " [Manual]", value: search }];
            if (fieldType !== "bool")
              retrievedList = [...retrievedList, { label: (search !== "" ? search + "* [Starts with Wildcard]" : "* [Wildcard]"), value: search + "[Wildcard]" }];
          }
          setValueList(retrievedList);
          setActiveList(filterActiveList(retrievedList));
          setValuesLoading(false);
        }
      };
      if (type === "collection")
        spotAPI.s().search(search).rows(100).targets(["collections"]).locale(l).e().done(completionFunction);
      else
        spotAPI.s().options([hash], true, "exists").rows(0).locale(l).e().done(completionFunction);
    });
  }, [spotAPI, selectedLocales, profile, type, fieldType, metafieldNamespace, metafieldKey, optionName, customFieldField, setValuesLoading, filterActiveList]);

  const handleValueSearch = useCallback(
    (text) => {
      if (text != search) {
        setValuesLoading(true);
        setSearch(text);
        if (typingTimeout)
          clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(function() {
          setTypingTimeout(null);
          performSearch(text)
        }, 200));
      }
    },
    [performSearch, setSearch, search]
  );
  useEffect(() => {
     handleValueSearch(search);
  }, [search, handleValueSearch]);

  const openFacetValueModal = useCallback(() => {
    if (fieldType === "string" || fieldType === "bool") {
      setSearch("");
      setValueList(null);
      setActiveList(null);
      performSearch(search);
    }
    setModalActive(true);
  }, [performSearch, setSearch, setModalActive]);

  const addFacetValue = useCallback(() => {
    setFrom("")
    setTo("")
    setEditIndex(null);
    openFacetValueModal();
  }, [openFacetValueModal]);

  const editFacetValue = useCallback((facet, idx) => {
    setEditIndex(idx);
    setNewValue(false);
    setShowAllSelectedValues(false);
    setSelectedLabel(facet.name);
    setSelectedSwatchId(facet.swatch_id);
    setSelectedValues(facet.values || []);
    if (facet.from)
      setFrom(facet.from);
    if (facet.to)
      setTo(facet.to);
    setGroupSelectedValues(true);
    openFacetValueModal();
  }, [openFacetValueModal]);

  const deleteFacetValues = useCallback(
    (items) => {
      if (typeof items != "object") items = [items];
      setFacetValues(
        facetValues.filter((fv, idx) => {
          return !items.includes(idx);
        })
      );
      setIsChanged(true);
    },
    [facetValues]
  );


  const handleCloseModal = useCallback(() => {
    setModalActive(false);
    setNewValue(true);
    setSelectedLabel("");
    setSelectedSwatchId(null);
    setGroupSelectedValues(false);
    setSelectedValues([]);
  }, []);

  const handleSaveModal = useCallback(() => {
    setIsValid({ ...isValid, facetLabel: true });
    setIsChanged(true);
    if ((!selectedValues || selectedValues.length == 0) && from != null && to != null) {
      if (editIndex)
        setFacetValues(facetValues.map((e,idx) => idx === editIndex ? { name: selectedLabel, swatch_id: selectedSwatchId, from: from, to: to } : e));
      else
        setFacetValues([...facetValues, { name: selectedLabel, swatch_id: selectedSwatchId, from: from, to: to }]);
      handleCloseModal();
    } else if (
      selectedValues.length > 0 &&
      (!groupSelectedValues || (typeof(selectedLabel) == "object" ? true : selectedLabel.length > 0) )
    ) {
      if (!newValue) {
        var tempValues = [...facetValues];
        tempValues[editIndex] = fieldType === "numeric" || fieldType === "date"
          ? { name: selectedLabel, swatch_id: selectedSwatchId, from: from, to: to }
          : { name: selectedLabel, swatch_id: selectedSwatchId, values: selectedValues };
        setFacetValues(tempValues);
      } else if (groupSelectedValues) {
        setFacetValues([
          ...facetValues,
          { name: selectedLabel, swatch_id: selectedSwatchId, values: selectedValues },
        ]);
      } else {
        const tmp = selectedValues.map((sv) => {
          return fieldType === "numeric" || fieldType === "date"
            ? { name: sv.name, swatch_id: selectedSwatchId,  from: sv.from, to: sv.to }
            : { name: (sv.indexOf("[Wildcard]") !== -1 ? "*" : sv), swatch_id: selectedSwatchId,  values: [sv] };
        });
        setFacetValues([...facetValues, ...tmp]);
      }
      handleCloseModal();
    } else if (selectedLabel.length === 0) {
      setIsValid({ ...isValid, facetLabel: false });
    }
  }, [
    facetValues,
    selectedLabel,
    selectedValues,
    selectedSwatchId,
    editIndex,
    newValue,
    groupSelectedValues,
    handleCloseModal,
    fieldType,
    isValid,
    to,
    from
  ]);


  const resourceName = {
    singular: "facet",
    plural: "facets",
  };

  if (facet == false)
    return <NotFound/>;

  if (!facet)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);

  return (
    <Page
      localization
      isChanged={isChanged}
      audit={facet && {resource: "Facet", id: facet.id}}
      overrideThemes
      resourceName={resourceName}
      resourceId={facet.id}
      disableThemes
      title={facet ? localeFormat(null, facet.name) : titleCase(type.replace(/[_-]/g, " "))}
      subtitle={facetId !== "new" ? "Edit Facet" : "New Facet"}
      breadcrumbs={[{
        content: "Facets",
        onAction: () => {
          redirect("/catalog/facets" + (currentTheme ? "?theme_id=" + currentTheme.id : ""));
        }
      }]}
      onSave={() => {
          var validation = {
            ...isValid,
            name: true,
            optionName: true,
            metafieldNamespace: true,
            customFeldField: true,
            metafieldKey: true,
            facetValues: true,
          };
          var save = true;
          if (name.length === 0) {
            validation.name = false;
            save = false;
          }
          if (facetValues.length === 0 && !includeAllValues && !adjustableRange) {
            validation.facetValues = false;
            save = false;
          }
          if (type === "option" && optionName.value.length === 0) {
            validation.optionName = false;
            save = false;
          }
          if (
            (type === "product-metafield" || type === "variant-metafield") &&
            metafieldNamespace.value.length === 0
          ) {
            validation.metafieldNamespace = false;
            save = false;
          }
          if (
            (type === "product-metafield" || type === "variant-metafield") &&
            metafieldKey.value.length === 0
          ) {
            validation.metafieldKey = false;
            save = false;
          }
           if (
            (type === "product-custom-field" || type === "variant-custom-field") &&
            customFieldField.value.length === 0
          ) {
            validation.customFieldField = false;
            save = false;
          }
          setIsValid(validation);
          if (save) {
            var object = {
              name: name,
              description: description,
              type: type,
              option: optionName.value,
              namespace: metafieldNamespace.value,
              key: metafieldKey.value,
              field: customFieldField.value,
              theme_id: currentTheme ? currentTheme.id : null,
              level: fieldType,
              single: singleValueOnly,
              locales: selectedLocales,
              updated_at: updatedAt,
              orphans: warnAboutOrphans,
              sort: sortOrder == "manual" ? "manual" : { [/asc/.test(sortOrder) ? "asc" : "desc"]: (/count/.test(sortOrder) ? "count" : "name") } ,
              weight: 0,
            };
            if (fieldType === "numeric" && adjustableRange) {
              object["boundary"] = adjustableRange;
            } else if (!includeAllValues) {
              object["values"] = translateValueListToBackend(object, facetValues);
            }
            const endpoint = "/api/facets/" + (facetId !== "new" ? facetId : "");
            authFetch(endpoint, { json: object })
              .then((r) => {
                setIsChanged(false);
                setQuickStartStatus({ ...quickStartStatus, facets: true });
                if(groups && groups.length > 0){
                  authFetch("/api/facets/groups/associate", { json: {
                    facet_group_id: selectedGroups,
                    facet_id: r.id,
                    replace: true
                  } })
                  .then((r) => {
                    redirect("/catalog/facets" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : ""));
                  });
                }
                else {
                  redirect("/catalog/facets" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : ""));
                }
              });
          }
        }
      }
      setTheme={(theme) => {
        if (currentTheme !== theme) {
          setCurrentTheme(theme);
          updateGroups(theme);
        }
      }}
    >
      {(theme, locale) => {
        setCurrentTheme(theme);
        const sortedFacetValues = facetValues && (sortOrder == "name-asc" || sortOrder == "name-desc") ? facetValues.sort(sortOrder == "name-asc" ? ((a,b) => localeFormat(locale, a.name).localeCompare(localeFormat(locale, b.name))) : ((a,b) => localeFormat(locale, b.name).localeCompare(localeFormat(locale, a.name)))) : facetValues;
        return (
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <FormLayout>
                  <LocalizedTextField
                    label="Name"
                    locale={locale}
                    value={name}
                    onChange={(val) => {  setIsChanged(true); setName(val) }}
                    error={!isValid.name}
                  />
                  <TextField
                    label="Description"
                    multiline={4}
                    value={description}
                    placeholder="Optional freeform description for this facet. Does not affect functionality."
                    onChange={(val) => {  setIsChanged(true); setDescription(val) }}
                  />
                  <Select
                    label="Catalogue Field"
                    options={catalogueFieldList}
                    onChange={(value) => {
                      setIsChanged(true);
                      setType(value);
                      setFacetValues([]);
                      setFieldType(value === "available" ? "bool" : (value === "created_at" || value === "published_at" ? "date" : (value === "price" || value === "compare_at_price" || value === "inventory_quantity" ? "numeric" : "string")));
                      setSingleValueOnly(false);
                      if (value === "option") {
                        setOptionName({ visible: true, value: "" });
                        setMetafieldNamespace({ visible: false, value: "" });
                        setCustomFieldField({ visible: false, value: "" });
                        setMetafieldKey({ visible: false, value: "" });
                      } else {
                        if (
                          value === "product-metafield" ||
                          value === "variant-metafield"
                        ) {
                          setOptionName({ visible: false, value: "" });
                          setMetafieldNamespace({ visible: true, value: "" });
                          setMetafieldKey({ visible: true, value: "" });
                          setCustomFieldField({ visible: false, value: "" });
                        } else if (value === "product-custom-field" || value === "variant-custom-field") {
                          setOptionName({ visible: false, value: "" });
                          setMetafieldNamespace({ visible: false, value: "" });
                          setMetafieldKey({ visible: false, value: "" });
                          setCustomFieldField({ visible: true, value: "" });
                        } else {
                          setOptionName({ visible: false, value: "" });
                          setMetafieldNamespace({ visible: false, value: "" });
                          setMetafieldKey({ visible: false, value: "" });
                          setCustomFieldField({ visible: false, value: "" });
                        }
                      }
                    }}
                    value={type}
                  />
                  <Stack distribution="fillEvenly">
                    {optionName.visible ? (
                      <TextField
                        value={optionName.value}
                        placeholder="option name"
                        onChange={(value) => {
                          setOptionName({ visible: true, value: value });
                          setIsChanged(true);
                        }}
                        error={!isValid.optionName}
                      />
                    ) : null}
                    {metafieldNamespace.visible ? (
                      <TextField
                        value={metafieldNamespace.value}
                        placeholder="namespace"
                        onChange={(value) => {
                          setMetafieldNamespace({ visible: true, value: value });
                          setIsChanged(true);
                        }}
                        error={!isValid.metafieldNamespace}
                      />
                    ) : null}
                    {metafieldKey.visible ? (
                      <TextField
                        value={metafieldKey.value}
                        placeholder="key"
                        onChange={(value) => {
                          setMetafieldKey({ visible: true, value: value });
                          setIsChanged(true);
                        }}
                        error={!isValid.metafieldKey}
                      />
                    ) : null}
                    {customFieldField.visible ? (
                      <TextField
                        value={customFieldField.value}
                        placeholder="field"
                        onChange={(value) => {
                          setCustomFieldField({ visible: true, value: value });
                          setIsChanged(true);
                        }}
                        error={!isValid.metafieldKey}
                      />
                    ) : null}
                  </Stack>
                  {(fieldType === "string" || fieldType === "bool") && (<Stack distribution="fillEvenly" alignment="center">
                    <Checkbox
                      label="Include all values automatically"
                      onChange={(value) => {
                        setIsValid({ ...isValid, facetValues: true });
                        setIncludeAllValues(value);
                        if (sortOrder == "manual" && !value)
                          setSortOrder("name-asc");
                        setIsChanged(true);
                      }}
                      error={!isValid.facetValues && "Your facet must contain at least one manual value, or include all values automatically."}
                      checked={includeAllValues}
                    />
                    <Select
                      options={[...(!includeAllValues ? [{ label: "Manual Ordering", value: "manual" }] : []), { label: "Sort Name Ascending", value: "name-asc" }, { label: "Sort Name Descending", value: "name-desc" }, { label: "Sort Count Ascending", value: "count-asc" }, { label: "Sort Count Descending", value: "count-desc" }]}
                      onChange={(value) => { setSortOrder(value);  setIsChanged(true); }}
                      value={sortOrder}
                    />
                  </Stack>)}
                  {fieldType === "date" && <Checkbox
                    label="Use dates relative to present"
                    onChange={(val) => { setRelativeDates(val);  setIsChanged(true); }}
                    checked={relativeDates}
                  />}
                  <Checkbox
                    label="Allow only a single value selected at a time"
                    onChange={(val) => { setSingleValueOnly(val);  setIsChanged(true); }}
                    disabled={adjustableRange}
                    checked={singleValueOnly}
                  />
                  {!includeAllValues && productFields.filter((f) => f.handle === type)[0].type === "both" && <Stack distribution="fillEvenly">
                    <Select
                      label="Field type"
                      onChange={(value) => {
                        setFieldType(value);
                        setFacetValues([]);
                        setIsChanged(true);
                      }}
                      options={[{label: "String", value: "string"}, {label: "Numeric", value: "numeric"}, {label: "Date", value: "date"}]}
                      value={fieldType}
                    />
                  </Stack>}
                  {fieldType === "numeric" && (<Stack distribution="fillEvenly">
                    <Checkbox
                      label="Treat numeric value as adjustable range"
                      onChange={(value) => { setAdjustableRange(value ? [null, null] : null); setIsChanged(true); }}
                      checked={adjustableRange !== null}
                    />
                  </Stack>)}
                </FormLayout>
              </Card>
              {adjustableRange && (
                <Card
                  sectioned
                  title="Ranges"
                >
                  <Stack vertical>
                    <Checkbox onChange={(value) => {  setIsChanged(true); setAdjustableRange([(value ? null : (adjustableRange && adjustableRange[0] != null ? adjustableRange[0] : "0")), (adjustableRange ? adjustableRange[1] : null)]); }} checked={adjustableRange && adjustableRange[0] === null} label="Automatically determine minimum value"/>
                    <TextField type='number' disabled={adjustableRange && adjustableRange[0] === null} label="Minimum Value" onChange={(value) => {  setIsChanged(true); setAdjustableRange([value, adjustableRange[1]]); } } value={adjustableRange && adjustableRange[0]}/>
                    <Checkbox onChange={(value) => {  setIsChanged(true); setAdjustableRange([(adjustableRange ? adjustableRange[0] : null), (value ? null : (adjustableRange && adjustableRange[1] != null ? adjustableRange[1] : "0"))]); }} checked={adjustableRange && adjustableRange[1] === null} label="Automatically determine maximum value"/>
                    <TextField type='number' disabled={adjustableRange && adjustableRange[1] === null} label="Maximum Value" onChange={(value) => {  setIsChanged(true); setAdjustableRange([adjustableRange[0], value])} } value={adjustableRange && adjustableRange[1]}/>
                  </Stack>
                </Card>
              )}
              {!includeAllValues && !adjustableRange ? (
                <Card
                  sectioned
                  title="Elements"
                  actions={[
                    { content: "Add", onAction: () => addFacetValue() },
                  ]}
                >
                  <MultiTable
                    sortable
                    resourceName={{
                      singular: "element",
                      plural: "elements",
                    }}
                    bulkActions={[
                      {
                        content: "Delete elements",
                        onAction: (ids) => deleteFacetValues(ids),
                      },
                    ]}
                    headings={
                      fieldType === "numeric" || fieldType === "date"
                        ? ["Label", "From", "To"]
                        : ["Label", "Values"]
                    }
                    rows={
                      sortedFacetValues &&
                      sortedFacetValues.map((facetValue, idx) => {
                        return fieldType === "numeric" || fieldType === "date"
                          ? [
                            <SwatchText swatch={swatches && swatches.filter((s) => s.id == facetValue.swatch_id)[0]}>{typeof(facetValue.name) === "object" ? (facetValue.name[locale ? locale.code : Object.keys(facetValue.name)[0]] || facetValue.name["en"]) : facetValue.name}</SwatchText>,
                            fieldType === 'date' ? getRelativeDateLabel(facetValue.from) : facetValue.from,
                            fieldType === 'date' ? getRelativeDateLabel(facetValue.to) : facetValue.to
                          ]
                          : [
                              <SwatchText swatch={swatches && swatches.filter((s) => s.id == facetValue.swatch_id)[0]}>{typeof(facetValue.name) === "object" ? (facetValue.name[locale ? locale.code : Object.keys(facetValue.name)[0]] || facetValue.name["en"]) : facetValue.name}</SwatchText>,
                              <LocalizedBadgeList values={facetValue.values.map((fv) => getValueDisplayName(fv))} maxToDisplay={maxFacetValuesShown}/>
                            ];
                      })
                    }
                    onRowClick={(row, idx) => { editFacetValue(facetValues[idx], idx) }}
                    onDragDrop={(!sortOrder || sortOrder == "manual") && ((source, destination) => {
                      var tmp = [...facetValues];
                      var element = tmp[source];
                      tmp.splice(source, 1);
                      tmp.splice(destination, 0, element);
                      setFacetValues(tmp);
                      setIsChanged(true);
                    })}
                  />
                  <Modal
                    large
                    title={newValue ? "Add Elements" : `Edit ${typeof(selectedLabel) === "object" ? (selectedLabel[locale ? locale.code : Object.keys(selectedLabel)[0]] || selectedLabel["en"]) : selectedLabel}`}
                    open={modalActive}
                    onClose={() => handleCloseModal()}
                    primaryAction={((activeList && (activeList.length > 0 || groupSelectedValues)) || fieldType === "numeric" || fieldType === "date") && {
                      content: newValue ? "Add" : "Modify",
                      disabled: (!selectedValues || selectedValues.length == 0) && from == null && to == null,
                      onAction: () => {
                        handleSaveModal();
                      },
                    }}
                    secondaryActions={[
                      {
                        content: "Close",
                        onAction: () => handleCloseModal(),
                      },
                    ]}
                    footer={
                      fieldType !== "numeric" && activeList && (activeList.length > 0 || showOnlyUnusedValues) && (<Stack>
                        <Checkbox
                          label="Group selected values"
                          checked={groupSelectedValues}
                          onChange={setGroupSelectedValues}
                          disabled={!newValue}
                        />
                        <Checkbox
                          label="Show only unused values"
                          checked={showOnlyUnusedValues}
                          onChange={(val) => {
                            setShowOnlyUnusedValues(val);
                          }}
                        />
                      </Stack>)
                    }
                  >
                    {!activeList && (fieldType === "string" || fieldType === "bool") && <Modal.Section><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></Modal.Section>}
                    {(fieldType === "string" || fieldType === "bool") ? (
                      activeList && (()=> {
                        if (!modalActive)
                          return (<></>);
                        const hasSelectedAllFacets = selectedValues && activeList && selectedValues.filter(function(v) { return v.indexOf("Wildcard]") == -1 }).length === activeList.filter(function(v) { return v.label.indexOf("Wildcard]") == -1 }).length;
                        return (<Modal.Section>
                          <Stack vertical>
                            {groupSelectedValues ? (
                              <LocalizedTextField
                                label="Label"
                                locale={locale}
                                value={selectedLabel}
                                onChange={setSelectedLabel}
                                error={!isValid.facetLabel}
                              />
                            ) : null}
                            {groupSelectedValues ? (
                              <SwatchSelector
                                label="Swatch"
                                fullWidth
                                swatches={swatches}
                                setSwatches={setSwatches}
                                value={selectedSwatchId}
                                onChange={(swatchId) => { setSelectedSwatchId(swatchId); }}
                              />
                            ) : null}
                            {activeList.filter(function(v) { return v.label.indexOf("Wildcard]") == -1 }).length > 0 && <Subheading>SELECTED &nbsp; <Button onClick={() => { setSelectedValues(hasSelectedAllFacets ? [] : activeList.filter(function(v) { return v.label.indexOf("Wildcard]") == -1; }).map((v) => v.value)) }} size="slim">{hasSelectedAllFacets ? "Select None" : "Select All"}</Button></Subheading>}
                            {activeList.length > 0 && <Stack style={{gap:"6px 12px"}}>
                              {selectedValues &&
                                selectedValues.slice(0, showAllSelectedValues ? 10000000 : maxFacetValuesShown).map((selected, idx) => {
                                  return [
                                    <Tag
                                      key={`${selected}_${idx}`}
                                      onRemove={() => {
                                        setSelectedValues(
                                          selectedValues.filter(
                                            (s) => s !== selected
                                          )
                                        );
                                      }}
                                    >
                                      {getValueDisplayName(selected)}
                                    </Tag>,
                                  ];
                                })}
                              {!showAllSelectedValues && selectedValues && selectedValues.length > maxFacetValuesShown && (
                                <Tag onClick={() => { setShowAllSelectedValues(true); }}>+ Show {selectedValues.length - maxFacetValuesShown} More</Tag>
                              )}
                            </Stack>}
                            <Stack.Item>
                              <Stack vertical spacing="loose">
                                <Subheading>ALL VALUES</Subheading>
                                {fieldType === "string" && <TextField
                                  prefix={<Icon source={SearchMajor} />}
                                  placeholder="Search"
                                  onChange={handleValueSearch}
                                  value={search}
                                />}
                                {activeList && activeList.length > 0 &&
                                  <OptionList
                                    onChange={(values) => {
                                      setSelectedValues(values);
                                    }}
                                    options={activeList.map((v) => { return { ...v, disabled: valuesLoading } })}
                                    selected={selectedValues}
                                    allowMultiple
                                  />
                                }
                              </Stack>
                            </Stack.Item>
                          </Stack>
                          {activeList.length === 0 && <div style={{ marginTop: "12px" }}><Heading>No {showOnlyUnusedValues ? "unused" : ""} values found.</Heading></div>}
                        </Modal.Section>)
                      })()
                    ) : (fieldType === "numeric" ? (
                      <div>
                        <Modal.Section>
                          <FormLayout>
                            <FormLayout.Group condensed>
                              <LocalizedTextField
                                label="Name"
                                locale={locale}
                                value={selectedLabel}
                                onChange={setSelectedLabel}
                              />
                              <SwatchSelector
                                label="Swatch"
                                fullWidth
                                swatches={swatches}
                                setSwatches={setSwatches}
                                value={selectedSwatchId}
                                onChange={(swatchId) => { setSelectedSwatchId(swatchId); }}
                              />
                              <TextField
                                label="From"
                                onChange={(value) => setFrom(value)}
                                value={from}
                                inputMode="numeric"
                                type="number"
                              />
                              <TextField
                                label="To"
                                onChange={(value) => setTo(value)}
                                value={to}
                                inputMode="numeric"
                                type="number"
                                min={from}
                              />
                              {newValue && <div style={{marginTop: "22px"}}>
                                <Button
                                  onClick={() => {
                                    if (selectedLabel && (to || from)) {
                                      setSelectedValues([
                                        ...selectedValues,
                                        { name: selectedLabel, from: from, to: to },
                                      ]);
                                      setTo(null);
                                      setFrom(null);
                                      setSelectedLabel("");
                                    }
                                  }}
                                >
                                  <Icon source={MobilePlusMajor} />
                                </Button>
                              </div>}
                            </FormLayout.Group>
                          </FormLayout>
                        </Modal.Section>
                        {newValue && selectedValues.length > 0 ? (
                          <Modal.Section>
                            <MultiTable
                              headings={["Name", "From", "To"]}
                              rows={
                                selectedValues &&
                                selectedValues.map((range, idx) => {
                                  return [range.name, range.from, range.to];
                                })
                              }
                            />
                          </Modal.Section>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <Modal.Section>
                          <FormLayout>
                            {relativeDates ? (
                              <FormLayout.Group condensed>
                                <LocalizedTextField
                                  label="Name"
                                  locale={locale}
                                  value={selectedLabel}
                                  onChange={setSelectedLabel}
                                />
                                <SwatchSelector
                                  label="Swatch"
                                  fullWidth
                                  swatches={swatches}
                                  setSwatches={setSwatches}
                                  value={selectedSwatchId}
                                  onChange={(swatchId) => { setSelectedSwatchId(swatchId); }}
                                />
                                <RelativeDateField
                                  label="From"
                                  onChange={(value) => setFrom(value)}
                                  value={from}
                                />
                                <RelativeDateField
                                  label="To"
                                  onChange={(value) => setTo(value)}
                                  value={to}
                                  min={from}
                                />
                                {newValue && <div style={{marginTop: "22px"}}>
                                  <Button
                                    onClick={() => {
                                      if (selectedLabel && (to || from)) {
                                        setSelectedValues([
                                          ...selectedValues,
                                          { name: selectedLabel, from: from, to: to },
                                        ]);
                                        setTo(null);
                                        setFrom(null);
                                        setSelectedLabel("");
                                      }
                                    }}
                                  >
                                    <Icon source={MobilePlusMajor} />
                                  </Button>
                                </div>}
                              </FormLayout.Group>
                            ) : (
                              <FormLayout.Group condensed>
                                <LocalizedTextField
                                  label="Name"
                                  locale={locale}
                                  value={selectedLabel}
                                  onChange={setSelectedLabel}
                                />
                                <SwatchSelector
                                  label="Swatch"
                                  fullWidth
                                  swatches={swatches}
                                  setSwatches={setSwatches}
                                  value={selectedSwatchId}
                                  onChange={(swatchId) => { setSelectedSwatchId(swatchId); }}
                                />
                                <DateField
                                  label="From"
                                  onChange={(value) => setTo(value)}
                                  value={to}
                                  min={from}
                                />
                                <DateField
                                  label="To"
                                  onChange={(value) => setTo(value)}
                                  value={to}
                                  min={from}
                                />
                                {newValue && <div style={{marginTop: "22px"}}>
                                  <Button
                                    onClick={() => {
                                      if (selectedLabel && (to || from)) {
                                        setSelectedValues([
                                          ...selectedValues,
                                          { name: selectedLabel, from: from, to: to },
                                        ]);
                                        setTo(null);
                                        setFrom(null);
                                        setSelectedLabel("");
                                      }
                                    }}
                                  >
                                    <Icon source={MobilePlusMajor} />
                                  </Button>
                                </div>}
                              </FormLayout.Group>
                            )}
                          </FormLayout>
                        </Modal.Section>
                        {newValue && selectedValues.length > 0 ? (
                          <Modal.Section>
                            <MultiTable
                              headings={["Name", "From", "To"]}
                              rows={
                                selectedValues &&
                                selectedValues.map((range, idx) => {
                                  return [range.name, getRelativeDateLabel(range.from), getRelativeDateLabel(range.to)];
                                })
                              }
                            />
                          </Modal.Section>
                        ) : null}
                      </div>
                    ))}
                  </Modal>
                </Card>
              ) : null}
            </Layout.Section>
            <Layout.Section secondary>
              {groups && groups.length > 0 && <Card
                title='Groups'
                sectioned
                >
                  <OptionList
                    selected={selectedGroups}
                    onChange={(val) => { setIsChanged(true); setSelectedGroups(val); } }
                    options={groups.map((group, idx1) => { return { label: group.name, value: group.id} })}
                    allowMultiple
                  />
                </Card>
              }

              {profile.shop.locales && profile.shop.locales.length > 1 && <Card
                  title='Locales'
                  sectioned
                >
                <OptionList
                  selected={selectedLocales}
                  onChange={(val) => { setIsChanged(true); setSelectedLocales(val); }}
                  options={profile.shop.locales.map((locale, idx1) => { return { label: locale.name, value: locale.code} })}
                  allowMultiple
                />
              </Card>}
              {fieldType == "string" && type != "tag" && !includeAllValues && <Card sectioned title={<Stack alignment="center">
                <Heading>Unassigned Warnings</Heading>
                <Tooltip content="Specifies whether you want this facet to have warnings about if there any values that aren't assigned to a particular category.">
                  <Icon source={CircleInformationMajor} color="base"/>
                </Tooltip>
              </Stack>}>
                <ChoiceList
                  selected={[warnAboutOrphans ? "1" : "0"]}
                  onChange={(val) => { setWarnAboutOrphans(val[0] == "1" ? true : false); setIsChanged(true); }}
                  choices={[
                    { label: "Warn", value: "1" },
                    { label: "Don't Warn", value: "0" }
                  ]}
                />
              </Card>}
            </Layout.Section>
          </Layout>
        );
      }}
    </Page>
  );
}
