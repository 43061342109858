import React, {useState, useEffect} from 'react';
import {
  Layout,
  TextField,
  Select
} from 'admin-frontend';
import { MultiTableForm } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { Page } from "../../components/Page"

export function MetafieldPolicy() {
  const [editingNamespace, setEditingNamespace] = useState(null);
  const [editingKey, setEditingKey] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingType, setEditingType] = useState('product-metafield');
  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [metafieldList, setMetafieldList] = useState(null);
  const [isBlacklist, setIsBlacklist] = useState(true);
  const [updatedAt, setUpdatedAt] = useState(null);

  const authFetch = useSpotFetch();

  useEffect(() => {
    if (!metafieldList) {
      authFetch("/api/global/metafield_options")
        .then((r) => {
            setIsLoading(false);
            setIsBlacklist(r.metafield_options.policy === "blacklist");
            setMetafieldList(r.metafield_options.list);
            setUpdatedAt(r.updated_at);
        });
    }
  }, [metafieldList, authFetch]);


  function saveMetafieldPolicies(blacklist, list) {
    setIsLoading(true);
    return authFetch("/api/global/metafield_options", { json: { metafield_options: {
      policy: blacklist ? "blacklist" : "whitelist",
      list: list
    }, updated_at: updatedAt } })
      .then((r) => {
          setIsLoading(false);
          setIsBlacklist(r.metafield_options.policy === "blacklist");
          setMetafieldList(r.metafield_options.list);
          setUpdatedAt(r.updated_at);
      })
  }

  function deletePolicies(ids) {
    let hash = Object.fromEntries(ids.map((e) => [e, true]));
    saveMetafieldPolicies(isBlacklist, metafieldList.filter((e, idx) => !hash[idx]));
  }

  const resouceName = {
    singular: "metafield policy",
    plural: "metafield policies"
  };

  return (
  <Page
    title={"Metafield " + (isBlacklist ? "Blacklist" : "Whitelist") + " Policy"}
    resourceName={resouceName}
    permission="setup"
    isChanged={isChanged}
    disableThemes
    primaryAction={!isEditing && {
      loading: isLoading,
      content: "Switch to " + (isBlacklist ? "Whitelist" : "Blacklist"),
      onAction: () => {
        saveMetafieldPolicies(!isBlacklist, metafieldList);
      }
    }}
    onBack={isEditing && (() => {
      setIsEditing(false);
    })}
    isLoading={isLoading}
    onSave={isEditing && (() => {
      let item = {
        type: editingType,
        namespace: editingNamespace,
        key: editingKey
      };
      if (editingId != null)
        metafieldList[editingId] = item;
      else
        metafieldList.push(item);
      setIsLoading(true);
      saveMetafieldPolicies(isBlacklist, metafieldList).then((r) => {
        setIsEditing(false);
        setIsLoading(false);
      });
    })}
  >
    <Layout>
      <Layout.Section>
        <MultiTableForm
          editingForm={[
            (<Select key="type" label="Metafield Type" options={[{label: "Product Metafield", value: "product-metafield"},{label: "Variant Metafield", value: "variant-metafield"}]} value={editingType} onChange={(val) => { setIsChanged(true); setEditingType(val); }}/>),
            (<TextField key="namespace" label="Namespace" value={editingNamespace} onChange={(val) => { setIsChanged(true); setEditingNamespace(val); }}/>),
            (<TextField key="key" label="Key" value={editingKey} onChange={(val) => { setIsChanged(true); setEditingKey(val); }}/>)
          ]}
          isEditing={isEditing}
          hideTable={isEditing}
          resourceName={resouceName}
          bulkActions={[{
            content: "Delete metafield policies",
            onAction: (ids) => deletePolicies(ids)
          }]}
          headings={[
            "Type",
            "Namespace",
            "Key"
          ]}
          rows={metafieldList && metafieldList.map((metafield, idx) => { return [
            metafield.type,
            metafield.namespace,
            metafield.key
          ] })}
          onRowClick={(row, idx) => {
            setEditingId(idx);
            setEditingKey(metafieldList[idx].key);
            setEditingNamespace(metafieldList[idx].namespace);
            setIsEditing(true);
            setEditingType(metafieldList[idx].type);
          }}
          onNew={() => {
            setEditingId(null);
            setEditingKey('');
            setEditingNamespace('');
            setIsEditing(true);
            setEditingType('product-metafield');
          }}
        />
      </Layout.Section>
    </Layout>
    </Page>
  );
}



